import { UseQueryResult, useQuery } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetUserInputOptionsParams extends MedScout.Pagination {
  mappingId: number | string;
  sort?: string;
  order?: string;
}

interface UseGetUserInputOptionsResponse extends MedScout.PaginatedResponse {
  results: MedScout.UserInputOption[];
}

const useGetUserInputOptions = ({
  mappingId,
  sort,
  order,
  page,
  pageSize,
}: GetUserInputOptionsParams) => {
  const queryParams = getSearchQueryParamsForAPI({
    sort,
    order,
    page: page ? page + 1 : undefined,
    pageSize,
  });
  return useQuery(
    [INTEGRATION_KEYS.GET_USER_INPUT_OPTIONS, mappingId],
    async () => {
      return await request({
        url: `v1/integrations/mappings/${mappingId}/user-inputs/${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: !!mappingId,
      refetchOnWindowFocus: false,
    }
  ) as UseQueryResult<UseGetUserInputOptionsResponse>;
};

export default useGetUserInputOptions;
