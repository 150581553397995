import { useState } from 'react';
import { IconButton, Button } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useProspectSelection } from 'src/context';
import { AddProspectDialog, AddProviderDialog } from 'src/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMediaQuery, useTheme } from '@mui/material';

const GridAddToListButton = () => {
  const {
    state: [newProspects],
    selectedRows: [rowsAreSelected, setRowsAreSelected],
  } = useProspectSelection();
  const { useNewListsModule } = useFlags();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [showAddProspectDialog, setShowAddProspectDialog] = useState(false);

  return (
    <>
      {isMobile ? (
        <IconButton
          variant="soft"
          color="neutral"
          sx={{
            fontWeight: 900,
          }}
          onClick={() => setShowAddProspectDialog(true)}
          disabled={!newProspects.length}
          aria-label="Add to List"
        >
          <Icon icon={faPlus} fontWeight={900} />
        </IconButton>
      ) : (
        <Button
          variant="soft"
          color="neutral"
          sx={{
            fontWeight: 900,
            display: 'flex',
            whiteSpace: 'nowrap',
            minWidth: 'max-content',
          }}
          onClick={() => setShowAddProspectDialog(true)}
          disabled={!newProspects.length}
          startDecorator={<Icon icon={faPlus} />}
        >
          Add to List
        </Button>
      )}
      {showAddProspectDialog && !useNewListsModule && (
        <AddProspectDialog
          onHide={() => {
            setRowsAreSelected(!rowsAreSelected);
            setShowAddProspectDialog(false);
          }}
          newProspects={{
            prospects: newProspects,
            ui_fromMap: false,
            onSuccess: () => null,
          }}
        />
      )}
      {showAddProspectDialog && useNewListsModule && (
        <AddProviderDialog
          onHide={() => {
            setRowsAreSelected(!rowsAreSelected);
            setShowAddProspectDialog(false);
          }}
          newProspects={{
            prospects: newProspects,
            ui_fromMap: false,
            onSuccess: () => null,
          }}
        />
      )}
    </>
  );
};

export default GridAddToListButton;
