import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface FormatTerritoryData {
  type: string;
  states: string[];
  bounding_box: number[];
}

export async function getConvertedTerritoryData({
  states,
  type,
  bounding_box,
}: FormatTerritoryData) {
  // convert state county or zip
  let queryParams = getSearchQueryParamsForAPI({
    bounding_box,
  });

  const searchIdParams = states
    .map((id) => `&${encodeURIComponent('within')}=${encodeURIComponent(id)}`)
    .join('');

  queryParams += searchIdParams;

  const convertedData = await request({
    url: `v1/accounts/boundaries/${type}/?${queryParams}`,
    method: 'GET',
  });

  return convertedData;
}
