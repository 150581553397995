import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

interface UpdateProviderList {
  listId: string | number;
  listName?: string;
  ownerId?: number;
  sharedWith?: number[];
}

/**
 * @description Updates the given provider list
 * @param listId
 * @param listName
 */
const useUpdateProviderList = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ listId, listName, ownerId, sharedWith }: UpdateProviderList) => {
      const newData = {};

      if (listName) newData['name'] = listName;
      if (ownerId) newData['owner_id'] = ownerId;
      if (sharedWith) newData['shared_user_ids'] = sharedWith;

      return request({
        url: `/v1/provider-lists/lists/${listId}/`,
        method: 'PATCH',
        data: newData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.providerLists);
      },
    }
  );
};

export default useUpdateProviderList;
