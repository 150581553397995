import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useUpdateMapping = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: any) => {
      return await request({
        url: `/v1/integrations/mappings/${data.id}/`,
        method: 'PATCH',
        data: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(INTEGRATION_KEYS.GET_MAPPINGS);
          },
        });
      },
    }
  );
};

export default useUpdateMapping;
