export type DateType = {
  lte?: string;
  gte?: string;
};

export function getMarks(numYears = 4) {
  const marks = [
    {
      value: 0,
      label: 'Historic',
    },
  ];
  const gap = 20;
  for (let i = numYears - 1; i >= 0; i--) {
    marks.push({
      value: 100 - i * gap,
      label: (new Date().getFullYear() - i).toString(),
    });
  }

  return marks;
}
