import { FormControlLabel, Radio, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';

interface TerritoryDashboardToolbarProps {
  searchValues: any;
  setSearchValues: any;
}

const TerritoryDashboardToolbar = ({
  searchValues,
  setSearchValues,
}: TerritoryDashboardToolbarProps) => {
  function handleChange(e) {
    e.preventDefault();
    if (!searchValues) return null;
    const newSearchValues = searchValues.map((search) => {
      if (search.id === Number(e.target.value)) {
        search.hidden = !search.hidden;
      }
      return search;
    });

    setSearchValues(newSearchValues);
  }

  if (!searchValues) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${grey[300]}`,
        paddingLeft: '1rem',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: 'white',
      }}
    >
      <Tooltip
        title={[
          'Reflects the total volume billed by the physician',
          'relative to your Saved Search. Volumes are not tied',
          'year filters, referrals or affiliations.',
        ].join(' ')}
        placement="top"
        arrow
      >
        <Icon icon={faInfoCircle} size="sm" color={grey[800]} />
      </Tooltip>
      <Box sx={{ textAlign: 'right ' }}>
        {searchValues?.map((search: any) => (
          <FormControlLabel
            key={search.id}
            value={search.id}
            control={
              <Radio
                size="small"
                checked={!search?.hidden}
                sx={{
                  color: grey[500],
                  '&.Mui-checked': {
                    color: search.color,
                  },
                  padding: '0.25rem',
                }}
                onClick={handleChange}
              />
            }
            label={_.truncate(search.label, { length: 32 })}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem',
                fontWeight: 500,
                color: search.hidden ? grey[600] : search.color,
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TerritoryDashboardToolbar;
