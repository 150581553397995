import Linkify from 'react-linkify';

const EmailCell = ({ row }) => {
  if (!row.email) return null;

  return (
    <Linkify
      componentDecorator={(href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noreferrer">
          {text}
        </a>
      )}
    >
      {row.email}
    </Linkify>
  );
};

export default EmailCell;
