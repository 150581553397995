import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';

const FormDivider = (props) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{ marginTop: 15 }}
      {...props}
    >
      <div
        style={{
          flex: 1,
          height: 1,
          backgroundColor: COLOR_MAP['gray-light'],
        }}
      ></div>
      <div
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          color: COLOR_MAP.gray,
          fontWeight: 700,
          fontSize: 14,
        }}
      >
        ADDITIONAL FILTERS
      </div>
      <div
        style={{
          flex: 1,
          height: 1,
          backgroundColor: COLOR_MAP['gray-light'],
        }}
      ></div>
    </div>
  );
};

export default FormDivider;
