import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

interface InitiateIntegrationProps {
  crm: string;
  username: string;
}

const useInitiateIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [INTEGRATION_KEYS.INITIATE_INTEGRATION],
    async (data: InitiateIntegrationProps) => {
      return await request({
        url: `/v1/integrations/initiate-integration/`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              INTEGRATION_KEYS.GET_AVAILABLE_INTEGRATIONS
            );
          },
        });
      },
    }
  );
};

export default useInitiateIntegration;
