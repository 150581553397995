import { Button, Toast as ToastElement, ToastContainer } from 'react-bootstrap';
import { Z_INDEX } from 'src/utils/constants/scss-variables.constants';

const ErrorNotification: React.FC<{
  show: boolean;
  onClose: () => void;
  onRetry: () => void;
}> = ({ show, onClose, onRetry, children }) => {
  return (
    <ToastContainer
      position="top-end"
      className="p-3"
      style={{ marginTop: 60, zIndex: Z_INDEX.toastContainer }}
    >
      <ToastElement show={show} onClose={onClose} autohide={false}>
        <ToastElement.Header>
          <strong className="me-auto text-danger">There was an error</strong>
          <small className="text-muted">just now</small>
        </ToastElement.Header>
        <ToastElement.Body>
          <span className="text-danger">{children}</span>
          <div className="d-flex justify-content-end mt-3">
            <Button onClick={onRetry}>Retry</Button>
          </div>
        </ToastElement.Body>
      </ToastElement>
    </ToastContainer>
  );
};

export default ErrorNotification;
