import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

interface GetProviderListResult {
  results: MedScout.List[];
  count: number;
  next: string | null;
  previous: string | null;
}

/**
 * @description list of all provider lists for the signed in user
 * Standard pagination, sort, and search parameters apply
 */
const useGetProviderLists = () => {
  return useQuery<GetProviderListResult>(
    [QUERY_KEYS.providerLists],
    async () => {
      return await request({
        url: `/v1/provider-lists/lists/?pageSize=1000`, // accepts user_id
        method: 'GET',
      });
    },
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetProviderLists;
