import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

interface GetProviderList {
  listId: string | number;
}

/**
 * @description Returns the details for the given provider list
 * @param listId  is id of the provider list to get
 */
const useGetProviderListById = ({ listId }: GetProviderList) => {
  return useQuery(
    [QUERY_KEYS.providerListById, listId],
    async () => {
      return await request({
        url: `/v1/provider-lists/lists/${listId}/`,
        method: 'GET',
      });
    },
    {
      enabled: !!listId,
    }
  );
};

export default useGetProviderListById;
