import { useState } from 'react';
import _ from 'lodash';
import { Tags } from 'src/components';
import { PipelineTags, ProviderListTags } from 'src/components';
import { useProspectSearch, useLogging } from 'src/context';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';

const HtmlToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '0.9rem',
    border: '1px solid #dadde9',
    borderRadius: '0.5rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f5f5f9',
    '&::before': {
      backgroundColor: '#f5f5f9',
      border: '1px solid #dadde9',
    },
  },
}));

const ToolTipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHovered',
})<{ isHovered: boolean }>(({ theme, isHovered }) => ({
  backgroundColor: isHovered ? '#2391d9' : '#693cbd',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  cursor: 'pointer',
  margin: '-20px 0 0 -20px',
  color: 'white',
  boxShadow: isHovered ? '0 0 0 3px white' : ' 0 0 0 1px white',
  '&:hover': {
    backgroundColor: '#2391d9',
    boxShadow: '0 0 0 3px white',
  },
}));

const ToolTipContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCluster',
})<{ isCluster: boolean }>(({ theme, isCluster }) => ({
  marginRight: isCluster ? '-0.5rem' : 0,
  color: theme.palette.text.primary,
  overflow: 'auto',
  minHeight: '7rem',
  maxHeight: '20rem',
}));

const ToolTipContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '0.5rem',
}));

const InfoBox = styled(Box)(({ theme }) => ({
  height: '100%',
  fontSize: '0.9rem',
  color: theme.palette.primary.main,
  fontWeight: 500,
  cursor: 'pointer',
  '& :hover': {
    textDecoration: 'underline !important',
  },
}));

interface MapMarkerProps {
  onClick;
  id;
  lat;
  lng;
  pointCount?;
  type;
  details?;
  isHovered;
  clusterLeaves?;
  onAddToList;
}

const MapMarker = ({
  onClick,
  id,
  lat,
  lng,
  pointCount,
  type,
  details,
  isHovered,
  clusterLeaves,
  onAddToList = null,
}: MapMarkerProps) => {
  const log = useLogging();
  const { useNewListsModule } = useFlags();
  const { prospectType } = useProspectSearch();
  const [hovered, setHovered] = useState(isHovered || false);

  function toolTipTitle() {
    const isCluster = type === 'cluster' && _.size(clusterLeaves) > 0;
    const isPoint = type === 'point' && details && !pointCount;
    const prospectTypeMap = {
      HCP: 'Providers',
      C: 'Sites',
    };

    if ((isPoint || isCluster) && hovered) {
      return (
        <ToolTipContentContainer
          isCluster={isCluster && clusterLeaves.length > 2}
        >
          <ToolTipContent>
            {isPoint && renderDetails(details, 0)}

            {isCluster &&
              _.map(clusterLeaves, (leafNode, idx) => (
                <Box
                  key={leafNode.details.provider_id}
                  sx={{
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    borderBottom: '1px solid #dadde9',
                    '& > p': {
                      fontSize: '0.9rem',
                    },
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  {renderDetails(leafNode.details, idx + 1)}
                </Box>
              ))}
          </ToolTipContent>
        </ToolTipContentContainer>
      );
    } else {
      return (
        <Box>
          <p>
            {details?.name || `${pointCount} ${prospectTypeMap[prospectType]}`}
          </p>
        </Box>
      );
    }
  }

  function renderDetails(details, idx) {
    return (
      <>
        <InfoBox component="p">
          <a
            href={`/profiles/${details.content_type}/${details.provider_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {idx ? `${idx}) ` : ''}
            {details.name} <Icon icon={faExternalLinkAlt} size="sm" />
          </a>
        </InfoBox>
        <p style={{ fontWeight: 500 }}>{details.specialty}</p>
        <p>{details.address_line_1}</p>
        <p>{details.address_line_2}</p>
        <p>
          {details.city}, {details.state}
        </p>
        <Tags tags={details?.tags} />

        {useNewListsModule ? (
          <ProviderListTags lists={details?.provider_lists} />
        ) : (
          <PipelineTags pipelines={details?.ui_prospectLists} />
        )}

        {onAddToList && (
          <InfoBox component="p">
            <a
              onClick={(e) => {
                e.stopPropagation();
                onAddToList(details);
              }}
            >
              {`Add to ${useNewListsModule ? 'Lists' : 'Pipelines'}`}
            </a>
          </InfoBox>
        )}
      </>
    );
  }

  return (
    <HtmlToolTip
      title={toolTipTitle()}
      placement="top"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -5],
              },
            },
          ],
        },
      }}
      disableFocusListener={!hovered}
    >
      <ToolTipContainer
        isHovered={isHovered}
        onClick={(e) => {
          // prevents clicking map elements below the marker
          e.stopPropagation();
          log.event('mapMarkerClicked');
          onClick(id, lat, lng, type);
          // Prevents the tooltip from showing when zooming in on a cluster
          setHovered(false);
        }}
        onMouseOver={() => {
          setHovered(true);
        }}
      >
        <Box>
          <span
            style={{
              color: 'white',
              fontSize: '0.625rem',
              fontWeight: 700,
            }}
          >
            {pointCount || 1}
          </span>
        </Box>
      </ToolTipContainer>
    </HtmlToolTip>
  );
};

export default MapMarker;
