import { useMemo } from 'react';
import {
  useGetMyProspectLists,
  Pipeline,
} from 'src/api/prospects/prospect-lists';
import _ from 'lodash';
import { useAuth } from 'src/context';

export type PipelineSet = {
  userLists: Pipeline[];
  sharedLists: { [ownerId: string]: Pipeline[] };
  allLists: Pipeline[];
};

type PipelineHookProps = {
  lists: PipelineSet;
  listsById: { [key: string]: Pipeline };
  isFetching: boolean;
  refetch: () => void;
};

const usePipelines = (): PipelineHookProps => {
  const { user } = useAuth();
  const {
    data: myProspectLists,
    isFetching,
    refetch,
  } = useGetMyProspectLists();

  const pipelines = useMemo(() => {
    const sortedProspectLists = _.orderBy(myProspectLists?.lists || [], 'name');
    const userLists = [];
    const sharedLists = {};

    sortedProspectLists.forEach((prospectList) => {
      if (user?.id === prospectList.owner.id) {
        userLists.push(prospectList);
      } else {
        sharedLists[prospectList.owner.id] =
          sharedLists[prospectList.owner.id] || [];
        sharedLists[prospectList.owner.id].push(prospectList);
      }
    });

    return {
      lists: {
        userLists,
        sharedLists,
        allLists: sortedProspectLists,
      },
      listsById: _.keyBy(sortedProspectLists, (list) => {
        return list.id;
      }),
    };
  }, [user, myProspectLists]);

  return {
    ...pipelines,
    isFetching,
    refetch,
  };
};

export default usePipelines;
