import { useState } from 'react';
import { IconButton } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useProspectSelection } from 'src/context';
import { RemoveProvidersDialog } from 'src/modules/Lists/components';

const GridProviderDeleteButton = () => {
  const {
    state: [selectionModel],
  } = useProspectSelection();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <>
      <IconButton
        variant={!!selectionModel.length ? 'outlined' : 'soft'}
        color="danger"
        sx={{
          fontWeight: 900,
        }}
        onClick={() => setShowDeleteDialog(true)}
        disabled={!selectionModel.length}
      >
        <Icon icon={faTrash} fontWeight={900} />
      </IconButton>
      <RemoveProvidersDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default GridProviderDeleteButton;
