import _ from 'lodash';

const createNotificationSlice = (set, get) => ({
  notifications: [],
  addNotification: (notification) => {
    set((state) => {
      return {
        notifications: _.concat(notification, state.notifications),
      };
    });
  },
  removeNotifications: (notificationIds, removeAll) => {
    set((state) => {
      if (removeAll) {
        return { notifications: [] };
      }

      const listOfNotificationIds = _.isArray(notificationIds)
        ? notificationIds
        : [notificationIds];
      return {
        notifications: _.filter(state.notifications, (notification) => {
          return !_.includes(listOfNotificationIds, notification.id);
        }),
      };
    });
  },
});

export default createNotificationSlice;
