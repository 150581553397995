import { format as formatDate } from 'date-fns';

const CrmSyncCell = ({ row }) => {
  return row.crm_synced_at ? (
    <span>{formatDate(new Date(row.crm_synced_at), 'MM/dd/yy')}</span>
  ) : (
    <>n/a</>
  );
};

export default CrmSyncCell;
