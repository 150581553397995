import { useState } from 'react';
import { Tags } from 'src/components';
import {
  Link,
  Button,
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
  styled,
} from '@mui/material';
import {
  faExternalLinkAlt,
  faNoteSticky,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { useLogging } from 'src/context';
import { format as formatDate } from 'date-fns';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { amber } from '@mui/material/colors';

const CustomContent = ({ notes }) => {
  return (
    <>
      <Box
        sx={{
          overflowY: 'auto',
          maxHeight: 250,
          maxWidth: 350,
          p: '1rem',
        }}
      >
        {notes.map((note, i) => {
          const isFirst = i === 0;

          return (
            <div
              key={`note_${note.id}`}
              style={{
                borderTop: isFirst ? 'none' : '1px solid',
                borderColor: COLOR_MAP['gray-lighter'],
                padding: '10px 10px 5px',
                paddingTop: isFirst ? 0 : '10px',
              }}
            >
              <Typography
                component={'time'}
                sx={{
                  flexShrink: 0,
                  fontSize: 10,
                  fontWeight: 500,
                }}
                dateTime={note.created_at}
              >
                {formatDate(new Date(note.created_at), 'PPp')}
              </Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}>
                &ldquo;{note.note}&rdquo;
              </Typography>
            </div>
          );
        })}
      </Box>
    </>
  );
};

const NoteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderLeft: `2px solid ${theme.palette.highlight.main}`,
    boxShadow: theme.shadows[6],
  },
  [` .${tooltipClasses.arrow}:before`]: {
    backgroundColor: theme.palette.highlight.main,
  },
}));

const NameCell = ({ row }) => {
  const log = useLogging();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link
          href={`/profiles/${row.content_type || 'hcp'}/${row.provider_id}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ flexGrow: 1 }}
        >
          {row.name || 'n/a'}
          <Icon
            icon={faExternalLinkAlt}
            size="sm"
            style={{ marginLeft: '0.25rem' }}
          />
        </Link>
        {!_.isEmpty(row.notes) && (
          <NoteTooltip
            title={<CustomContent notes={row.notes} />}
            placement="right"
            disableFocusListener
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            arrow
          >
            <Button
              variant="text"
              endIcon={
                <Icon
                  icon={faNoteSticky}
                  style={{ fontSize: '1rem' }}
                  color={amber[300]}
                />
              }
              sx={{ fontSize: '0.75rem' }}
              onClick={() => {
                log.event('pipelineListNotesClickedOpen');
                setTooltipOpen((state) => !state);
              }}
              size="small"
            >
              {_.size(row.notes)} {_.size(row.notes) === 1 ? 'Note' : 'Notes'}
            </Button>
          </NoteTooltip>
        )}
      </Box>
      {row.tags?.length > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tags tags={row.tags} tagType="tag" size="small" />
        </Box>
      )}
    </Box>
  );
};

export default NameCell;
