import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { useProspectSearch } from 'src/context';

const useGetTerritoryWithVolume = ({
  territoryId,
}: {
  territoryId: number;
}) => {
  const { queryString } = useProspectSearch();

  return useQuery(
    [QUERY_KEYS.analytics_territories, territoryId, queryString],
    () => {
      if (!territoryId) return Promise.resolve(null);

      return request({
        url: `/v1/analytics/territories/${territoryId}${queryString}`,
        data: {},
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetTerritoryWithVolume;
