import { useQueryClient, useMutation } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from '../search/constants';
import { QUERY_KEYS as DASHBOARD_KEYS } from '../dashboards/constants';

interface AddItemToProviderListProps {
  listId: string | number;
  providers: MedScout.Opportunity[];
}

/**
 * @description Adds a provider to a provider list
 * @param listId
 * @param providerId
 * @param type
 */

const useAddItemsToProviderList = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: AddItemToProviderListProps) => {
      return request({
        url: `/v1/provider-lists/lists/${data.listId}/items/`,
        method: 'POST',
        data: data.providers.map(({ provider_id, content_type }) => ({
          provider_id,
          type: content_type,
        })),
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.search);
        queryClient.invalidateQueries(DASHBOARD_KEYS.affiliations);
      },
    }
  );
};

export default useAddItemsToProviderList;
