import { MedScoutLoadingIcon } from 'src/components';
import { styled } from '@mui/material';

const PositioningContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadingIndicator = ({ alignCenter = true }) => {
  return (
    <PositioningContainer
      style={{ height: alignCenter ? '100%' : '400px' }}
    >
      <MedScoutLoadingIcon />
    </PositioningContainer>
  );
};

export default LoadingIndicator;
