const createKanbanSlice = (set, get) => ({
  tempCrmSyncedAtValuesByProspectId: {},
  addTempCrmSyncedAtValuesByProspectId: ({ prospectId, syncedAtTimestamp }) => {
    set((prev) => {
      return {
        tempCrmSyncedAtValuesByProspectId: {
          ...prev.tempCrmSyncedAtValuesByProspectId,
          [prospectId]: syncedAtTimestamp,
        },
      };
    });
  },
});

export default createKanbanSlice;
