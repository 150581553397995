import { useState, useRef, useEffect } from 'react';
import { useGetProviderLists, useAddItemsToProviderList } from 'src/hooks';
import {
  useLogging,
  useAuth,
  useLayoutControl,
  useNotification,
} from 'src/context';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

/**
 * Add one or more selected opportunities to a pipeline
 *
 * @param {*} {
 *   onHide,
 *   newProspects,
 * }
 * @return {*}
 */
const AddProviderDialog = ({ onHide, newProspects }) => {
  const { setNotification } = useNotification();
  const { data: providerLists, isLoading: providerListsLoading } =
    useGetProviderLists();
  const { mutateAsync: addProvidersToList, isLoading: addProvidersLoading } =
    useAddItemsToProviderList();
  const [, setInlineError] = useState('');
  const [selectedProviderListId, setSelectedProviderListId] =
    useState<string>(null);
  const radioGroupRef = useRef<HTMLElement>(null);
  const log = useLogging();
  const { user } = useAuth();
  const { showAddListDialog, setShowAddListDialog } = useLayoutControl();

  async function handleAddProviders() {
    try {
      // remove prospects that are already in the list
      const prospects = await handleDuplicateRemoval();

      if (prospects.length === 0) {
        setNotification({
          title: 'No prospects added',
          message: 'All selected prospects are already in the list.',
          type: 'info',
        });
        onHide();
        return;
      }
      await addProvidersToList({
        listId: selectedProviderListId,
        providers: prospects,
      });

      if (newProspects?.onSuccess) {
        newProspects.onSuccess();
      }

      log.event('addProspect', {
        source: 'searchView',
        view: newProspects.ui_fromMap ? 'map' : 'table',
        count: prospects?.length,
      });

      setNotification({
        title: 'Prospects added',
        message: `Added ${prospects.length} prospects to the list.`,
        type: 'success',
      });

      onHide();
    } catch (err) {
      setInlineError(err.message);
      log.event('addProspect', {
        source: 'searchView',
        view: newProspects.ui_fromMap ? 'map' : 'table',
        count: newProspects?.prospects?.length,
        error: err.message,
      });

      setNotification({
        title: 'Error',
        message:
          err.message ||
          'An error occurred while adding prospects to the list.',
        type: 'error',
      });
    }
  }

  function handleDuplicateRemoval() {
    // Filter out prospects that are already in the selected provider list
    return newProspects.prospects.filter((prospect) => {
      // Check if the prospect's provider_lists contain the selectedProviderListId
      if (!prospect.provider_lists || prospect.provider_lists.length === 0)
        return true; // Return true if provider_lists is empty or undefined

      // Return false if the prospect has the selectedProviderListId in any of its provider_lists
      return !prospect.provider_lists.some(
        (list) => list.id?.toString() === selectedProviderListId
      );
    });
  }

  function handleSelectList(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedProviderListId(e.target.value);
  }

  function handleToggleAddListDialog(e) {
    e.stopPropagation();
    setShowAddListDialog(!showAddListDialog);
  }

  useEffect(() => {
    if (!providerLists?.results?.length) return;

    const mostRecentProviderList = providerLists.results
      ?.filter((list) => list.owner.id === user.id)
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )[0];

    if (!mostRecentProviderList) return;

    setSelectedProviderListId(mostRecentProviderList.id.toString());
  }, [providerLists, user]);

  return (
    <Dialog open>
      <DialogTitle>Add to List</DialogTitle>
      <DialogContent sx={{ maxHeight: '25rem', overflow: 'auto' }}>
        {providerListsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size="4rem" />
          </Box>
        ) : (
          <RadioGroup
            ref={radioGroupRef}
            aria-label="provider list"
            name="providerList"
            value={selectedProviderListId}
            onChange={handleSelectList}
          >
            {providerLists?.results
              ?.filter((list) => list.owner.id === user.id)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((list) => (
                <FormControlLabel
                  key={list.id}
                  value={list.id}
                  control={<Radio />}
                  label={list.name}
                />
              )) || 'No lists available'}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant="text" onClick={handleToggleAddListDialog}>
          New List
        </Button>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="outlined" onClick={onHide}>
            Cancel
          </Button>
          <LoadingButton
            loading={addProvidersLoading}
            disabled={!selectedProviderListId}
            variant="contained"
            onClick={() => handleAddProviders()}
            aria-label="Add To List"
          >
            Add to List
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddProviderDialog;
