class TerritoryService {
  static formatTerritoryData(data: any, enabledSearchIds: any[]) {
    if (!data?.results) return [];
    return data?.results?.map((territory) => {
      // build fields for each enabledSearchId
      enabledSearchIds?.map((id) => {
        const volume = territory?.volumes?.[id];
        const data = territory?.trends?.[id];
        const volume_shift = territory?.volume_shifts?.[id];
        const doesHaveData = data?.length > 0;

        if (!territory[id]) {
          territory[id] = {
            volume,
            volume_shift,
            data: doesHaveData
              ? [
                  {
                    id: territory?.id,
                    data: data?.length > 0 ? data : [],
                  },
                ]
              : [],
          };
        }
      });

      return territory;
    });
  }
}

export default TerritoryService;
