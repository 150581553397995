import { styled } from '@mui/material';
import { LoadingIndicator } from 'src/components';

const FullPageContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto;
`;

/**
 * FullPageLoadingIndicator - renders a loading indicator in the center of the screen
 * full height and width
 * @returns {JSX.Element}
 */
const FullPageLoadingIndicator = () => {
  return (
    <FullPageContainer>
      <LoadingIndicator />
    </FullPageContainer>
  );
};

export default FullPageLoadingIndicator;
