import { Box, BoxProps, Typography, Tooltip } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faHeartbeat,
  faBriefcaseMedical,
  faBookMedical,
} from '@fortawesome/pro-solid-svg-icons';
import { amber } from '@mui/material/colors';

interface DataBoxProps {
  isMedicare?: boolean;
  isCommercial?: boolean;
  isScraped?: boolean;
}

const DataBox: React.FC<BoxProps & DataBoxProps> = ({
  children,
  isMedicare = false,
  isCommercial = false,
  isScraped = false,
  sx,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        sx={{
          py: 1,
          pb: 2,
          mb: -1,
          justifyContent: 'flex-end',
          alignItems: 'center',
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontStyle: 'italic',
            fontSize: '0.5rem',
            textTransform: 'uppercase',
          }}
        >
          Data source:
        </Typography>
        <Tooltip title={'CMS sourced Medicare claims data'} arrow>
          <Icon
            icon={faHeartbeat}
            style={{ opacity: isMedicare ? '1' : '0.10' }}
            color={isMedicare ? amber[600] : 'inherit'}
          />
        </Tooltip>
        <Tooltip title="Commercial payer claims data" arrow>
          <Icon
            icon={faBriefcaseMedical}
            style={{ opacity: isCommercial ? '1' : '0.10' }}
            color={isCommercial ? amber[600] : 'inherit'}
          />
        </Tooltip>
        {/* <Tooltip title="Other data" arrow>
          <Icon
            icon={faBookMedical}
            style={{ opacity: isScraped ? '1' : '0.10' }}
            color={isScraped ? amber[600] : 'inherit'}
          />
        </Tooltip> */}
      </Box>
      <>{children}</>
    </Box>
  );
};

export default DataBox;
