import { OverlayTrigger } from 'react-bootstrap';
import DetailsPopover from 'src/modules/Discovery/components/ResultComponents/DetailsPopover';
import { Button } from '@mui/material';

const VolumeCell = ({ row }) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      rootClose
      overlay={<DetailsPopover row={row} />}
    >
      <Button variant="outlined">{row.volume || '0'}</Button>
    </OverlayTrigger>
  );
};

export default VolumeCell;
