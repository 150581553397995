import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

const useUpdateUserNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, { notification_settings }>(
    ({ notification_settings }) => {
      // optimistic update
      queryClient.setQueryData<any>(
        [QUERY_KEYS.user_notification_settings],
        (old) => {
          return {
            ...old,
            ...notification_settings,
          };
        }
      );

      return request({
        url: `v1/settings/notifications`,
        method: 'PUT',
        data: {
          notification_settings,
        },
      });
    },
    {
      onError: () => {
        queryClient.invalidateQueries([QUERY_KEYS.user_notification_settings]);
      },
    }
  );
};

export default useUpdateUserNotificationSettings;
