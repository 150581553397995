import { Typography } from '@mui/material';
import { format as formatDate } from 'date-fns';

const NotesCell = ({ row }) => {
  if (!row.notes) return null;

  return (
    <ul style={{ margin: 'unset' }}>
      {row.notes.map((note) => (
        <li key={note.id}>
          <Typography
            component={'time'}
            dateTime={note.created_at}
            sx={{
              fontSize: '0.75em',
              fontStyle: 'italic',
              display: 'block',
            }}
          >
            {formatDate(new Date(note.created_at), 'PPp')}:
          </Typography>
          <Typography>&ldquo;{note.note}&rdquo;</Typography>
        </li>
      ))}
    </ul>
  );
};

export default NotesCell;
