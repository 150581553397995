import { Box } from '@mui/material';
import { PushToCRMGroup } from 'src/components';

const CrmCell = ({ row }) => {
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <PushToCRMGroup provider={row} />
    </Box>
  );
};

export default CrmCell;
