import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from './constants';
import TerritoryService from 'src/services/TerritoryService';
import useRefetchTerrioryAnalytics from './useRefetchTerritoryAnalytics';
interface TerritoryAnalyticsProps {
  enabledSearchIds: string[];
  searchString: string;
  sort: string;
  order: string;
  sort_search?: string;
  sort_value?: string;
  type: string;
}

const useTerritoryAnalytics = ({
  page,
  pageSize,
  enabledSearchIds,
  searchString,
  sort, // if sort is set to saved_search, then sort_search and sort_value are required
  order,
  sort_search, // id of the saved search to sort on if sort is set to saved_search
  sort_value, // The value to sort on, can be volume or unique_providers if sort is set to saved_search
  type,
}: MedScout.Pagination & TerritoryAnalyticsProps) => {
  /** Each saved search id has to be in a saved_search key value pair
   * But you can't send an object with the same key twice, so.. we have to do this
   * e.g. &saved_search=1&saved_search=2
   */
  const searchIdParams = enabledSearchIds
    .filter((id) => id !== 'volume')
    .map(
      (id) => `&${encodeURIComponent('saved_search')}=${encodeURIComponent(id)}`
    );

  const searchParams = getSearchQueryParamsForAPI({
    page: page + 1,
    pageSize: pageSize,
    q: searchString || '',
    sort: sort || '',
    order: order || '',
    sort_search: sort_search || '',
    sort_value: sort_value || '',
    type: type || '',
  });

  const newParams = searchParams.concat(...searchIdParams);

  const { data, isLoading, error, isFetching } = useQuery(
    [QUERY_KEYS.analytics_territories, newParams],
    () => {
      return request({
        url: `/v1/analytics/territories/${newParams}`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false, // this seems to have stopped the lengthy delays, but at what cost?
      /**
       * This changes in newer versions of react-query
       * https://tanstack.com/query/v5/docs/react/guides/paginated-queries
       */
      keepPreviousData: true,
    }
  );
  // This is the refetch interval for the territory analytics
  useRefetchTerrioryAnalytics({ newParams, searchIdParams });

  const territoryData = TerritoryService.formatTerritoryData(
    data,
    enabledSearchIds
  );

  return {
    data: territoryData,
    isLoading: isLoading || isFetching,
    error,
    count: data?.count,
  };
};

export default useTerritoryAnalytics;
