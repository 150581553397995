import _ from 'lodash';
import { STATUS_COLOR_MAP } from 'src/utils/constants/status-colors.constants';
import Tags from './Tags';
import { ChipProps } from '@mui/material';
import { usePipelines } from 'src/hooks';

/**
 * PipelineTags
 *
 * @param {*} { pipelines, size = 'small' }
 * @return {*}
 */
const PipelineTags: React.FC<{
  pipelines;
  size?: ChipProps['size'];
}> = ({ pipelines, size = 'small' }) => {
  const { listsById } = usePipelines();
  if (_.isEmpty(pipelines)) {
    return null;
  }
  const max = 4;
  const formattedTags = _.map(pipelines, (list) => {
    const bgColor = list.status_display?.color || STATUS_COLOR_MAP[list.status];
    const pipeline = _.get(listsById, list.list_ref_id || list.id);

    return {
      id: list.list_ref_id,
      bgColor,
      label: list.list_name || list.name,
      FormattedText: () => <span>{list.list_name || list.name}</span>,
      fullText: formatFullText(list, pipeline),
      href: `/pipelines?pipeline_id=${list.list_ref_id}`,
    };
  });

  const groupStyle =
    formattedTags.length > 1
      ? {
          pl: '2.25rem',
          '& .MuiLink-root': {
            marginLeft: '-1.25rem',
            minWidth: '4rem',
            '&>.MuiChip-root': {
              paddingRight: '1rem',
            },
            '&:first-of-type>.MuiChip-root': {
              paddingRight: formattedTags.length <= max ? 'unset' : '1rem',
            },
          },
          '& .MuiAvatarGroup-avatar': {
            marginLeft: '-1rem',
          },
        }
      : {};

  const surplusStyle = {
    '&>div.MuiAvatarGroup-avatar': {
      height: 'unset',
      borderRadius: '1rem',
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 0,
      color: 'unset',
      border: '1px dotted #000',
      backgroundColor: 'white',
      marginLeft: '-2rem !important',
      fontSize: '0.75rem',
      fontWeight: '600',
    },
  };

  function formatFullText(list, appliedList = null) {
    return appliedList
      ? [
          `${appliedList?.owner?.first_name} ${_.head(
            appliedList?.owner?.last_name
          )}`,
          list.list_name || list.name,
          list.status_display?.name,
        ]
          .filter((a) => a)
          .join(' > ')
      : [list.list_name || list.name, list.status_display?.name]
          .filter((a) => a)
          .join(' > ');
  }

  return (
    <Tags
      tags={formattedTags}
      size={size}
      max={max}
      sx={{
        justifyContent: 'flex-end',
        ...groupStyle,
        ...surplusStyle,
      }}
    />
  );
};

export default PipelineTags;
