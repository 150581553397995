import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

const useGetDashboards = () => {
  return useQuery(
    [QUERY_KEYS.dashboards],
    () => {
      return request({
        url: `/v1/analytics/dashboards?dashboard_type=territory`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 600_000,
    }
  );
};

export default useGetDashboards;
