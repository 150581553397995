/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  useReducer,
  useContext,
  ReactElement,
  useCallback,
} from 'react';
import { notificationReducer } from './reducers';

export enum NOTIFICATION_ACTIONS {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
}

export type NotificationType = {
  title: string;
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
};

export type NotificationStateType = {
  notification: NotificationType;
};

export interface UseNotificationType {
  notification: NotificationType;
  setNotification: (notification: NotificationType | null) => void;
}

export type NotificationReducerAction = {
  type: NOTIFICATION_ACTIONS;
  payload?: any;
};

const initialState: NotificationStateType = {
  notification: null,
};

const { SET_NOTIFICATION } = NOTIFICATION_ACTIONS;

const useNotificationContext = () => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  const setNotification = useCallback(
    (notification) =>
      dispatch({
        type: SET_NOTIFICATION,
        payload: notification,
      }),
    []
  );

  return {
    state,
    setNotification,
  };
};

type UseNotificationContextType = ReturnType<typeof useNotificationContext>;

const initContextState: UseNotificationContextType = {
  state: initialState,
  setNotification: () => {},
};

export const NotificationContext =
  createContext<UseNotificationContextType>(initContextState);

export const NotificationProvider = ({ children }): ReactElement => {
  return (
    <NotificationContext.Provider value={useNotificationContext()}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook: Where the magic happens
export const useNotification = (): UseNotificationType => {
  const context = useContext(NotificationContext);
  if (!context)
    throw new Error('useNotification must be wrapped in a MapProvider');
  const { state, ...actions } = context;
  return {
    ...actions,
    notification: state.notification,
  };
};
