import { useMutation, useQuery, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import _ from 'lodash';

export class NewUser {
  id: string;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_superuser: boolean;
  is_active: boolean;
  company?: string;
  company_manager: boolean;
  company_name: string;
  company_department: string;
  company_managers: any[];
  invite_now: boolean;
  is_lir_eligible: boolean;
}

const QUERY_KEYS = {
  all_users: 'all_users',
  all_companies: 'all_companies',
  my_territories: 'my_territories',
};

export function useGetAllUsers(enabled) {
  return useQuery(
    [QUERY_KEYS.all_users],
    () => {
      return request({
        url: `/v1/admin/users`,
        method: 'GET',
        data: {},
      });
    },
    {
      enabled: Boolean(enabled),
      staleTime: 30000,
      refetchOnWindowFocus: false,
    }
  );
}

export function useGetAllCompanies(enabled) {
  return useQuery(
    [QUERY_KEYS.all_companies],
    () => {
      return request({
        url: `/v1/admin/companies`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: Boolean(enabled),
      staleTime: 30000,
      refetchOnWindowFocus: false,
    }
  );
}

// export function useGetMyTerritories() {
//   return useQuery(
//     [QUERY_KEYS.my_territories],
//     () => {
//       return request({
//         url: `/v1/territory`,
//         data: {},
//         method: 'GET',
//       });
//     },
//     {
//       enabled: true,
//       staleTime: 60000,
//       refetchOnWindowFocus: false,
//     }
//   );
// }

export function useCreateNewUser() {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, NewUser>(
    ({
      email,
      username,
      first_name,
      last_name,
      is_staff,
      is_superuser,
      is_lir_eligible,
      company_name,
      company_manager,
      company_department,
      company_managers,
      invite_now,
    }) => {
      const isMedscoutEmail = _.includes(email, '@medscout.io');

      return request({
        url: `v1/admin/users`,
        method: 'POST',
        data: {
          email: _.trim(_.toLower(email)),
          username: _.trim(_.toLower(username)),
          first_name: _.trim(first_name),
          last_name: _.trim(last_name),
          is_staff: isMedscoutEmail ? is_staff : false,
          is_superuser: isMedscoutEmail ? is_superuser : false,
          is_lir_eligible,
          invite_now: !!invite_now,
          company: {
            name: _.trim(company_name),
            manager: company_manager,
            department: company_department,
            managers: _.map(company_managers, (manager) => {
              return manager?.id || manager;
            }),
          },
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.all_users]);
        queryClient.invalidateQueries([QUERY_KEYS.all_companies]);
      },
    }
  );
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, NewUser>(
    ({
      id,
      email,
      username,
      first_name,
      last_name,
      is_staff,
      is_superuser,
      is_active,
      is_lir_eligible,
      company_name,
      company_manager,
      company_department,
      company_managers,
    }) => {
      const isMedscoutEmail = _.includes(email, '@medscout.io');

      return request({
        url: `v1/admin/users/${id}`,
        method: 'PUT',
        data: {
          email: _.trim(_.toLower(email)),
          username: _.trim(_.toLower(username)),
          first_name: _.trim(first_name),
          last_name: _.trim(last_name),
          is_staff: isMedscoutEmail ? is_staff : false,
          is_superuser: isMedscoutEmail ? is_superuser : false,
          is_active,
          is_lir_eligible,
          company: {
            name: _.trim(company_name),
            manager: company_manager,
            department: company_department,
            managers: _.map(company_managers, (manager) => {
              return manager?.id || manager;
            }),
          },
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.all_users]);
        queryClient.invalidateQueries([QUERY_KEYS.all_companies]);
      },
    }
  );
}

export function useInviteUser() {
  return useMutation<
    any,
    unknown,
    {
      id: string;
    }
  >(({ id }) => {
    return request({
      url: `v1/admin/users/${id}/invite/`,
      method: 'GET',
    });
  });
}

export function useResetUserPassword() {
  return useMutation<
    any,
    unknown,
    {
      id: string;
    }
  >(({ id }) => {
    return request({
      url: `v1/admin/users/${id}/reset-password/`,
      method: 'GET',
    });
  });
}
