export const env = {
  NODE_ENV: process.env['NODE_ENV'] || 'production',
  ANALYTICS_ID: process.env['NEXT_PUBLIC_ANALYTICS_ID'] || '',
  FEATURES_ID: process.env['NEXT_PUBLIC_FEATURES_ID'] || '',
  MUI_X_KEY: process.env['NEXT_PUBLIC_MUI_X_LICENSE_KEY'] || '',
  GOOGLE_MAPS_KEY: process.env['NEXT_PUBLIC_GOOGLE_MAPS_KEY'] || '',
};

export const AUTH = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
};
