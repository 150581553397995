import { useEffect } from 'react';
import {
  Drawer,
  styled,
  DrawerProps,
  Box,
  Button,
  useTheme,
  Typography,
} from '@mui/material';
import { ProspectService } from 'src/services';
import {
  FilterTagList,
  SaveComboButton,
  ContextCommands,
} from 'src/components';
import {
  useProspectSearch,
  useLayoutControl,
  useLogging,
  useAuth,
} from 'src/context';
import { useEditSavedSearch } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { SearchMenu, FilterForm } from '..';
import { useWindowSize } from 'src/utils/hooks/useWindowSize';
import _ from 'lodash';
import { useRouter } from 'next/router';

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    backdropFilter: 'blur(6px)',
    backgroundColor: `#FFFFFF9F`,
    padding: '0',
  },
  '& .MuiDrawer-paperAnchorTop': {
    top: '3.45rem',
    overflow: 'visible',
  },
}));

const CommandSet = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '0.5rem',
  padding: '0 1rem',
}));

/**
 * FilterDrawer
 * @description The global prospect search (saved search) filter drawer.
 *
 * @param {DrawerProps} { open, onClose }
 * @return {*}
 */
const FilterDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const {
    prospectFilters,
    setProspectFilters,
    setProspectSearch,
    prospectSearch,
    isCustom,
  } = useProspectSearch();
  const { height } = useWindowSize();
  const { setShowAddSavedSearchDialog } = useLayoutControl();
  const { isLoading: isEditingSearch, mutateAsync: editSavedSearch } =
    useEditSavedSearch();
  const log = useLogging();
  const { user } = useAuth();
  const canEdit =
    (user?.company?.manager ||
      user?.is_superuser ||
      !prospectSearch?.org_search) &&
    !!prospectSearch?.id;
  const maxHeight = height - 3.5 * 16;
  const router = useRouter();
  const theme = useTheme();
  // Used to indicate whether or not the search enters a draft search while in the territory dashboard
  const draftOnTerritoryDashboard =
    isCustom && router.pathname === '/territories';

  async function handleSave() {
    if (
      !confirm(
        prospectSearch?.org_search
          ? 'Are you sure you want to update this search for all users?'
          : 'Are you sure you want to update this search?'
      )
    )
      return;

    const groupedFiltersByContext = _.reduce(
      prospectFilters,
      (result, { context, title, value }) => {
        // due to name being used for saved search, if it is a 'name' filter we need to utilize entity field
        const conditionalContext = context === 'name' ? 'entity' : context;
        if (!result[conditionalContext]) {
          result[conditionalContext] = [{ title, value }];
        } else {
          result[conditionalContext] = [
            ...result[conditionalContext],
            { title, value },
          ];
        }

        return result;
      },
      {}
    );

    try {
      const updatedSearch = await editSavedSearch({
        searchId: prospectSearch.id,
        name: prospectSearch.search_name || prospectSearch.name,
        filters: groupedFiltersByContext,
      });
      log.event('savedSearchEditSuccess');
      setProspectSearch(updatedSearch);
    } catch (err) {
      log.exception(err.message);
    }
  }

  function handleSaveAs() {
    setShowAddSavedSearchDialog(true);
  }

  function handleResetSearch() {
    const filters = ProspectService.getGroupedFilters(
      prospectSearch.filters || prospectSearch || []
    );
    setProspectFilters(filters);
  }

  useEffect(() => {
    if (!draftOnTerritoryDashboard) return;

    log.event('Draft Prospect Search on Territory Dashboard.');
  }, [draftOnTerritoryDashboard, log]);

  return (
    <StyledDrawer
      anchor="top"
      open={open}
      onClose={onClose}
      SlideProps={{ easing: 'cubic-bezier(0,0.75,0.25,1)' }}
      transitionDuration={{ enter: 300, exit: 300 }}
    >
      <Box>
        <Box
          sx={{
            position: 'absolute',
            overflow: 'auto',
            width: '25.5rem',
            height: '100%',
            maxHeight,
            backgroundColor: 'white',
            boxShadow: '0px 16px 16px rgba(0, 0, 0, 0.25)',
          }}
        >
          <SearchMenu />
        </Box>
        <Box
          sx={{
            marginLeft: '25.5rem',
            display: 'flex',
            flexDirection: 'column',
            maxHeight,
            overflowY: { xs: 'auto', lg: 'unset' },
          }}
        >
          <FilterTagList filters={prospectFilters} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box pl={2}>
              {draftOnTerritoryDashboard && (
                <Typography sx={{ fontSize: '0.85rem', lineHeight: '1.5rem' }}>
                  <Icon
                    icon={faExclamationTriangle}
                    style={{ marginRight: '0.5rem' }}
                    color={theme.palette.warning.main}
                  />
                  <span>Draft prospect searches </span>
                  <span
                    style={{
                      color: theme.palette.warning.main,
                      fontWeight: 500,
                    }}
                  >
                    are not reflected in Territory analytics.
                  </span>
                </Typography>
              )}
            </Box>
            <CommandSet>
              <ContextCommands show={isCustom}>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  onClick={handleResetSearch}
                  aria-label="Reset Search"
                >
                  Reset
                </Button>
              </ContextCommands>
              <SaveComboButton
                size="small"
                startIcon={<Icon icon={faSave} />}
                loading={isEditingSearch}
                onSave={canEdit ? handleSave : undefined}
                onSaveAs={handleSaveAs}
                canEdit={canEdit}
              />
            </CommandSet>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <FilterForm />
          </Box>
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default FilterDrawer;
