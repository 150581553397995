import { Menu, MenuProps, styled } from '@mui/material';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& hr': {
    opacity: '0.15',
  },
  '& .MuiPaper-root': {
    borderRadius: '6px',
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(235, 241, 242, 0.75)',
    borderBottom: `3px solid ${theme.palette.highlight.main}`,
  },
  '& .MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
}));

const OverlayMenu: React.FC<MenuProps> = ({ children, ...props }) => {
  return <StyledMenu {...props}>{children}</StyledMenu>;
};

export default OverlayMenu;
