import { useState } from 'react';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useLogging } from 'src/context';

const useGlobalSearch = () => {
  const [globalSearchResults, setGlobalSearchResults] = useState([]);
  const log = useLogging();

  async function search(searchTerm: string) {
    if (searchTerm.length <= 2) return setGlobalSearchResults([]);
    try {
      const queryParams = getSearchQueryParamsForAPI({ q: searchTerm });
      const response = await request({
        url: `/v1/search/providers/${queryParams}`,
        data: {},
        method: 'GET',
      });
      setGlobalSearchResults([...response.providers]);
    } catch (error) {
      setGlobalSearchResults([]);
      log.exception(error);
    }
  }

  return {
    globalSearchResults,
    search,
    setGlobalSearchResults,
  };
};

export default useGlobalSearch;
