import _ from 'lodash';
import { Tags } from 'src/components';

const SmartListTypeCell = ({ value }) => {
  if (!value) return null;
  const fullText = _.startCase(value);
  const tag = {
    id: 123,
    label: value,
    fullText: fullText,
    FormattedText: () => <span>{fullText}</span>,
    bgColor: '#F7E7A7',
  };

  return <Tags tags={[tag]} tagType="list" />;
};

export default SmartListTypeCell;
