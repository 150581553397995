import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetEducationProps extends MedScout.Pagination, MedScout.Sortable {
  id: string;
  type: string;
}

const useGetEducation = ({
  id,
  type,
  page,
  pageSize,
  sort,
  order,
  sort_search,
  sort_value,
}: GetEducationProps) => {
  const queryParams = getSearchQueryParamsForAPI({
    education_type: type,
    page: page + 1,
    pageSize,
    sort,
    order,
    sort_search,
    sort_value,
  });

  return useQuery(
    [QUERY_KEYS.GET_EDUCATION, id, queryParams],
    () => {
      return request({
        url: `/v1/hcp/${id}/education-network${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: !!id && !!type,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 60_000,
    }
  );
};

export default useGetEducation;
