import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';
import { QUERY_KEYS } from '../search/constants';
import { QUERY_KEYS as LISTS_KEYS } from '../lists/constants';
import { PROFILE_QUERY_MAP } from 'src/hooks';

const useLinkToDuplicateCRMObject = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [INTEGRATION_KEYS.ADD_DUPLICATE_PUSH],
    async (data: MedScout.DuplicatePush) => {
      const response = await request({
        method: 'POST',
        url: '/v1/integrations/link-to-crm-object/',
        data,
      });
      return response;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERY_KEYS.search);
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              PROFILE_QUERY_MAP.get('profile_details')
            );
          },
        });
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(LISTS_KEYS.providerListItems);
          },
        });
      },
    }
  );
};

export default useLinkToDuplicateCRMObject;
