import { Grid, Typography, Box } from '@mui/material';
import React from 'react';
import { TypeIcon } from 'src/components';

const BladeSubHeader = ({ item }) => {
  return (
    <Grid container sx={{ pb: 1 }}>
      <Grid
        item
        md={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TypeIcon
          type={item.type}
          style={{ fontSize: '1.8rem', color: 'white' }}
        />
      </Grid>
      <Grid item md={11}>
        <Box
          sx={{
            color: 'white',
            '& p': {
              fontSize: '0.8rem',
              fontWeight: 500,
              lineHeight: '1rem',
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'white',
              fontSize: '1.4rem',
              fontWeight: 600,
              pb: 0,
            }}
          >
            {item.name}
          </Typography>
          <Typography>
            {item.city}, {item.state}
          </Typography>
          <Typography> {item.specialty}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BladeSubHeader;
