import { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useGetMyTeam } from 'src/hooks';
import { useAuth } from 'src/context';
import { useGetAllUsers } from 'src/api/admin/user-management/admin-user-management';
import { nanoid } from 'nanoid';
import { Controller } from 'react-hook-form';

interface UserSelectorProps {
  control;
  id: string;
  allUsers?: boolean;
}

const UserSelector = ({ control, id, allUsers = false }: UserSelectorProps) => {
  const { user } = useAuth();
  const { data: teamData, isLoading: teamLoading } = useGetMyTeam({
    enabled: !allUsers,
  });
  const { data: allUserData, isLoading: allUserLoading } = useGetAllUsers(
    allUsers && user?.is_superuser
  );
  const isLoading = teamLoading || allUserLoading;

  const team: { id; label; group }[] = useMemo(() => {
    if (!teamData || allUsers) return [];

    const peers = teamData?.peers?.map(({ id, first_name, last_name }) => ({
      id,
      label: `${first_name} ${last_name}`,
      group: 'Colleagues',
    }));
    const managers = teamData?.managers?.map(
      ({ id, first_name, last_name }) => ({
        id,
        label: `${first_name} ${last_name}`,
        group: 'Managers',
      })
    );
    const reports = teamData?.reports?.map(({ id, first_name, last_name }) => ({
      id,
      label: `${first_name} ${last_name}`,
      group: 'Reports',
    }));

    return [...peers, ...managers, ...reports].filter((n) => n);
  }, [teamData]);

  const system: { id; label; group }[] = useMemo(() => {
    if (!allUserData || !allUsers) return [];

    const system = allUserData.users.map(({ id, first_name, last_name }) => ({
      id,
      label: `${first_name} ${last_name}`,
      group: 'All Users',
    }));

    return [...system];
  }, [allUsers, allUserData]);

  return (
    <Controller
      name={id}
      control={control}
      render={({ formState: { errors }, field: { onChange, onBlur } }) => (
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              label={isLoading ? 'Loading...' : 'Select a team member'}
              helperText={errors[id]?.message}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <li {...props} key={nanoid()} value={option.id}>
              {option.label}
            </li>
          )}
          groupBy={(option) => option.group}
          options={allUsers ? system : team}
          onChange={(e: React.ChangeEvent<HTMLLIElement>) => {
            onChange(e.target.value);
          }}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default UserSelector;
