import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import _ from 'lodash';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';
import { PIPELINE_QUERY_MAP } from 'src/hooks';

function useRemoveTeamMember() {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, { company_id; repUserId }>(
    // company_id used for cache busting
    ({ company_id, repUserId }) => {
      return request({
        url: `/v1/manager/team/remove/${repUserId}`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries(
          PROSPECT_OVERVIEW_QUERY_KEYS.all_rep_prospects
        );
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('my_lists'),
          body.company_id,
        ]);
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('all_normalized_prospects'),
        ]);
      },
    }
  );
}

export default useRemoveTeamMember;
