import { Box, Grid, Typography, styled } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import {
  GridAddToListButton,
  GridColumnMenu,
  GridExportMenu,
  GridProviderDeleteButton,
  GridTrendingButton,
} from './components';
import { Container } from '@mui/joy';

const ControlLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

interface TableControlsProps {
  onExport: (type: string) => void;
  columns: any[];
  columnVisibilityModel: any;
  setColumnVisibilityModel: any;
  showDeleteButton?: boolean;
  showAddToListButton?: boolean;
  showTrendingButton?: boolean;
  variant?: 'affiliations' | null;
}

const TableControls = ({
  onExport,
  columns,
  columnVisibilityModel,
  setColumnVisibilityModel,
  showDeleteButton = false,
  showAddToListButton = false,
  showTrendingButton = false,
  variant = null,
}: TableControlsProps) => {
  const gridWidth =
    variant === 'affiliations' ? { xs: 12, xl: 7 } : { xs: 12, lg: 7 };
  return (
    <Grid item {...gridWidth}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          padding: '0.5rem 0',
        }}
      >
        <Box sx={{ flex: 2 }}>
          <ControlLabel>Search List</ControlLabel>
          <GridToolbarQuickFilter
            variant="outlined"
            fullWidth
            sx={{
              minHeight: '100%',
              minWidth: '100%',
              p: 0,
              m: 0,
            }}
            InputProps={{
              startAdornment: (
                <Icon icon={faSearch} color="#cdd7e1" size="sm" />
              ),
              endAdornment: null,
              placeholder: 'by Name, Specialty or Location',
              fullWidth: true,
              sx: {
                borderRadius: '0.375rem',
                height: '2.25rem',
                width: '100%',
                minWidth: '100%',
                border: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fieldset: {
                  border: '1px solid #cdd7e1',
                  /* --joy-shadow-xs */
                  boxShadow: '0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
                  width: '100%',
                  minWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
                input: {
                  padding: '0.5rem',
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  letterSpacing: '0.01071em',
                  color: '#32383e',
                  width: '100%',
                  minWidth: '100%',
                  backgroundColor: '#fbfcfe',
                },
                '&:hover': {
                  border: 0,
                  fieldset: {
                    border: '1px solid #cdd7e1',
                  },
                },
              },
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <ControlLabel /> {/* Empty label to maintain layout */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              gap: '0.5rem',
              mt: '1.1rem',
              ml: '0.5rem',
            }}
          >
            {showDeleteButton && <GridProviderDeleteButton />}
            {showAddToListButton && <GridAddToListButton />}
            <GridExportMenu onExport={onExport} />
            <GridColumnMenu
              columns={columns}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
            />
            {showTrendingButton && <GridTrendingButton />}
          </Box>
        </Box>
      </Container>
    </Grid>
  );
};

export default TableControls;
