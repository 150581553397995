import { Menu, MenuProps } from '@mui/material';

const ListMenu: React.FC<MenuProps> = ({
  children,
  open = false,
  onClose,
  onClick,
  anchorEl,
}) => {
  return (
    <Menu
      aria-label="list option menu"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onClick={onClick}
    >
      {children}
    </Menu>
  );
};

export default ListMenu;
