import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    // avoid authorization header for SSR support
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const isUnauthorizedError = error.response?.status === 401;

    if (error.response?.status === 500) Sentry.captureException(error);
    if (error?.code === 'ECONNABORTED') Sentry.captureException(error);

    // for errors that are not 401s immediately throw
    if (!isUnauthorizedError) return Promise.reject(error);

    // 401 errors, you get logged out
    if (isUnauthorizedError) window.location.href = '/auth/logout';
  }
);

export default axiosInstance;
