import { Option, Select, Tooltip, Box, styled, Typography } from '@mui/joy';
import { useProspectSearch, useLogging } from 'src/context';

const SelectorLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

const VolumeTypeToggle = () => {
  const log = useLogging();
  const { prospectSearch, prospectVolumeType, setProspectVolumeType } =
    useProspectSearch();

  return (
    <Box sx={{ p: 0 }}>
      <SelectorLabel>Volume Type</SelectorLabel>
      <Tooltip
        title="Please select a saved search."
        arrow
        disableHoverListener={!!prospectSearch?.id}
        disableFocusListener={!!prospectSearch?.id}
        disableTouchListener={!!prospectSearch?.id}
        placement="top-start"
      >
        <Select
          defaultValue="claims"
          value={prospectVolumeType}
          onChange={(e, newValue) => {
            setProspectVolumeType(newValue as 'claims' | 'patients');
            log.event('Volume Type Changed', {
              value: newValue,
            });
          }}
          disabled={!prospectSearch?.id}
          sx={{ width: '100%', minWidth: '140px' }}
          slotProps={{
            listbox: {
              sx: {
                zIndex: 9999, // This would not show any other way on the result lists
              },
            },
          }}
          aria-label="Volume Type"
        >
          <Option value="claims" aria-label="claims">
            Claims
          </Option>
          <Option value="patients" aria-label="patients">
            Patients
          </Option>
        </Select>
      </Tooltip>
    </Box>
  );
};

export default VolumeTypeToggle;
