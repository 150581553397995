import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from './constants';

/**
 * Custom hook for fetching suggestions based on search parameters.
 * @param params - The search parameters.
 * @returns The result of the query.
 */
export default function useGetSuggestions(params) {
  return useQuery(
    [QUERY_KEYS.suggestions, params],
    async () => {
      const queryParams = getSearchQueryParamsForAPI({
        type: params.type,
        context: params.context,
        q: params.q,
        company_id: params.company_id,
      });

      const promise = request({
        url: `/v1/query/suggestions${queryParams}`,
        data: {},
        method: 'GET',
      });

      return promise;
    },
    {
      enabled: !!params.q,
      refetchOnWindowFocus: false,
      keepPreviousData: !!params.q,
    }
  );
}
