import { Box, Typography } from '@mui/material';
import React from 'react';

const GroupsCell = ({ row }) => {
  if (!row.groups) return null;

  function renderGroups(row) {
    return row.groups
      ?.filter((group) => group.name)
      ?.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <div>
      {renderGroups(row)?.map((group, index) => {
        return (
          <Box key={index}>
            <Typography
              sx={{
                fontSize: '0.6rem',
                fontWeight: 500,
                whiteSpace: 'nowrap',
              }}
            >
              {group.name}
            </Typography>
          </Box>
        );
      })}
    </div>
  );
};

export default GroupsCell;
