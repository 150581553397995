import React from 'react';
import * as Sentry from '@sentry/react';
import { useLogging, useNotification, useLayoutControl } from 'src/context';
import { useLinkToDuplicateCRMObject } from 'src/hooks';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const PushToCRMDuplicateDialog = ({ open, onClose }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const { pushDuplicateToCRM } = useLayoutControl();
  const { mutateAsync: linkToDuplicateCRMObject, isLoading } =
    useLinkToDuplicateCRMObject();

  async function handleSubmit() {
    try {
      await linkToDuplicateCRMObject({
        crm: pushDuplicateToCRM?.crm?.toLowerCase(),
        crm_id: pushDuplicateToCRM.crm_id,
        crm_type: pushDuplicateToCRM.crm_type,
        object_type: pushDuplicateToCRM.object_type,
        object_id: pushDuplicateToCRM.object_id,
        mapping: pushDuplicateToCRM.mapping,
      });
      setNotification({
        title: 'Success',
        message: 'Provider linked to existing CRM record',
        type: 'success',
      });
    } catch (err: any) {
      setNotification({
        title: 'Error',
        message: 'Error linking to existing CRM record',
        type: 'error',
      });
      log.exception(`Error in PushToCRMDuplicateDialog: ${err}`);
    }

    log.event('Push Duplicate to CRM', {
      ...pushDuplicateToCRM,
    });

    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Record Exists</DialogTitle>
      <DialogContent>
        {`We found a ${pushDuplicateToCRM?.crm_type} matching this provider in your CRM. Would you
          like to use it?`}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.5rem 1rem',
            gap: '0.5rem',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
              log.event('Push Duplicate to CRM - Cancel', {
                ...pushDuplicateToCRM,
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit} // now
          >
            Link to Existing
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PushToCRMDuplicateDialog;
