import {
  faUserMd,
  faFileMedicalAlt,
  faHospital,
  faCapsules,
  faProcedures,
  faClinicMedical,
  faTags,
  faMedkit,
  faMoneyBill,
  faCrosshairs,
  faWallet,
} from '@fortawesome/pro-solid-svg-icons';

const SEARCH_INPUT_TYPES = {
  SELECT_MIN_MAX: 'SELECT_MIN_MAX',
};

const searchTypeMap = {
  spec: {
    label: 'Provider Specialties',
    placeholder: 'e.g. Acute Care',
    icon: faProcedures,
    resultInfoMap: {
      titlePath: 'specialty',
      valuePath: 'id',
      subTitlePath: null,
    },
  },
  HCP_name: {
    label: 'Physician Name',
    placeholder: 'e.g. Susan Smith',
    icon: faUserMd,
    tooltipDataKey: 'physicianProfile',
    resultInfoMap: {
      titlePath: 'name',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  C_name: {
    label: 'Center Name',
    placeholder: 'e.g. Abc Medical Care',
    icon: faClinicMedical,
    resultInfoMap: {
      titlePath: 'name',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  combo_volume: {
    label: 'Codes',
    placeholder: 'e.g. 3218F, A4397, etc.',
    icon: faFileMedicalAlt,
    tooltipData: [
      {
        header: 'ICD-10',
        key: 'icd',
      },
      {
        header: 'HCPCS',
        key: 'hcpcs',
      },
      {
        header: 'CPT®',
        key: 'cpt',
      },
    ],
    resultInfoMap: null,
  },
  // TODO: type-specificity no longer necessary
  center_combo_volume: {
    label: 'Codes',
    placeholder: 'e.g. 001U079, 4671, etc.',
    icon: faFileMedicalAlt,
    tooltipData: [
      {
        header: 'DRG',
        key: 'drg',
      },
      {
        header: 'ICD',
        key: 'icd',
      },
      {
        header: 'APC',
        key: 'apc',
      },
      {
        header: 'HCPCS',
        key: 'hcpcs',
      },
      {
        header: 'CPT®',
        key: 'cpt',
      },
    ],
    resultInfoMap: null,
  },
  cpt: {
    label: 'CPT® Procedures',
    placeholder: 'e.g. 3218F',
    icon: faFileMedicalAlt,
    tooltipDataKey: 'cpt',
    resultInfoMap: {
      titlePath: 'code',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  icd: {
    label: 'ICD-10 Diagnoses',
    placeholder: 'e.g. T2114XA',
    icon: faFileMedicalAlt,
    tooltipDataKey: 'icd',
    resultInfoMap: {
      titlePath: 'code',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  drg: {
    label: 'DRG Procedures',
    placeholder: 'e.g. 001U079',
    icon: faFileMedicalAlt,
    tooltipDataKey: 'drg',
    resultInfoMap: {
      titlePath: 'code',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  dme: {
    label: 'Durable Medical Equipment',
    placeholder: 'e.g. A4397',
    icon: faMedkit,
    tooltipDataKey: 'dme',
    resultInfoMap: {
      titlePath: 'code',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  hcpcs: {
    label: 'HCPCS',
    placeholder: 'e.g. A4397',
    icon: faMedkit,
    tooltipDataKey: 'hcpcs',
    resultInfoMap: {
      titlePath: 'code',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  apc: {
    label: 'APC Procedures',
    placeholder: 'e.g. 5671',
    icon: faFileMedicalAlt,
    tooltipDataKey: 'apc',
    resultInfoMap: {
      titlePath: 'code',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  icdp: {
    label: 'ICD-10 Procedures',
    placeholder: 'e.g. 0P594ZZ',
    tooltipDataKey: 'icdp',
    icon: faFileMedicalAlt,
    resultInfoMap: {
      titlePath: 'code',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  drug: {
    label: 'Prescriptions',
    placeholder: 'e.g. Alphagan P',
    tooltipDataKey: 'prescriptions',
    icon: faCapsules,
    resultInfoMap: {
      titlePath: 'name',
      subTitlePath: null,
      valuePath: 'id',
    },
  },
  system: {
    label: 'Hospital Systems',
    placeholder: "e.g. Children's Health",
    icon: faHospital,
    resultInfoMap: {
      titlePath: 'system',
      subTitlePath: null,
      valuePath: 'id',
    },
  },
  payments: {
    label: 'Open Payments: Company',
    placeholder: 'e.g. Boston Scientific',
    tooltipDataKey: 'payments',
    icon: faMoneyBill,
    inputType: SEARCH_INPUT_TYPES.SELECT_MIN_MAX,
    resultInfoMap: {
      titlePath: 'name',
      subTitlePath: null,
      valuePath: 'id',
    },
  },
  tags: {
    label: 'Tags',
    placeholder: 'e.g. Existing',
    icon: faTags,
    resultInfoMap: {
      titlePath: 'name',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  payer: {
    label: 'Payer',
    placeholder: 'e.g. Commercial, Medicare...',
    icon: faWallet,
    resultInfoMap: {
      titlePath: 'name',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
  site: {
    label: 'Site of Service',
    placeholder: 'e.g. Office, Worksite...',
    icon: faCrosshairs,
    resultInfoMap: {
      titlePath: 'name',
      subTitlePath: 'description',
      valuePath: 'id',
    },
  },
};

export const SearchBoxConstants = {
  searchTypeMap,
  SEARCH_INPUT_TYPES,
};
