import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useGetMappingById = ({ mappingId, enabled }) => {
  return useQuery(
    [INTEGRATION_KEYS.GET_MAPPING, mappingId],
    () => {
      return request({
        url: `/v1/integrations/mappings/${mappingId}/`,
        method: 'GET',
      });
    },
    {
      enabled,
    }
  );
};

export default useGetMappingById;
