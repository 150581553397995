import React from 'react';
import { Box } from '@mui/joy';
import Pagination from '@mui/material/Pagination';
import { grey } from '@mui/material/colors';

const ClientPagination = ({ page, perPage, count, onPageChange }) => {
  const numberOfPages = Math.ceil(count / perPage) || 0;

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: -1,
        zIndex: 2,
        boxShadow: '0px 0 10px rgba(0, 0, 0, 0.25)',
      }}
    >
      {numberOfPages > 1 && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'white',
              padding: '1rem 0.5rem',
              borderTop: `1px solid ${grey[300]}`,
            }}
          >
            <Pagination
              count={numberOfPages}
              page={page}
              onChange={onPageChange}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ClientPagination;
