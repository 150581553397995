import Tags from './Tags';
import { ChipProps } from '@mui/material';
import { useAuth } from 'src/context';

/**
 * ProviderListTags
 *
 * @param {*} { lists, size = 'small' }
 * @return {*}
 */
const ProviderListTags: React.FC<{
  lists: MedScout.List[];
  size?: ChipProps['size'];
}> = ({ lists, size = 'small' }) => {
  const { user } = useAuth();

  if (!lists || lists.length === 0) return null;

  const max = 4;
  const formattedTags = lists?.map((list) => {
    return {
      id: list.id,
      label: list.name,
      FormattedText: () => <span>{list.name}</span>,
      fullText: formatFullPath(list),
      href: `/lists/${user.id === list.owner.id ? 'my' : 'shared'}/${list.id}`,
    };
  });

  const groupStyle =
    formattedTags.length > 1
      ? {
          pl: '2.25rem',
          '& .MuiLink-root': {
            marginLeft: '-1.25rem',
            minWidth: '4rem',
            '&>.MuiChip-root': {
              paddingRight: '1rem',
            },
            '&:first-of-type>.MuiChip-root': {
              paddingRight: formattedTags.length <= max ? 'unset' : '1rem',
            },
          },
          '& .MuiAvatarGroup-avatar': {
            marginLeft: '-1rem',
          },
        }
      : {};

  const surplusStyle = {
    '&>div.MuiAvatarGroup-avatar': {
      height: 'unset',
      borderRadius: '1rem',
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 0,
      color: 'unset',
      border: '1px dotted #000',
      backgroundColor: 'white',
      marginLeft: '-2rem !important',
      fontSize: '0.75rem',
      fontWeight: '600',
    },
  };

  function formatFullPath(list) {
    return [
      [list?.owner?.first_name, list.owner.last_name].join(' '),
      list.name,
    ].join(' > ');
  }

  return (
    <Tags
      tags={formattedTags}
      size={size}
      max={max}
      sx={{
        justifyContent: 'flex-end',
        ...groupStyle,
        ...surplusStyle,
      }}
    />
  );
};

export default ProviderListTags;
