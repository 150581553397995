// /api/v1/analytics/dashboards/{dashboard id}/render/{territory_id}/export/?saved_search_ids=[list, of, ids, ...]
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface DashboardBladeExportParams {
  dashboardId: number;
  territoryId: number | string;
  enabledSearchIds: string[];
  type: string;
}

/**
 * @description   This hook is used to export the data for a dashboard blade
 * @param         {DashboardBladeExportParams} params
 */

const useTerritoryDashboardExport = ({
  dashboardId,
  territoryId,
  enabledSearchIds,
  type,
}: DashboardBladeExportParams) => {
  return {
    getExport: async () => {
      const queryParams = getSearchQueryParamsForAPI({
        saved_search_ids: enabledSearchIds,
        type: type || '',
      });

      return request({
        url: `/v1/analytics/dashboards/${dashboardId}/render/${territoryId}/export/${queryParams}`,
        method: 'GET',
      });
    },
  };
};

export default useTerritoryDashboardExport;
