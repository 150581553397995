import { Dropdown, Menu, MenuButton, IconButton } from '@mui/joy';
import ExportMenuItem from './ExportMenuItem';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const GridExportMenu = ({ onExport }) => {
  const theme = useTheme();
  const dropdownRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!anchorEl) return;
    setAnchorEl(null);
  }, [isMobile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAnchorEl(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleExport = (type: string) => {
    onExport(type);
  };

  const toggleMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  return (
    <Dropdown>
      {isMobile ? (
        <IconButton
          variant="soft"
          color="neutral"
          onClick={toggleMenu}
          sx={{
            fontWeight: 900,
          }}
        >
          <Icon icon={faArrowToBottom} fontWeight={900} size="lg" />
        </IconButton>
      ) : (
        <MenuButton
          startDecorator={<Icon icon={faArrowToBottom} fontWeight={900} />}
          variant="soft"
          color="neutral"
          onClick={toggleMenu}
          sx={{
            fontWeight: 900,
          }}
        >
          Export
        </MenuButton>
      )}
      <Menu
        placement="bottom-end"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        sx={{
          boxShadow: 'sm',
          flexGrow: 0,
          minWidth: 200,
          maxHeight: 300,
          overflow: 'auto',
        }}
        ref={dropdownRef}
      >
        <ExportMenuItem type="csv" onExport={handleExport} />
        <ExportMenuItem type="xlsx" onExport={handleExport} />
      </Menu>
    </Dropdown>
  );
};

export default GridExportMenu;
