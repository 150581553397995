import { useEffect } from 'react';
import { Box, Select, MenuItem } from '@mui/material';
import { Toggle } from 'src/components';
import { faUserDoctor, faHospital } from '@fortawesome/pro-solid-svg-icons';
import { useProspectSearch, useLogging } from 'src/context';

const ResultTypeSelector = ({ legacy = false }) => {
  const log = useLogging();
  const { prospectType, setProspectType, prospectMode, setProspectMode } =
    useProspectSearch();
  // const [recordType, setRecordType] = useState('treating');
  const toggleOrientation = prospectType === 'C' ? 'right' : 'left';

  useEffect(() => {
    // If coming back from a profile, etc and the mode is 'receiving', switch it to 'sending'
    // Receiving is not available here.
    if (prospectMode === 'receiving') setProspectMode('sending');
  });

  useEffect(() => {
    if (prospectType === 'C') setProspectMode('code_volume');
  }, [prospectType]);

  return (
    <Toggle
      leftIcon={faUserDoctor}
      rightIcon={faHospital}
      leftLabel="Providers"
      rightLabel="Sites"
      onLeft={() => {
        setProspectType('HCP');
        log.event('Toggle Changed: HCP');
      }}
      onRight={() => {
        setProspectType('C');
        log.event('Toggle Changed: Centers');
      }}
      status={toggleOrientation}
      showLabels
      slots={
        !legacy
          ? {
              action: () => null,
              thumb: ({ sx, ...props }) => (
                <Select
                  value={prospectMode || 'code_volume'}
                  size="small"
                  onChange={(e) => {
                    log.event('Mode Changed', {
                      mode: e.target.value,
                    });
                    setProspectMode(e.target.value as typeof prospectMode);
                  }}
                  disabled={prospectType === 'C'}
                  sx={{
                    borderRadius: '1rem',
                    '--Switch-thumbHeight': '1.6rem',
                    '--Switch-thumbWidth': '3.5rem',
                    '--mui-palette-text-disabled': 'white',
                    '& .MuiSelect-select': {
                      width: 'var(--Switch-thumbWidth)',
                      fontSize: '0.8rem',
                      fontWeight: 400,
                      // padding: '0.15rem 0.75rem',
                      color: 'white',
                    },
                    '&.Mui-disabled .MuiSelect-icon': {
                      display: 'none',
                    },
                    '& .MuiSelect-icon': {
                      color: 'white',
                    },
                  }}
                  {...props}
                >
                  <MenuItem value="code_volume">Treating</MenuItem>
                  <MenuItem value="sending">Referring</MenuItem>
                </Select>
              ),
            }
          : {}
      }
      sx={
        !legacy
          ? {
              width: '8rem',
              '& .MuiSwitch-thumb': {
                transform: 'translate(0, 0)',
              },
              '&.Mui-checked .MuiSwitch-thumb': {
                transform: 'translate(20px, 0)',
              },
            }
          : {}
      }
    />
  );
};

export default ResultTypeSelector;
