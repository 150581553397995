import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { useAuth } from 'src/context';
import { QUERY_KEYS } from './constants';

/**
 * Custom hook for deleting a saved search.
 *
 * @returns {MutationFunction} The mutation function for deleting a saved search.
 */
export default function useDeleteSavedSearch() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(
    ({ searchId }: { searchId: string }) => {
      return request({
        url: `/v1/saved-searches/${searchId}`,
        data: {},
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QUERY_KEYS.saved_searches,
          user?.company?.id,
        ]);
      },
    }
  );
}
