import { MAP_ACTIONS, MapStateType, ReducerAction } from '../map';

export const mapReducer = (
  state: MapStateType,
  action: ReducerAction
): MapStateType => {
  switch (action.type) {
    case MAP_ACTIONS.SET_SELECT_TERRITORIES: {
      if (action.payload.preventEditingAndCreationReset)
        return {
          ...state,
          selectedTerritories: action.payload.territories,
        };

      return {
        ...state,
        selectedTerritories: action.payload.territories,
        // editingTerritory: null,
        // tempTerritoryCoordinates: [],
        // tempSelectedCounties: [],
        // tempSelectedZipCodes: [],
        // createNewTerritoryMode: false,
      };
    }
    case MAP_ACTIONS.SET_EXISTING_TERRITORIES: {
      return {
        ...state,
        existingTerritories: action.payload,
        // selectedTerritories: [],

        // editingTerritory: null,
        // tempTerritoryCoordinates: [],
        // tempSelectedCounties: [],
        // tempSelectedZipCodes: [],
        // createNewTerritoryMode: false,
      };
    }
    case MAP_ACTIONS.ADD_TEMP_TERRITORY_COORDINATE: {
      return {
        ...state,
        tempTerritoryCoordinates: [...action.payload],
      };
    }
    case MAP_ACTIONS.REMOVE_TEMP_TERRITORY_COORDINATE: {
      return {
        ...state,
        tempTerritoryCoordinates: state.tempTerritoryCoordinates.filter(
          (coordinate) => coordinate !== action.payload
        ),
      };
    }
    case MAP_ACTIONS.ADD_TEMP_SELECTED_STATE: {
      const uniqueStates = new Set(state.tempSelectedStates);
      action.payload.forEach((state: string) => uniqueStates.add(state));
      return {
        ...state,
        tempSelectedStates: [...Array.from(uniqueStates)],
      };
    }
    case MAP_ACTIONS.REMOVE_TEMP_SELECTED_STATE: {
      let copyState = [...state.tempSelectedStates];
      if (action.payload.removeAll) {
        copyState = [];
      } else {
        action.payload.items.forEach((state: string) => {
          const index = copyState.indexOf(state);
          if (index > -1) copyState.splice(index, 1);
        });
      }
      return {
        ...state,
        tempSelectedStates: [...copyState],
      };
    }
    case MAP_ACTIONS.ADD_TEMP_SELECTED_COUNTY: {
      const uniqueCounties = new Set(state.tempSelectedCounties);
      action.payload.forEach((county: string) => uniqueCounties.add(county));
      return {
        ...state,
        tempSelectedCounties: [...Array.from(uniqueCounties)],
      };
    }
    case MAP_ACTIONS.REMOVE_TEMP_SELECTED_COUNTY: {
      let copyState = [...state.tempSelectedCounties];
      if (action.payload.removeAll) {
        copyState = [];
      } else {
        action.payload.items.forEach((county: string) => {
          const index = copyState.indexOf(county);
          if (index > -1) copyState.splice(index, 1);
        });
      }
      return {
        ...state,
        tempSelectedCounties: [...copyState],
      };
    }
    case MAP_ACTIONS.ADD_TEMP_SELECTED_ZIP_CODE: {
      const uniqueZips = new Set(state.tempSelectedZipCodes);
      action.payload.forEach((zip: string) => uniqueZips.add(zip));
      return {
        ...state,
        tempSelectedZipCodes: [...Array.from(uniqueZips)],
      };
    }
    case MAP_ACTIONS.REMOVE_TEMP_SELECTED_ZIP_CODE: {
      let copyZips = [...state.tempSelectedZipCodes];

      if (action.payload.removeAll) {
        copyZips = [];
      } else {
        action.payload.items.forEach((zip: string) => {
          const index = copyZips.indexOf(zip);
          if (index > -1) copyZips.splice(index, 1);
        });
      }

      return {
        ...state,
        tempSelectedZipCodes: [...copyZips],
      };
    }
    case MAP_ACTIONS.TOGGLE_EDIT_TERRITORY_MODE: {
      return {
        ...state,
        createNewTerritoryMode: action.payload,
        existingTerritories: [],
        // tempTerritoryCoordinates: [],
        // tempSelectedCounties: [],
        // tempSelectedZipCodes: [],
        // editingTerritory: null,

        // selectedTerritories: [],
      };
    }
    case MAP_ACTIONS.EDIT_TERRITORY: {
      const coordinatesClone = [...(action.payload?.coordinates ?? [])];

      const coordinates =
        action.payload?.geometry_type === 'Polygon'
          ? [coordinatesClone]
          : action.payload?.geometry_type === 'MultiPolygon'
          ? coordinatesClone
          : [];

      return {
        ...state,
        editingTerritory: action.payload,
        tempTerritoryCoordinates: coordinates,
        tempSelectedCounties: [],
        tempSelectedZipCodes: [],
        createNewTerritoryMode: false,
        existingTerritories: [],
      };
    }
    case MAP_ACTIONS.SET_DRAWING_MANAGER_DRAW_MODE: {
      return {
        ...state,
        drawingManagerDrawMode: action.payload,
      };
    }

    case MAP_ACTIONS.CLEAR_OVERLAY: {
      return {
        ...state,
        clearOverlay: action.payload,
      };
    }

    case MAP_ACTIONS.BOUNDARIES_LOADING: {
      return {
        ...state,
        boundariesLoading: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
