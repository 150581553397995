import { QUERY_KEYS } from './constants';
import { request } from 'src/api/request';
import { useQuery, UseQueryResult } from 'react-query';

function useGetTerritory({ territoryId }: { territoryId: number | string }) {
  return useQuery(
    [QUERY_KEYS.territory, territoryId],
    () => {
      if (!territoryId) return Promise.resolve(null);

      return request({
        url: `v1/accounts/territories/${territoryId}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !!territoryId,
    }
  ) as UseQueryResult<MedScout.Territory>;
}

export default useGetTerritory;
