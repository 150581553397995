import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormHelperText, TextField } from '@mui/material';
import ControlLabel from './ControlLabel';

interface ControlledInputProps {
  name: string;
  type?: string;
  label?: string;
  rules?: any;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  value?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  size?: 'small' | 'medium';
  min?: number;
  max?: number;
  helperText?: string;
  disabled?: boolean;
  showLabel?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
  onBlur?: () => void;
  onChange?: (e) => void;
  onKeyDown?: (e) => void;
}

const ControlledInput = ({ ...props }: ControlledInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!props) return null;

  const errorMessage = props?.helperText || 'This field is required';
  const required = props.required || props.rules?.required;

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: required ? errorMessage : false,
        ...props.rules,
      }}
      render={({ field }) => {
        return (
          <ControlLabel
            label={props?.label || ''}
            required={required}
            showLabel={props.showLabel}
            hasTooltip={props.hasTooltip}
            tooltipText={props.tooltipText}
          >
            <TextField
              {...field}
              type={props.type || 'text'}
              value={field.value || ''}
              onKeyDown={props.onKeyDown || null}
              onChange={(e) => {
                field.onChange(e);
                if (props.onChange) props.onChange(e);
              }}
              onBlur={props.onBlur || field.onBlur}
              autoFocus={props.autoFocus}
              placeholder={props.placeholder || 'Enter a value...'}
              variant={props.variant || 'standard'}
              fullWidth
              error={!!errors[props.name]}
              InputProps={{
                inputProps: {
                  min: props.min,
                  max: props.max,
                },
              }}
              helperText={props.helperText || ''}
              disabled={props.disabled || false}
              size={props.size || 'small'}
              aria-label={props.label || ''}
            />
            <FormHelperText error sx={{ ml: 0 }}>
              {!!errors[props.name] && (errors[props.name]?.message as string)}
            </FormHelperText>
          </ControlLabel>
        );
      }}
    />
  );
};

export default ControlledInput;
