export const QUERY_KEYS = {
  territory: 'territory',
  territoryExport: 'territoryExport',
  volume_territories: 'volume_territories',
  my_territories: 'my_territories',
  analytics_territories: 'analytics_territories',
  counties: 'counties',
  zipCodes: 'zipCodes',
  territories: 'territories',
  boundaries: 'boundaries',
};
