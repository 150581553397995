import React from 'react';
import LineChart from './LineChart';
interface TerritoryChartBodyProps {
  chartData: any;
  searchValues: any;
}

const TerritoryChartBody = ({
  chartData,
  searchValues,
}: TerritoryChartBodyProps) => {
  // The following is a gross hack. Filter out the total volume data for body
  // (it's used in the header, not the body of the blade/tearoff)
  // TODO: chart "cards" should be described with a type attribute
  const charts = chartData.filter(
    (result) => result.card_name !== 'Total Volume'
  );

  return (
    <>
      {charts.map((data, index) => {
        const max = Math.max(
          ...data.results.reduce(
            (agg, r) => [...agg, ...r.data.map((d) => Number(d.y))],
            []
          )
        );

        return (
          <LineChart
            key={index}
            data={data}
            searchValues={searchValues}
            yScale={{
              type: 'linear',
              min: 0,
              max: max + 1000,
            }}
          />
        );
      })}
    </>
  );
};

export default TerritoryChartBody;
