import React from 'react';
import { useLogging } from 'src/context';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faLineChart, faHashtag } from '@fortawesome/pro-solid-svg-icons';
import { Box, Button, IconButton } from '@mui/joy';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEnabledSearchIds } from 'src/modules/Territory/context/enabledSearchIds';
import { useTerritoryContext } from 'src/modules/Territory/context/TerritoryContext';
import { grey } from '@mui/material/colors';

const GridTrendingButton = () => {
  const log = useLogging();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [enabledSearchIds] = useEnabledSearchIds();
  const { volumeToggle, setVolumeToggle } = useTerritoryContext();
  return (
    <>
      {isMobile ? (
        <IconButton
          variant="soft"
          color="neutral"
          onClick={() => {
            log.event(`Toggle Volume Cell`, {
              type: volumeToggle ? 'Total' : 'Trend',
            });
            setVolumeToggle(!volumeToggle);
          }}
          sx={{
            fontWeight: 900,
          }}
        >
          <Icon
            icon={volumeToggle ? faHashtag : faLineChart}
            fontWeight={900}
            size="lg"
          />
        </IconButton>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-end',
            gap: '0.5rem',
            flexWrap: 'nowrap',
            padding: '0.5rem 0',
          }}
        >
          <Button
            aria-label="Toggle Volume Cell"
            variant="soft"
            color="neutral"
            sx={{ color: grey[800] }}
            disabled={enabledSearchIds.length > 0 ? false : true}
            startDecorator={
              <Icon icon={volumeToggle ? faHashtag : faLineChart} />
            }
            onClick={() => {
              log.event(`Toggle Volume Cell`, {
                type: volumeToggle ? 'Total' : 'Trend',
              });
              setVolumeToggle(!volumeToggle);
            }}
          >
            {volumeToggle ? 'Total' : 'Trend'}
          </Button>
        </Box>
      )}
    </>
  );
};

export default GridTrendingButton;
