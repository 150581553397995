import { create } from 'zustand';
import createDataSourcesInfoSlice from './createDataSourcesInfoSlice';
import createKanbanSlice from './createKanbanSlice';
import createNotificationSlice from './createNotificationSlice';

export const useStore = create((set, get) => ({
  ...createDataSourcesInfoSlice(set, get),
  ...createKanbanSlice(set, get),
  ...createNotificationSlice(set, get),
}));
