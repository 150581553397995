import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';

function useGetAllRepProspects({ enabled = true, company_id }) {
  return useQuery(
    [PROSPECT_OVERVIEW_QUERY_KEYS.all_rep_prospects, company_id],
    () => {
      const queryParams = getSearchQueryParamsForAPI({ company_id });

      return request({
        url: `/v1/manager${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled,
      staleTime: 600_000,
      keepPreviousData: true,
    }
  );
}

export default useGetAllRepProspects;
