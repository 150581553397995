import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Card,
  CardContent,
  Radio,
  Typography,
  Box,
  useTheme,
} from '@mui/material';

const ControlledRadioCard = ({ ...props }) => {
  const theme = useTheme();
  const { control, watch } = useFormContext();
  const { name, value, label, description } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Card
          elevation={0}
          sx={{
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '0.25rem',
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.grey[100],
            },
          }}
          onClick={() => field.onChange(value)}
        >
          <CardContent
            sx={{
              display: 'flex',
            }}
          >
            <Radio
              checked={watch(name) === value}
              onChange={() => field.onChange(value)}
            />
            <Box>
              <Typography sx={{ fontWeight: 600 }}>{label}</Typography>
              <Typography sx={{ fontSize: '0.85rem', fontWeight: 500 }}>
                {description}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    />
  );
};

export default ControlledRadioCard;
