import { styled, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

const ListsHeaderComponent = styled(Box)(() => ({
  '& .MuiTypography-root': { color: grey[800] },
}));

const ListsHeader: React.FC = ({ children }) => {
  return <ListsHeaderComponent>{children}</ListsHeaderComponent>;
};

export default ListsHeader;
