import { Box } from '@mui/material';
import React from 'react';

const StreetAddressCell = ({ row }) => {
  return (
    <Box component="address" sx={{ '&>p': { fontSize: '0.65rem' } }}>
      {row.address_line_1 && row.address_line_1} <br />
      {row.address_line_2 && <p>{row.address_line_2}</p>}
    </Box>
  );
};

export default StreetAddressCell;
