import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useGetObjectTypeAttributes = ({ type, enabled }) => {
  return useQuery(
    [INTEGRATION_KEYS.GET_OBJECT_TYPE_ATTRIBUTES, type],
    () => {
      return request({
        url: `/v1/integrations/mappings/${type}/available-attributes/`,
        method: 'GET',
      });
    },
    { enabled }
  );
};

export default useGetObjectTypeAttributes;
