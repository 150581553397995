import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from './constants';

/**
 * Custom hook for getting code details.
 *
 * @param {Object} options - The options for the hook.
 * @param {string} options.context - The context for the code details.
 * @param {string} options.value - The value of the code.
 * @param {boolean} options.enable - Flag to enable/disable the hook.
 * @returns {QueryResult} - The result of the query.
 */
export default function useGetCodeDetails({ context, value, enable }) {
  return useQuery(
    [QUERY_KEYS.code_details, context, value],
    async () => {
      const queryParams = getSearchQueryParamsForAPI({
        context,
        code: value,
      });

      return request({
        url: `/v1/query/code${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: Boolean(context) && Boolean(value) && enable,
      staleTime: 120000,
      refetchOnWindowFocus: false,
    }
  );
}
