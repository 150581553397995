import React, { useRef, useState, useEffect } from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { localizeVolume } from 'src/utils/stringFormat.helpers';
import { StaticGoogleMap, Path } from 'react-static-google-map';
import { env } from 'src/constants';
import Link from 'next/link';
import _ from 'lodash';

const DataLabel = styled(Typography)`
  font-size: 0.65rem;
  font-weight: 300;
  font-style: italic;
  color: white;
  padding-bottom: 0.5em;
`;
const DataValue = styled(Typography)`
  color: white;
  font-size: 0.85rem;
  padding-bottom: 0.25em;
  padding-left: 0.5rem;
`;
const VolumeGroup = styled('dl')({
  display: 'flex',
  flexWrap: 'wrap',
  color: 'white',
  alignItems: 'center',
  margin: '0',
  paddingLeft: '0.5rem',
  '& dt, & dd': {
    padding: 0,
    margin: 0,
  },
  '& dt': {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    fontWeight: 300,
    fontSize: '1rem',
  },
  '& dd': {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
});

interface TerritoryPathProps {
  paths;
}

const renderTerritoryPaths = ({ paths }: TerritoryPathProps) => {
  const isNew = !!paths?.polygons;
  const newPaths = isNew ? paths?.polygons : paths?.old_polygons;
  // If the first element is an array, we have multiple polygons.
  if (Array.isArray(newPaths[0])) {
    return newPaths.map((path, i) => {
      return <Path key={`polygon_${i}`} points={path} />;
    });
  } else {
    return <Path key={`polygon`} points={newPaths} />;
  }
};

interface TerritorySubheaderProps {
  data: any;
  volumeData: any;
}

const TerritorySubheader: React.FC<TerritorySubheaderProps> = ({
  data,
  volumeData,
}) => {
  const [mapSize, setMapSize] = useState('');
  const mapCellRef = useRef(null);

  useEffect(() => {
    function sizeMap() {
      if (mapCellRef.current) {
        setMapSize(
          `${mapCellRef.current.clientWidth}x${mapCellRef.current.clientHeight}`
        );
      }
    }

    window.addEventListener('resize', () => {
      sizeMap();
    });
    sizeMap();
  }, []);

  if (!data) return null;

  return (
    <Grid container sx={{ border: '1px #597992 solid', minHeight: '10rem' }}>
      <Grid
        item
        xs={6}
        sx={{
          borderRight: '1px #597992 solid',
          borderCollapse: 'collapse',
          padding: '0.5rem',
        }}
      >
        <DataLabel>Associated Volumes:</DataLabel>

        {volumeData.map((result, i) => {
          return (
            <VolumeGroup key={`totalVolume_${i}`}>
              <dt>{_.truncate(result.label, { length: 32 })}</dt>
              <dd>{localizeVolume(result.value)}</dd>
            </VolumeGroup>
          );
        })}
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          borderRight: '1px #597992 solid',
          borderCollapse: 'collapse',
          padding: '0.5rem',
        }}
      >
        <DataLabel>Owner:</DataLabel>
        <DataValue variant="body1">{`${data.owner?.first_name} ${data.owner?.last_name}`}</DataValue>
      </Grid>
      <Grid ref={mapCellRef} item xs={3}>
        {mapSize && data?.polygons?.length && (
          <Link href={`search/${data.id}`}>
            <StaticGoogleMap size={mapSize} apiKey={env.GOOGLE_MAPS_KEY}>
              {renderTerritoryPaths({ paths: data })}
            </StaticGoogleMap>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default TerritorySubheader;
