class DashboardService {
  static formatDashboardData(data: any, colors: any) {
    if (!data) return;

    // add color to each saved search
    const formattedData = data.results
      .map((card: any) => {
        card.results.forEach((result: any, i: number) => {
          result.color = colors[i].main;
        });
        return card;
      })
      .filter((card: any) => {
        return card.results.every((result: any) => result.data);
      });

    return Object.assign({}, data, { results: formattedData });
  }
}

export default DashboardService;
