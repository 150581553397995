import { request } from 'src/api/request';
import { useQueryClient, useMutation } from 'react-query';
import _ from 'lodash';
import { PIPELINE_QUERY_MAP } from '../';
import { useAuth } from 'src/context';

export default function useNewPipeline() {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const { mutateAsync, isLoading } = useMutation(
    ({
      listName,
      company_id,
      company_shared,
      manager_shared,
      shared_user_ids,
    }: {
      listName: string;
      company_id?: string;
      company_shared?;
      manager_shared?;
      shared_user_ids?;
    }) => {
      const conditionalSharedPayload = {};
      if (!_.isNil(company_shared)) {
        conditionalSharedPayload['company_shared'] = company_shared;
      }

      if (!_.isNil(manager_shared)) {
        conditionalSharedPayload['manager_shared'] = manager_shared;
      }

      if (!_.isNil(shared_user_ids)) {
        conditionalSharedPayload['shared_user_ids'] = shared_user_ids;
      }

      return request({
        url: `/v1/prospects/pipeline/`,
        method: 'POST',
        data: {
          name: listName,
          company_id,
          ...conditionalSharedPayload,
        },
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('my_lists'),
          body.company_id,
        ]);
      },
    }
  );

  async function create({
    name,
    company_shared,
    manager_shared,
    shared_user_ids,
  }) {
    if (!name) return;

    await mutateAsync({
      listName: name,
      company_id: user?.company?.id?.toString(),
      company_shared,
      manager_shared,
      shared_user_ids,
    });
  }

  return { mutateAsync, isLoading, create };
}
