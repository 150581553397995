import { useState } from 'react';
import {
  Typography,
  styled,
  Link as MuiLink,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import Link from 'next/link';
import { grey } from '@mui/material/colors';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: grey[500],
  '&:hover': {
    textDecoration: 'none',
    color: grey[700],
  },
}));

interface CrumbProps {
  crumb: MedScout.Breadcrumb;
  isLast?: boolean;
}

const Crumb = ({ crumb, isLast = false }: CrumbProps) => {
  const theme = useTheme();
  const router = useRouter();
  const pathname = usePathname();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return crumb?.options ? (
    <>
      <MuiLink
        underline="none"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
          fontWeight: 600,
          '&:hover': {
            color: grey[900],
          },
        }}
      >
        {crumb.name} <Icon icon={faChevronDown} size="sm" />
      </MuiLink>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {crumb?.options?.map((crumb, index) => {
          const isCurrent = crumb.href === pathname;
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                router.push(crumb.href);
              }}
              sx={{
                borderLeft: isCurrent
                  ? `5px solid ${theme.palette.highlight.main}`
                  : `5px solid transparent`,
                backgroundColor: isCurrent ? grey[100] : 'transparent',
              }}
            >
              {crumb.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  ) : isLast ? (
    <Typography
      variant="body1"
      color="textPrimary"
      sx={{
        fontWeight: 600,
      }}
    >
      {crumb.name}
    </Typography>
  ) : (
    <StyledLink href={crumb.href}>{crumb.name}</StyledLink>
  );
};

export default Crumb;
