import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS as TERRITORY_KEYS } from 'src/hooks/territories/constants';
import { QUERY_KEYS as REFERRAL_KEYS } from 'src/hooks/dashboards/constants';

import { QUERY_KEYS } from './constants';

/**
 * Custom hook for editing a saved search.
 *
 * @returns A mutation function for editing a saved search.
 */
export default function useEditSavedSearch() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      searchId,
      name,
      filters,
    }: {
      searchId: string;
      name: string;
      filters?: any;
    }) => {
      const updatedSearch = { name };

      if (filters) {
        Object.assign(updatedSearch, filters);
      }

      return request({
        url: `/v1/account/saved-searches/${searchId}/`,
        method: 'PATCH',
        data: updatedSearch,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.saved_searches]);

        // invalidate the analytics territories query when the saved search is updated
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              TERRITORY_KEYS.analytics_territories
            );
          },
        });

        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(REFERRAL_KEYS.referrals);
          },
        });
      },
    }
  );
}
