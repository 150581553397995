import { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  styled,
  ClickAwayListener,
} from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSearch, faClose } from '@fortawesome/pro-solid-svg-icons';
import SearchResults from './components/SearchResults';
import { grey } from '@mui/material/colors';

// hooks
import { useGlobalSearch } from 'src/hooks';
import { useDebounce } from 'use-debounce';
import { useLogging, useLayoutControl } from 'src/context';

const StyledTextField = styled(TextField)(({ theme }) => ({
  textAlign: 'right',
  input: {
    textAlign: 'right',
  },
  '.MuiOutlinedInput-root': {
    height: '2.45rem',
    borderRadius: '0.25rem',
    backgroundColor: theme.palette.primary.light,
    boxShadow: 'inset 1px 1px 3px 0 rgba(0, 0, 0, 0.5)',
    whiteSpace: 'nowrap',
    color: '#2C3D49',
    '&:hover fieldset': {
      border: '1px solid white',
      boxShadow: 'inset 1px 1px 5px 0 rgba(0, 0, 0, 0.5)',
    },
    '&.Mui-focused fieldset, & fieldset': {
      border: '1px solid white',
    },
  },
}));

const GlobalSearch = () => {
  const inputRef = useRef(null);
  const boxRef = useRef(null);
  const containerRef = useRef(null);
  const log = useLogging();
  const { setShowFilterDrawer } = useLayoutControl();

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const { search, globalSearchResults, setGlobalSearchResults } =
    useGlobalSearch();
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  useEffect(() => {
    search(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    open && inputRef.current.focus();
  }, [open]);

  function handleClickOutside() {
    setOpen(false);
    setGlobalSearchResults([]);
    setIsSearching(false);
    setSearchTerm('');
  }

  function handleClearSearch() {
    setSearchTerm('');
    setGlobalSearchResults([]);
    setIsSearching(false);
  }

  function handleSearchOpened() {
    setOpen(true);
    setShowFilterDrawer(false);
    log.event('Global Search Opened');
  }

  async function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value.length ? setIsSearching(true) : setIsSearching(false);
    setSearchTerm(e.target.value);
  }

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <Box ref={boxRef} sx={{ position: 'relative' }}>
        <Box
          ref={containerRef}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            overflow: 'hidden',
            width: !open ? '32px' : 'auto',
          }}
        >
          <StyledTextField
            inputRef={inputRef}
            id="search"
            aria-label="Global Search"
            placeholder="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            sx={{
              '& .MuiInputBase-root': {
                paddingRight: '0.5rem',
              },
              width: open ? '100%' : '0%',
              opacity: open ? '1' : '0',
              transition:
                'width 0.2s cubic-bezier(0,0.75,0.25,1), opacity 0.2s cubic-bezier(0,0.75,0.25,1)',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm.length ? (
                    <Icon
                      icon={faClose}
                      fontSize="1.3rem"
                      color={grey[800]}
                      onClick={handleClearSearch}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <Icon icon={faSearch} fontSize="1.3rem" color={grey[800]} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            sx={{
              position: 'absolute',
              opacity: open ? '0' : '1',
              display: open ? 'none' : 'block',
              transition: 'opacity 0.2s cubic-bezier(0,0.75,0.25,1)',
            }}
            onClick={handleSearchOpened}
            aria-label="Global Search Show"
          >
            <Icon icon={faSearch} fontSize="1.3rem" color="#fff" />
          </IconButton>
        </Box>
        <SearchResults
          results={globalSearchResults}
          isSearching={isSearching}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default GlobalSearch;
