import {
  Dispatch,
  createContext,
  useContext,
  useState,
  SetStateAction,
} from 'react';

const EnabledSearchIdsContext = createContext<
  [string[], Dispatch<SetStateAction<string[]>>]
>([[], () => []]);

export const EnabledSearchIdsProvider = ({ children }) => {
  const searchIdState = useState([]);

  return (
    <EnabledSearchIdsContext.Provider value={searchIdState}>
      {children}
    </EnabledSearchIdsContext.Provider>
  );
};

export const useEnabledSearchIds = () => {
  const context = useContext(EnabledSearchIdsContext);
  if (context === undefined) {
    throw new Error(
      'useEnabledSearchIds must be used within a EnabledSearchIdsProvider'
    );
  }

  return context;
};
