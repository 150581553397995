import { Slider as MuiSlider, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

interface RootProps {
  isSet: boolean;
}

export const Slider = styled(MuiSlider, {
  shouldForwardProp: (prop) => prop !== 'isSet',
})<RootProps>(({ theme, isSet }) => ({
  color: isSet ? theme.palette.filter['year'].main : grey[500],
  height: 2,
  margin: '0 1rem',
  display: 'block',
  width: 'unset',
  '& .MuiSlider-thumb': {
    height: '0.625rem',
    width: '0.625rem',
    borderRadius: 0,
    marginTop: '-0.313rem',
    backgroundColor: isSet ? theme.palette.filter['year'].main : grey[500],
    '&::after': {
      content: '""', // You have to have the double quotes here, take them out and see what happens. lol
      position: 'absolute',
      left: '0.313rem',
      right: 0,
      top: '.75rem',
      margin: '0 auto',
      width: 0,
      height: 0,
      borderTop: `8px solid ${
        isSet ? theme.palette.filter['year'].main : grey[500]
      }`,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
    },
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    top: '-0.375rem',
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-markLabel': {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    marginTop: '-0.5rem',
  },
  '& .MuiSlider-track': {
    border: 'none',
    color: isSet
      ? theme.palette.filter['year'].light
      : 'rgba(148, 148, 148, .30)',
    height: '0.625rem',
    marginTop: '-0.25rem',
    padding: '0',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: grey[500],
  },
  '& .MuiSlider-mark': {
    backgroundColor: grey[500],
    height: '0.375rem',
    width: '0.063rem',
    marginTop: '0.188rem',
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));
