import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useAuth } from 'src/context';
import { QUERY_KEYS } from './constants';

/**
 * Custom hook to fetch saved searches for the selected company.
 * @returns The result of the query.
 */
export default function useGetSavedSearches() {
  const { user } = useAuth();

  return useQuery(
    [QUERY_KEYS.saved_searches, user?.company?.id],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        company_id: user?.company?.id,
      });
      return request({
        url: `/v1/saved-searches${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      staleTime: 30000,
    }
  );
}
