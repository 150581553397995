import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetSearchCRMParams {
  crmType: string;
  q: string;
  option: MedScout.UserInputOption;
}

const useGetSearchCRM = ({ crmType, q, option }: GetSearchCRMParams) => {
  const queryParams = getSearchQueryParamsForAPI({
    q,
    option: option?.id,
  });

  return useQuery(
    [INTEGRATION_KEYS.GET_SEARCH_CRM, crmType, q, option],
    async () => {
      return await request({
        url: `/v1/integrations/crm-object/${crmType.toLowerCase()}/search/${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: !!crmType || !!q.length || !!option,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  ) as UseQueryResult<MedScout.CRMOptionLookup[]>;
};

export default useGetSearchCRM;
