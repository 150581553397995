import { useState, forwardRef } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import { useLogging, useProspectSearch } from 'src/context';
import { useGetCodeDetails } from 'src/hooks';
import { HtmlToolTip } from 'src/components';
import {
  Typography,
  Chip,
  ChipProps,
  Box,
  ListItem,
  ClickAwayListener,
  useTheme,
  styled,
} from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

const FilterCategory = styled(Typography)`
  font-size: 0.85rem;
  font-weight: 600;
`;
const FilterSubcategory = styled(Typography)`
  font-size: 0.75rem;
  padding-bottom: 0.5rem;
`;

const FilterTag = forwardRef<
  undefined,
  { label: string; type: string; color; handleRemove; onClick? } & ChipProps
>(
  (
    { label, type, color, handleRemove, onClick = undefined, ...props },
    ref
  ) => {
    const lightColor = color?.light || 'primary.light';
    const mainColor = color?.main || 'primary.main';

    return (
      <Chip
        ref={ref}
        label={label}
        size="small"
        onClick={onClick}
        onDelete={handleRemove}
        sx={{
          border: `1px solid ${mainColor}`,
          borderLeftWidth: '0.75rem',
          backgroundColor: lightColor,
          '& .MuiChip-deleteIcon': {
            color: mainColor,
          },
        }}
        {...props}
      />
    );
  }
);
FilterTag.displayName = 'FilterTag';

const FilterTagWithContext = ({ filter, label, color, handleRemove }) => {
  const [showContext, setShowContext] = useState(false);
  const log = useLogging();
  const { data, isLoading } = useGetCodeDetails({
    context: filter.context,
    value: filter.value,
    enable: showContext,
  });

  return (
    <ClickAwayListener onClickAway={() => setShowContext(false)}>
      <HtmlToolTip
        open={showContext}
        title={label}
        onClose={() => setShowContext(false)}
        color={color.main}
        isLoading={isLoading}
        description={
          <>
            {data?.code?.category && (
              <FilterCategory>{data?.code?.category}</FilterCategory>
            )}
            {!_.isEmpty(data?.code?.sub_categories) && (
              <FilterSubcategory>
                {_.join(data?.code?.sub_categories, ', ')}
              </FilterSubcategory>
            )}
            <Typography variant="body1">
              {data?.code?.description || 'No description available.'}
            </Typography>
          </>
        }
      >
        <FilterTag
          label={label}
          type={filter.context}
          color={color}
          onClick={() => {
            log.event('filterTooltipDisplayed');
            setShowContext(true);
          }}
          handleRemove={handleRemove}
        />
      </HtmlToolTip>
    </ClickAwayListener>
  );
};

interface FilterTagListProps {
  filters: any[];
  onAddFilter?: () => void;
  onRemoveFilter?: (filter: any) => void;
}

/**
 * Renders a list of filter tags
 *
 * @param {*} {
 *   filters,
 *   onRemoveFilter,
 * }
 * @return {*}
 */
const FilterTagList: React.FC<FilterTagListProps> = ({
  filters,
  onRemoveFilter,
}) => {
  const { prospectFilters, setProspectFilters, setProspectSearch } =
    useProspectSearch();
  const log = useLogging();
  const theme = useTheme();

  function removeFilter(removedFilter) {
    const newFilterSet = prospectFilters.filter(
      (filter) =>
        filter.context != removedFilter.context ||
        filter.value != removedFilter.value
    );
    log.event('removeFilter', {
      context: removedFilter.title,
    });
    log.event('applyFilters', {
      context: newFilterSet.map((f) => f.title),
    });
    if (newFilterSet.length === 0) {
      setProspectSearch(null);
    }
    setProspectFilters(newFilterSet);
  }

  return (
    <Box
      component="ul"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        listStyle: 'none',
        px: '1rem',
        py: '0.5rem',
        m: 0,
        gap: '0.25rem',
        maxHeight: { lg: '8rem' },
        overflowY: { lg: 'auto' },
      }}
    >
      {_.isEmpty(filters) && (
        <Typography sx={{ fontSize: '0.85rem', lineHeight: '1.5rem' }}>
          <Icon
            icon={faExclamationTriangle}
            style={{ marginRight: '0.5rem' }}
            color={theme.palette.warning.main}
          />
          <span>No codes or filters selected. </span>
          <span style={{ color: theme.palette.warning.main, fontWeight: 500 }}>
            Add some to return results.
          </span>
        </Typography>
      )}
      {_.map(filters, (filter, idx) => {
        const contextIdentifiersToInclude = {
          cpt: true,
          hcpcs: true,
          icd: true,
          drg: true,
          apc: true,
          icdp: true,
        };
        const includeContext = contextIdentifiersToInclude[filter.context];
        const conditionalTrademark = filter.context === 'cpt' ? '®' : '';
        const display = includeContext
          ? `${_.upperCase(filter.context)}${conditionalTrademark}-${
              filter.title
            }`
          : filter.title;
        let secondary = '';
        if (_.isArray(filter.value)) {
          const values = filter.value.slice(1);
          const [min, max] = values;
          const formattedMin = numeral(min).format('$0,0');
          const formattedMax = numeral(max).format('$0,0');
          if (_.isNil(max)) {
            // no max value, and no floor include any
            secondary += min === 0 ? `: Any payments` : `: ${formattedMin}+`;
          } else if (max === 0) {
            secondary += ': No payments';
          } else if (min === 0 && max > 0) {
            secondary += `: Below ${formattedMax}`;
          } else {
            secondary += `: ${formattedMin} - ${formattedMax}`;
          }
        }

        if (filter.context === 'year') {
          const currentYear = new Date().getFullYear();
          const { value, title } = filter;

          try {
            // new Date returns December 31st of the previous year if you just give it a year.
            const titleDate = new Date(title).getFullYear() + 1;

            // If the title is a valid year, previous to this year, and the value is 'now', add a suffix to the filter tag.
            if (
              !isNaN(titleDate) &&
              titleDate < currentYear &&
              JSON.parse(value).lte === 'now'
            ) {
              secondary += '- Current';
            }
          } catch (err: any) {
            // just so it doesn't throw to the user
            console.error(`Error parsing date: ${err.message}`);
          }
        }

        return (
          <ListItem
            key={`${display}_${secondary}_${idx}`}
            sx={{ p: 0, m: 0, width: 'auto' }}
          >
            {includeContext ? (
              <FilterTagWithContext
                filter={filter}
                label={`${display} ${secondary}`}
                color={theme.palette.filter[filter.context]}
                handleRemove={onRemoveFilter || (() => removeFilter(filter))}
              />
            ) : (
              <FilterTag
                label={`${display} ${secondary}`}
                color={theme.palette.filter[filter.context]}
                type={filter.context}
                handleRemove={onRemoveFilter || (() => removeFilter(filter))}
              />
            )}
          </ListItem>
        );
      })}
    </Box>
  );
};

export default FilterTagList;
