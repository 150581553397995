import {
  NOTIFICATION_ACTIONS,
  NotificationStateType,
  NotificationReducerAction,
} from '../notification';

export const notificationReducer = (
  state: NotificationStateType,
  action: NotificationReducerAction
): NotificationStateType => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};
