import React from 'react';
import {
  FormControl,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

const FormControlHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showLabel',
})<{ showLabel: boolean }>(({ theme, showLabel }) => ({
  display: !showLabel ? 'none' : 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  marginBottom: '0.25rem',
}));

interface ControlLabelProps {
  label: string;
  children: React.ReactNode;
  hasTooltip?: boolean;
  required?: boolean;
  tooltipText?: string;
  showLabel?: boolean;
}

const ControlLabel = ({ showLabel = true, ...props }: ControlLabelProps) => {
  const theme = useTheme();

  return (
    <FormControl fullWidth>
      <FormControlHeader showLabel={showLabel}>
        <Typography
          sx={{
            fontSize: '0.85rem',
            fontWeight: 600,
            color: theme.palette.grey[700],
            gap: '0.5rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {props?.label || ''}
          {props?.hasTooltip && (
            <Tooltip
              title={
                props?.tooltipText ||
                'This field is required to complete the form'
              }
              placement="bottom-end"
              arrow
            >
              <Icon
                icon={faInfoCircle}
                color={theme.palette.grey[500]}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </Typography>
        {props?.required && showLabel && (
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: 600,
              color: theme.palette.grey[500],
            }}
          >
            (required)
          </Typography>
        )}
      </FormControlHeader>
      {props.children}
    </FormControl>
  );
};

export default ControlLabel;
