import React from 'react';
import _ from 'lodash';
import { green, red, grey } from '@mui/material/colors';

import { Typography, Box, styled } from '@mui/material';
import { useListsLayoutControl } from 'src/modules/Lists/context';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-solid-svg-icons';

const VolumeTrendContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  '&:after': {
    content: '""',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 15px 15px 0',
    borderColor: 'transparent #fab005 transparent transparent',
    right: '0',
    top: '0',
    position: 'absolute',
  },
});

const VolumeShifterValueCell = ({ row, field }) => {
  const { toggleSmartListsDrawer } = useListsLayoutControl();

  const percentage =
    row[field]?.volume_shift || row.list_value?.replace(/%/g, '');

  // check if the percentage is negative
  const isNegative = percentage < 0;

  // calculate the number of arrows to display, min 1, max 5
  const numArrows = Math.min(Math.max(percentage, 1), 5);

  // calculate the number of grey arrows to display, min 0, max 4
  const numGreyArrows = 5 - numArrows;

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <VolumeTrendContainer onClick={() => toggleSmartListsDrawer(row)}>
        <Typography variant="subtitle2">
          {Array.from({ length: numArrows }).map((_, i) => (
            <Icon
              key={`positive-${i}`}
              icon={isNegative ? faChevronLeft : faChevronRight}
              color={isNegative ? red[600] : green[600]}
              fontWeight={900}
              style={{
                marginRight: '-8px',
              }}
              size="2xl"
            />
          ))}
          {Array.from({ length: numGreyArrows }).map((_, i) => (
            <Icon
              key={`negative-${i}`}
              icon={faChevronLeft}
              color={grey[200]}
              style={{
                marginRight: '-8px',
              }}
              size="2xl"
            />
          ))}
        </Typography>
      </VolumeTrendContainer>
    </Box>
  );
};

export default VolumeShifterValueCell;
