import React from 'react';
import { Drawer, DrawerProps, Box, styled } from '@mui/material';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import BladeHeader from './BladeHeader';
import BladeSubheader from './BladeSubheader';
import BladeContent from './BladeContent';
interface RootProps {
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
}

const BladeComponent: React.FC<DrawerProps & RootProps> = styled(Drawer, {
  shouldForwardProp: (prop) =>
    prop !== 'width' && prop !== 'minWidth' && prop !== 'maxWidth',
})<RootProps>(({ theme, width, minWidth, maxWidth }) => ({
  width: isNaN(Number(width)) ? width : `${width}rem`,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
    boxSizing: 'border-box',
    top: '3.4rem',
    bottom: 0,
    height: 'auto',
    width: isNaN(Number(width)) ? width : `${width}rem`,
    minWidth: isNaN(Number(minWidth)) ? minWidth : `${minWidth}rem`,
    maxWidth: isNaN(Number(maxWidth)) ? maxWidth : `${maxWidth}rem`,
  },
}));

interface BladeProps {
  icon?: IconDefinition;
  title: string;
  headerSlots?: {
    slot1?: React.ReactNode;
    slot2?: React.ReactNode;
    slot3?: React.ReactNode;
  };
  route?: string;
  subHeader?: React.ReactNode;
  commands?: React.ReactNode[];
  hideBackdrop?: boolean;
  variant?: 'temporary' | 'persistent' | 'permanent';
  onClose?: () => void;
  onExport?: () => void;
}

/**
 * @name Blade
 * @description Level 3 content component, used for displaying a drawer with a title and commands. Can be torn off into a new window.
 *
 * @param {*} {
 *   onClose,
 *   open,
 *   title,
 *   headerSlots,
 *   subHeader,
 *   icon,
 *   children,
 *   commands,
 *   hideBackdrop = false,
 *   variant = 'temporary',
 *   width = '45%',
 *   minWidth = '40rem',
 *   maxWidth = '50rem',
 * }
 * @return {*}
 */
const Blade: React.FC<DrawerProps & BladeProps & RootProps> = ({
  onClose,
  onExport,
  open,
  icon,
  title,
  route,
  headerSlots,
  subHeader,
  commands = [],
  children,
  hideBackdrop = false,
  variant = 'temporary',
  width = '45%',
  minWidth = 40,
  maxWidth = 50,
}) => {
  return (
    <BladeComponent
      open={open}
      onClose={onClose}
      variant={variant}
      hideBackdrop={hideBackdrop}
      anchor="right"
      SlideProps={{
        easing: 'cubic-bezier(0,0.75,0.25,1)',
      }}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
    >
      <BladeHeader
        slots={headerSlots}
        commands={commands}
        onClose={onClose}
        route={route}
        onExport={onExport}
        title={title}
        icon={icon}
      />
      <BladeContent>
        {subHeader && <BladeSubheader>{subHeader}</BladeSubheader>}
        <Box>{children}</Box>
      </BladeContent>
    </BladeComponent>
  );
};

export default Blade;
