import { forwardRef } from 'react';
import {
  default as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { styled } from '@mui/material';

const StyledButton = styled(MuiIconButton)`
  min-width: unset;
  padding: unset;

  :hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface IconButtonProps extends MuiIconButtonProps {
  icon: IconDefinition;
  label?: string;
  iconSize?: SizeProp;
}

/**
 * A button that renders a FontAwesome icon
 *
 * @param { IconDefinition } icon - the FontAwesome icon to render
 * @param IconButtonProps
 * @return { React.FC }
 */
const IconButton: React.FC<IconButtonProps> = forwardRef(
  ({ icon, label = '', color, iconSize = null as SizeProp, ...props }, ref) => {
    return (
      <StyledButton
        ref={ref}
        LinkComponent="span"
        aria-label={label}
        {...props}
      >
        <Icon icon={icon} color={color} size={iconSize} />
      </StyledButton>
    );
  }
);
IconButton.displayName = 'IconButton';

export default IconButton;
