import _ from 'lodash';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers'; // TODO: This is a bit of a hack, we should probably move this to a more generic location

class ProspectService {
  static sortByVolume(a: string, b: string) {
    const aNum = Number(a === '<11' ? '1' : a || '0');
    const bNum = Number(b === '<11' ? '1' : b || '0');

    return aNum - bNum;
  }
  static getGroupedFilters(savedSearch) {
    const validSearchContext = {
      ex_pr: 'ex_pr',
      apc: 'apc',
      cpt: 'cpt',
      drg: 'drg',
      drug: 'drug',
      hcpcs: 'hcpcs',
      icd: 'icd',
      icdp: 'icdp',
      spec: 'spec',
      system: 'system',
      payments: 'payments',
      tags: 'tags',
      entity: 'name', // special cased from the API due to the name of saved search colliding
      site: 'site',
      payer: 'payer',
      year: 'year',
    };

    return _.reduce(
      savedSearch,
      (result, value, key) => {
        const context = validSearchContext[key];

        if (context) {
          _.forEach(value, (filter) => {
            result.push({ context, ...filter });
          });
        }

        // short circuit if it is not a field that contains filters we allow
        return result;
      },
      []
    );
  }

  static getQueryString(filters, type) {
    const groupedFiltersByContext = filters.reduce(
      (result, { context, value }) => {
        if (!result[context]) {
          result[context] = [value];
        } else {
          result[context] = [...result[context], value];
        }

        return result;
      },
      {}
    );

    const sortedParams = _.reduce(
      groupedFiltersByContext,
      (result, currentParams, key) => {
        if (_.isArray(currentParams)) {
          result[key] = _.sortBy(_.uniq(currentParams));
        } else {
          result[key] = currentParams;
        }

        return result;
      },
      {}
    );

    return getSearchQueryParamsForAPI({
      type,
      ex_pr: 0,
      ...sortedParams,
    });
  }
}

export default ProspectService;
