import React from 'react';
import { Stepper, Step, StepButton } from '@mui/material';

const StepIndicator = ({ steps, activeStep, completed, handleStep }) => {
  return (
    <Stepper nonLinear activeStep={activeStep}>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton color="inherit" onClick={() => handleStep(index)}>
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepIndicator;
