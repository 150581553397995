import { Button, Box, styled, useTheme } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

const StyledPipelineButtonWrapper = styled(Box)<{
  variant: 'pill' | 'rectangle';
}>(({ theme, variant }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: variant === 'pill' ? '0' : '0.25rem',
  backgroundColor: 'white',
  borderTopLeftRadius: variant === 'pill' ? '1rem' : '0.25rem',
  borderBottomRightRadius: variant === 'pill' ? '1rem' : '0.25rem',
  display: 'flex',
  alignItems: 'center',
  gap: variant === 'pill' ? '0.25rem' : '0.5rem',
  paddingLeft: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',
}));

const StyledPipelineButton = styled(Button)`
  font-size: 0.65rem;
  font-weight: 400;
  white-space: nowrap;
  border-radius: 0;
`;

const AddToListButton: React.FC<{
  onClick: () => void;
  variant?: 'rectangle' | 'pill';
}> = ({ onClick, variant = 'rectangle' }) => {
  const theme = useTheme();
  const iconVariant = variant === 'pill' ? faPlusCircle : faPlusSquare;
  const { useNewListsModule } = useFlags();

  return (
    <StyledPipelineButtonWrapper variant={variant} onClick={onClick}>
      <Icon icon={iconVariant} color={theme.palette.primary.main} size="lg" />
      <StyledPipelineButton
        variant="contained"
        size="small"
        sx={
          variant === 'pill'
            ? {
                borderTopLeftRadius: '0.95rem',
                px: '1rem',
              }
            : { px: '1rem' }
        }
      >
        {useNewListsModule ? 'Add to List' : 'Add to Pipeline'}
      </StyledPipelineButton>
    </StyledPipelineButtonWrapper>
  );
};

export default AddToListButton;
