import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

function useGetBoundaries({ bounding_box = [], type = 'states', enabled }) {
  const queryParams = getSearchQueryParamsForAPI({
    bounding_box,
  });

  return useQuery(
    [QUERY_KEYS.boundaries, bounding_box, type],
    () => {
      return request({
        url: `v1/accounts/boundaries/${type}${queryParams}`,
        data: {},
        method: 'GET',
        timeout: 60_000,
      });
    },
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
}

export default useGetBoundaries;
