function RevisionContent() {
  return (
    <div>
      <p>
        In order to access CPT data, our data provider enforces these terms and
        conditions. If you have any questions, please feel free to contact{' '}
        <a href="mailto:support@medscout.io" className="default-link">
          support@medscout.io
        </a>
        .
      </p>

      <hr />

      <h6 className="mt-3 mb-1">End User Point and Click Agreement:</h6>
      <p>
        CPT codes, descriptions and other data only are copyright 1995 - 2019
        American Medical Association. All rights reserved. CPT is a registered
        trademark of the American Medical Association (AMA). You, your employees
        and agents are authorized to use CPT only as contained in the following
        authorized materials of MedScout internally within your organization
        within the United States for the sole use by yourself, employees and
        agents. Use is limited to use in Medicare, Medicaid or other data made
        available by MedScout You agree to take all necessary steps to insure
        that your employees and agents abide by the terms of this agreement. Any
        use not authorized herein is prohibited, including by way of
        illustration and not by way of limitation, making copies of CPT for
        resale and/or license, transferring copies of CPT to any party not bound
        by this agreement, creating any modified or derivative work of CPT, or
        making any commercial use of CPT. License to use CPT for any use not
        authorized herein must be obtained through the AMA, CPT Intellectual
        Property Services, AMA Plaza, 330 N. Wabash Ave., Suite 39300, Chicago,
        IL 60611-5885. Applications are available at the AMA Web site,
        http://www.ama-assn.org/go/cpt.
      </p>

      <h6 className="mt-3 mb-1">
        Applicable FARS\DFARS Restrictions Apply to Government Use.
      </h6>
      <p>
        This product includes CPT which is commercial technical data and/or
        computer databases and/or commercial computer software and/or commercial
        computer software documentation, as applicable, which were developed
        exclusively at private expense by the American Medical Association, 515
        North State Street, Chicago, Illinois, 60654. U.S. Government rights to
        use, modify, reproduce, release, perform, display, or disclose these
        technical data and/or computer databases and/or computer software and/or
        computer software documentation are subject to the limited rights
        restrictions of DFARS 252.227-7015(b)(2) (November 1995) and/or subject
        to the restrictions of DFARS 227.7202-1(a) (June 1995) and DFARS
        227.7202-3(a) (June 1995), as applicable for U.S. Department of Defense
        procurements and the limited rights restrictions of FAR 52.227-14 (June
        1987) and/or subject to the restricted rights provisions of FAR
        52.227-14 (June 1987) and FAR 52.227-19 (June 1987), as applicable, and
        any applicable agency FAR Supplements, for non-Department of Defense
        Federal procurements.
      </p>

      <h6 className="mt-3 mb-1">
        AMA Disclaimer of Warranties and Liabilities.
      </h6>
      <p>
        CPT is provided &ldquo;as is&rdquo; without warranty of any kind, either
        expressed or implied, including but not limited to, the implied
        warranties of merchantability and fitness for a particular purpose. No
        fee schedules, basic unit, relative values or related listings are
        included in CPT. The AMA does not directly or indirectly practice
        medicine or dispense medical services. The responsibility for the
        content of this file/product is with MedScout and no endorsement by the
        AMA is intended or implied. The AMA disclaims responsibility for any
        consequences or liability attributable to or related to any use,
        non-use, or interpretation of information contained or not contained in
        this file/product. This agreement will terminate upon notice if you
        violate its terms. The AMA is a third party beneficiary to this
        agreement.
      </p>

      <h6 className="mt-3 mb-1">MedScout Disclaimer</h6>
      <p>
        The scope of this license is determined by the AMA, the copyright
        holder. Any questions pertaining to the license or use of the CPT should
        be addressed to the AMA. End Users do not act for or on behalf of
        MedScout. MedScout DISCLAIMS RESPONSIBILITY FOR ANY LIABILITY
        ATTRIBUTABLE TO END USER USE OF THE CPT. MedScout WILL NOT BE LIABLE FOR
        ANY CLAIMS ATTRIBUTABLE TO ANY ERRORS, OMISSIONS, OR OTHER INACCURACIES
        IN THE INFORMATION OR MATERIAL CONTAINED ON THIS PAGE. In no event shall
        MedScout be liable for direct, indirect, special, incidental, or
        consequential damages arising out of the use of such information or
        material. Should the foregoing terms and conditions be acceptable to
        you, please indicate your agreement and acceptance by clicking below on
        the button labeled &ldquo;Accept&rdquo;.
      </p>
    </div>
  );
}

const revisionDate = '2022-06-16';
const revisionName = `${revisionDate}__web`;

export const revisionInfo_2022_06_16 = {
  ContentComponent: RevisionContent,
  revisionDate,
  revisionName,
};
