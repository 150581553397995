import {
  Dispatch,
  createContext,
  useContext,
  useState,
  SetStateAction,
} from 'react';

interface TerritoryContextValue {
  volumeToggle: boolean;
  setVolumeToggle: Dispatch<SetStateAction<boolean>>;
}

const TerritoryContext = createContext<TerritoryContextValue | null>(null);

export const TerritoryProvider = ({ children }) => {
  const [volumeToggle, setVolumeToggle] = useState(false);

  return (
    <TerritoryContext.Provider value={{ volumeToggle, setVolumeToggle }}>
      {children}
    </TerritoryContext.Provider>
  );
};

export const useTerritoryContext = () => {
  const context = useContext(TerritoryContext);
  if (context === undefined) {
    throw new Error(
      'useTerritoryContext must be used within a TerritoryProvider'
    );
  }

  return context;
};
