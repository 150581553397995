import axios from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const useGetAllCounties = () => {
  return useQuery(
    [QUERY_KEYS.counties],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        time: Date.now(),
      });
      // due to AWS not allowing the existence of an auth header ()
      // all counties does not anticipate map bounds and fetches a json redirect
      return axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}v1/map/counties${queryParams}`
      );
    },
    {
      enabled: true,
      staleTime: 600_000,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetAllCounties;
