import { Box, Option, Typography, Select, styled } from '@mui/joy';
import React from 'react';
import { useProspectSearch, useLogging } from 'src/context';

const SelectorLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '1.125rem' /* 18px */,
  paddingBottom: '0.5rem',
}));

const ReferringTreatingToggle = () => {
  const {
    prospectSearch,
    prospectMode,
    setProspectMode,
    prospectRelationship,
  } = useProspectSearch();
  const log = useLogging();

  const nameMap = {
    code_volume: 'Treating',
    sending: 'Referring',
  };
  return (
    <Box sx={{ p: 0 }}>
      <SelectorLabel>Volume Source</SelectorLabel>
      <Select
        defaultValue="code_volume"
        value={prospectMode}
        onChange={(e, newValue) => {
          const value = newValue as 'code_volume' | 'sending';
          log.event(`Toggle Changed: ${nameMap[value]}`);
          setProspectMode(value);
        }}
        disabled={!prospectSearch?.id}
        sx={{ width: '100%', minWidth: '140px' }}
        aria-label="Volume Source"
      >
        <Option value="code_volume" aria-label="treating">
          Treating
        </Option>
        <Option
          value="receiving"
          aria-label="receiving"
          hidden={prospectRelationship === 'inbound' || !prospectRelationship}
        >
          Referred
        </Option>
        <Option
          value="sending"
          aria-label="sending"
          hidden={prospectRelationship === 'outbound'}
        >
          Referred
        </Option>
      </Select>
    </Box>
  );
};

export default ReferringTreatingToggle;
