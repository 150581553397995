import { request } from 'src/api/request';

// TODO: Elminiate these hooks in favor of the new ones when merged
const useTerritories = () => {
  async function deserializeTerritories(data: File) {
    if (!data) return Promise.reject(new Error('No file provided'));

    const formData = new FormData();
    formData.append('territory', data);

    return request({
      url: '/v1/accounts/territories/import/',
      method: 'POST',
      data: formData,
    });
  }

  async function uploadTerritories(data) {
    return request({
      url: '/v1/accounts/territories/',
      method: 'POST',
      data: data,
    });
  }

  return {
    deserializeTerritories,
    uploadTerritories,
  };
};

export default useTerritories;
