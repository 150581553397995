import React from 'react';
import { useMousePosition } from 'src/utils/hooks/useMousePosition';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';

const HoverData = ({ data }) => {
  const mousePosition = useMousePosition();
  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: 'white',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: COLOR_MAP['gray-dark'],
        borderStyle: 'solid',
        top: mousePosition.y - 35,
        left: mousePosition.x - 30,
        fontSize: 14,
        fontWeight: 600,
        paddingRight: 5,
        paddingLeft: 5,
        paddingTop: 3,
        paddingBottom: 3,
      }}
    >
      <div>{data.name}</div>
    </div>
  );
};

export default HoverData;
