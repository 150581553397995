import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const TabSelector = ({
  onTabChange,
  tabs,
  value,
  variant = 'outline-primary',
  size = 'md',
}) => {
  const content = tabs.map((tab) => {
    return (
      <ToggleButton
        className="w-50"
        key={tab.value}
        id={`tab-selector-${tab.value}`}
        type="radio"
        checked={value === tab.value}
        value={tab.value}
        variant={variant}
        size={size}
        onChange={(e) => {
          onTabChange(e.currentTarget.value);
        }}
      >
        {tab.label}
      </ToggleButton>
    );
  });

  return <ButtonGroup className="w-100">{content}</ButtonGroup>;
};

export default TabSelector;
