import React from 'react';
import Image from 'next/image';
import salesforce from '/public/brands/salesforce.svg';
import hubspot from '/public/brands/hubspot.svg';
import dynamics from '/public/brands/dynamics.svg';
import pipedrive from '/public/brands/pipedrive.svg';
import zoho from '/public/brands/zoho.svg'; // might want this later
import zoho_allblue from '/public/brands/zoho_allblue.svg';

interface BrandIconProps {
  brand: string;
  height?: string;
  width?: string;
  invert?: boolean;
}

const BrandIcon = (props: BrandIconProps) => {
  const iconType = {
    salesforce: salesforce,
    hubspot: hubspot,
    dynamics: dynamics,
    zoho: zoho_allblue,
    pipedrive: pipedrive,
  };

  if (!props.brand || !iconType[props.brand]) return null;

  return (
    <Image
      src={iconType[props.brand]}
      alt={`${props.brand} icon`}
      style={{
        width: props.width || '1.6em',
        height: props.height || '1.6em',
        filter: props.invert ? 'brightness(0) invert(1)' : 'none',
      }}
    />
  );
};

export default BrandIcon;
