import { QUERY_KEYS } from './constants';
import { request } from 'src/api/request';
import { useQuery } from 'react-query';

function useGetSavedTerritory({
  territoryId,
  isAnalytics,
}: {
  territoryId: number | string;
  isAnalytics: boolean;
}) {
  return useQuery(
    [QUERY_KEYS.territory, territoryId],
    () => {
      if (!territoryId) return Promise.resolve(null);

      return request({
        url: `v1/accounts/territories/${territoryId}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !!territoryId && isAnalytics,
    }
  );
}

export default useGetSavedTerritory;
