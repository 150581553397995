import { styled, Box } from '@mui/material';

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.fill.light,
}));

const BladeSubheader = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default BladeSubheader;
