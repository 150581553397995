import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

const useRefetchTerritoryAnalytics = ({ newParams, searchIdParams }) => {
  const queryClient = useQueryClient();
  const [interval, setInterval] = useState<number | false>(false);

  const queryParams = getSearchQueryParamsForAPI({
    v: interval,
  });

  return useQuery(
    ['useTerritoryAnaltyicsRefetch'],
    () => {
      return request({
        url: `v1/analytics/saved-search/cache-status/${queryParams.concat(
          ...searchIdParams
        )}`,
        method: 'GET',
      });
    },
    {
      onSuccess: (data) => {
        /** Until we upgrade react-query this is the solution
         * determine if all of the returned values are true
         * if so, invalidate the currentKey and set the refetchInterval to false
         * else, double the refetchInterval with a limit of 2 minutes
         * In newer versions refetchInterval will be a function
         * https://tanstack.com/query/v5/docs/react/reference/useQuery
         * response object is [{"id":1,"valid":true},{"id":2,"valid":true}]
         */

        const invalidateQuery = data.every((obj) => obj.valid === true);

        if (invalidateQuery) {
          // Invalidate the currentKey
          // so that the useTerritoryAnalytics hook will refetch
          queryClient.invalidateQueries([
            QUERY_KEYS.analytics_territories,
            newParams,
          ]);

          setInterval(false);
          return;
        }

        setInterval((oldVal) => {
          if (oldVal === 120_000) return false; // don't go over 2 minutes
          return oldVal === false ? 15_000 : oldVal * 2;
        });
        return;
      },
      refetchIntervalInBackground: true,
      refetchInterval: interval,
      enabled: searchIdParams.length > 0,
    }
  );
};

export default useRefetchTerritoryAnalytics;
