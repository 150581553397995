import React from 'react';
import { usePathname } from 'next/navigation';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Crumb from './Crumb';
interface BreadcrumbProps {
  breadcrumbs?: MedScout.Breadcrumb[];
  invalidPaths?: string[];
}

/**
 * @description this component is used to generate breadcrumbs for the current page with or without a list of breadcrumbs passed in
 * @param breadcrumbs  - list of breadcrumbs to use when generating breadcrumbs
 * @param invalidPaths  - list of paths to ignore when generating breadcrumbs
 */
const Breadcrumbs = ({
  breadcrumbs = [],
  invalidPaths = [],
}: BreadcrumbProps) => {
  const pathname = usePathname();

  function generateBreadcrumbPath(): MedScout.Breadcrumb[] {
    if (!pathname) return [];
    const asPathParts = pathname
      ?.split('/')
      .filter((v) => !v.includes('[') || !v.includes(']')) // remove query params
      .filter((v) => v.length > 0);

    // remove invalid paths from the list
    const filteredAsPathParts = asPathParts?.filter(
      (part) => !invalidPaths.includes(part)
    );

    const listOfCrumbs = filteredAsPathParts.map((part, index) => {
      const hasAllParts = filteredAsPathParts.length === asPathParts.length;

      // +1 to include the current part, +2 to include the current part and the next part because we still need the full path
      const indexToSlice = hasAllParts ? index + 1 : index + 2;
      const href = `/${asPathParts?.slice(0, indexToSlice).join('/')}`;

      return {
        name: part.charAt(0).toUpperCase() + part.slice(1),
        href,
      };
    });

    return listOfCrumbs;
  }

  const breadCrumbs =
    breadcrumbs.length !== 0 ? breadcrumbs : generateBreadcrumbPath();

  return (
    <MuiBreadcrumbs separator="-" aria-label="breadcrumb">
      {breadCrumbs?.map((crumb, index) => {
        const isLast = index === breadCrumbs.length - 1;
        return <Crumb crumb={crumb} isLast={isLast} key={crumb.href} />;
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
