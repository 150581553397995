import { useQuery } from 'react-query';
import axios from 'axios';

export const APP_RELEASES_QUERY_KEYS = {
  build_id: 'build_id',
};

export function useGetBuildId() {
  return useQuery(
    [APP_RELEASES_QUERY_KEYS.build_id],
    () => {
      return axios.get('/api/build-id');
    },
    {
      enabled: true,
      staleTime: 12 * 60 * 60 * 1000, // 12 hr refetch time
    }
  );
}
