import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { useAuth } from 'src/context';

const useProspectTerritory = () => {
  const { setUser } = useAuth();

  const { mutateAsync, isLoading } = useMutation(
    'v1/user/update',
    (newTerritory: MedScout.Territory) =>
      request({
        url: `/v1/account/current-user/`,
        method: 'PATCH',
        data: {
          last_territory_id: newTerritory?.id || null,
        },
      }),
    {
      onMutate: (newTerritory) => {
        setUser((oldUser) => ({
          ...oldUser,
          last_territory: newTerritory,
        }));
      },
    }
  );

  return { setProspectTerritory: mutateAsync, isLoading };
};

export default useProspectTerritory;
