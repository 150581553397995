import { Modal } from 'react-bootstrap';
import { Typography, useTheme } from '@mui/material';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const Header: React.FC<{ icon?: IconDefinition }> = ({ children, icon }) => {
  const theme = useTheme();

  return (
    <Modal.Header
      closeButton
      style={{
        background: theme.palette.primary.light,
        padding: '0.5rem 1rem',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <Typography variant="dialogTitle">
        {icon && <Icon icon={icon} style={{ marginRight: '0.5rem' }} />}
        {children}
      </Typography>
    </Modal.Header>
  );
};

export default Header;
