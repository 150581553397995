import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface GetIntegrationTokenResponse {
  username: string;
  enabled: boolean;
}
const useGetIntegrationToken = ({
  username,
  enabled,
}: GetIntegrationTokenResponse) => {
  const queryParams = getSearchQueryParamsForAPI({
    username,
  });
  return useQuery(
    [INTEGRATION_KEYS.GET_INTEGRATION_TOKEN, username],
    () => {
      return request({
        url: `/v1/integrations/integrations-token/${queryParams}`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
    }
  );
};

export default useGetIntegrationToken;
