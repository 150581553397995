import { useEffect, useState, useCallback } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useProspectSearch } from 'src/context';
import TerritoryDashboardToolbar from './TerritoryDashboardToolbar';
import TerritoryChartBody from './TerritoryChartBody';
import { grey } from '@mui/material/colors';

interface TerritoryDashboardProps {
  dashboardData: any;
  isLoading: boolean;
}

const TerritoryDashboard = ({
  dashboardData,
  isLoading,
}: TerritoryDashboardProps) => {
  const { prospectSearch } = useProspectSearch();
  const theme = useTheme();
  const [searchValues, setSearchValues] = useState([]);

  const buildToolBarData = useCallback(
    (data) => {
      const toolBarData = [];
      data?.results?.map((result) => {
        result.results.map((r, i) => {
          const doesExist = toolBarData.find((t) => t.id === r.id);
          if (doesExist) return;
          toolBarData.push({
            id: r.id,
            label: r.label,
            hidden: false,
            color: theme.palette.chart[i].main,
          });
        });
      });

      // find prospectSearch in array
      const prospectSearchIndex = toolBarData.findIndex(
        (search) => search.id === prospectSearch?.id
      );

      // if prospectSearch is found, move it to the front of the array
      if (prospectSearchIndex > -1) {
        const prospectSearch = toolBarData.splice(prospectSearchIndex, 1);
        toolBarData.unshift(prospectSearch[0]);
      }

      return toolBarData;
    },
    [theme]
  );

  useEffect(() => {
    if (dashboardData?.results?.length === 0) return null;
    setSearchValues(buildToolBarData(dashboardData));
  }, [dashboardData, buildToolBarData]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        padding: 0,
        margin: 0,
      }}
    >
      {!isLoading && dashboardData?.results ? (
        <>
          <TerritoryDashboardToolbar
            searchValues={searchValues}
            setSearchValues={setSearchValues}
          />
          <TerritoryChartBody
            chartData={dashboardData.results}
            searchValues={searchValues}
          />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '24rem',
            width: '100%',
            color: grey[400],
          }}
        >
          <CircularProgress size={30} color="inherit" />
        </Box>
      )}
    </Box>
  );
};

export default TerritoryDashboard;
