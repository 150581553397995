import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';

const CommandCell = ({ row, reportDataIssue }) => (
  <Icon
    icon={faBullhorn}
    style={{
      marginBottom: 2,
      marginRight: 5,
      color: COLOR_MAP['gray-dark'],
      cursor: 'pointer',
    }}
    aria-label="Report Data Issue"
    onClick={() => reportDataIssue(row)}
  />
);

export default CommandCell;
