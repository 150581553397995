import {
  styled,
  AccordionDetails,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import SideNavCategory from './SideNavCategory';
import SideNavOption from './SideNavOption';

export interface SideNavProps {
  options: {
    label: string;
    id: string;
    icon?: JSX.Element;
    path?: string;
    handleClick?: () => void;
    commands?: any[];
    options?: SideNavProps['options'];
    active?: boolean;
  }[];
}

const SidebarNavWrapper = styled('nav')(({ theme }) => ({
  flexShrink: 0,
  height: '100%',
  maxWidth: '25rem',
  borderRight: `1px solid ${grey[400]}`,
  backgroundColor: theme.palette.highlight.light,
  overflowY: 'auto',
  overflowX: 'hidden',
  boxShadow: theme.shadows[5],
}));

const ListItemMessage = ({ text }: { text: string }) => {
  return (
    <ListItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Typography sx={{ p: 1, fontWeight: 600 }}>{text}</Typography>
    </ListItem>
  );
};

const SideNav: React.FC<SideNavProps> = ({ options }) => {
  const Segments = options.map((route, i) => {
    if (!route) return null;

    return (
      <SideNavCategory
        key={`segment_${i}`}
        label={route.label}
        path={route.path}
        icon={route.icon}
        handleClick={route.handleClick}
        hasOptions={!!route.options}
        commands={route.commands}
        expanded={route.active}
      >
        {route.options && (
          <AccordionDetails sx={{ pl: 0, pr: 0 }}>
            <List dense disablePadding>
              {route.options?.length > 0 ? (
                route.options
                  .sort((a, b) => a?.label?.localeCompare(b?.label))
                  .map((option) => {
                    return (
                      <SideNavOption
                        key={`list_${option.id}`}
                        option={option}
                        handleClick={option.handleClick}
                      />
                    );
                  })
              ) : (
                <ListItemMessage text="No Lists Found" />
              )}
            </List>
          </AccordionDetails>
        )}
      </SideNavCategory>
    );
  });

  return <SidebarNavWrapper>{Segments}</SidebarNavWrapper>;
};

export default SideNav;
