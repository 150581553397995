import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import _ from 'lodash';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';
import { PIPELINE_QUERY_MAP } from 'src/hooks';

function useAddTeamMembers() {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, { repUserIds; company_id }>(
    // company_id used for cache busting
    ({ repUserIds, company_id }) => {
      return request({
        url: `/v1/manager/team/add`,
        method: 'POST',
        data: {
          managed_user_ids: _.isArray(repUserIds) ? repUserIds : [repUserIds],
        },
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries(
          PROSPECT_OVERVIEW_QUERY_KEYS.all_rep_prospects
        );
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('my_lists'),
          body.company_id,
        ]);
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('all_normalized_prospects'),
        ]);
      },
    }
  );
}

export default useAddTeamMembers;
