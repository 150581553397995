import { Typography, MenuItem, Menu, styled } from '@mui/material';
import { Link } from 'src/components';
import React, { useMemo } from 'react';
import { useLayoutControl, useLogging } from 'src/context';
import { useGetMappings } from 'src/hooks';

const StyledText = styled('span')(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  lineHeight: '1.25rem',
  marginRight: '-1px',
  width: '100%',
  color: theme.palette.primary.main,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  padding: '0.5rem 1rem',
}));

interface CRMLinks {
  crm: string;
  crm_link: string;
  crm_object_type: string;
  created_by: {
    firstname: string;
    lastname: string;
  };
}

interface CRMMaxMenuProps {
  provider: MedScout.Opportunity | null;
  integration: string;
  options: CRMLinks[];
  anchorEl: HTMLElement;
  setAnchorEl: (el: HTMLElement) => void;
}

const CRMMaxMenu = ({
  provider,
  integration,
  options = [],
  anchorEl,
  setAnchorEl,
}: CRMMaxMenuProps) => {
  const { setPushProviderToCRM } = useLayoutControl();
  const log = useLogging();

  const { data: allMappings } = useGetMappings({
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  // Gets mappings for specific provider type, removing used mappings
  const mappings = useMemo(() => {
    return allMappings?.results
      ?.filter(
        (mapping) =>
          mapping?.medscout_object_type ===
            provider?.content_type?.toUpperCase() &&
          mapping?.enabled &&
          mapping?.crm?.toLowerCase() === integration?.toLowerCase()
      )
      ?.filter(
        (mapping) =>
          !provider?.crm_links?.some((link) => link.mapping_id === mapping.id)
      );
  }, [allMappings, integration, provider]);

  // Function to handle closing the menu
  function handleMenuClose() {
    setAnchorEl(null);
  }

  // Function to handle Link click event
  function handleLinkClick(e) {
    e.stopPropagation();
    log.event('viewInCRMLinkClicked');
    setAnchorEl(null);
  }

  function renderMenuItem(link, index) {
    const fullName = `${link?.owner?.firstname || ''} ${
      link?.owner?.lastname || ''
    }`?.trim();

    const label =
      link?.crm_status && link?.crm_object_type
        ? `${link?.crm_object_type}: ${link?.crm_status}`
        : link?.crm_object_type
        ? `View ${link?.crm_object_type} in CRM`
        : fullName;

    return (
      <MenuItem key={index} aria-label={`Menu Item For ${label}`} sx={{ p: 0 }}>
        <Link
          href={link?.crm_link}
          onClick={handleLinkClick}
          target="_blank"
          underline="none"
          sx={{
            display: 'flex',
            gap: '0.5rem',
            width: '100%',
            height: '100%',
            p: '0.5rem 1rem',
          }}
        >
          <StyledText>{label}</StyledText>
        </Link>
      </MenuItem>
    );
  }

  if (!options.length) return null;

  return (
    <Menu
      sx={{ mt: '0.25rem' }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {options.map((link, index) => renderMenuItem(link, index))}
      <MenuItem
        onClick={() => {
          handleMenuClose();
          setPushProviderToCRM(provider, integration);
        }}
        sx={{ p: 0 }}
        disabled={!mappings?.length}
      >
        <StyledTypography>
          <StyledText>Push to New</StyledText>
        </StyledTypography>
      </MenuItem>
    </Menu>
  );
};

export default CRMMaxMenu;
