import _ from 'lodash';

export function concatNames(list, delimiter = ',\n') {
  const joinedNames = _.reduce(
    _.orderBy(list, 'name', 'asc'),
    (result, listItem) => {
      return result ? `${result}${delimiter}${listItem.name}` : listItem.name;
    },
    ''
  );

  return joinedNames || 'n/a';
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  } else {
    return phoneNumberString;
  }
}

export function localizeVolume(volume: string | number) {
  if (!volume) return volume;
  return Number(volume)?.toLocaleString();
}

export function strToLineLengthThreshold(str, splitThreshold = 50) {
  if (_.size(str) > splitThreshold) {
    const words = _.split(str, ' ');
    const lines = [];
    _.reduce(
      words,
      (result, word, idx) => {
        const isLast = _.size(words) - 1 === idx;

        result += word;

        if (!isLast && _.size(result) > splitThreshold) {
          lines.push(result);
          result = '';
        } else if (!isLast) {
          result = result + ' ';
        }

        if (isLast) {
          lines.push(result);
          result = '';
        }

        return result;
      },
      ''
    );

    return _.join(lines, '\n');
  } else {
    return str;
  }
}
