import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, useTheme, Skeleton } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

type HtmlToolTipProps = TooltipProps & {
  title: string;
  icon?: JSX.Element;
  description: JSX.Element;
  children: React.ReactElement;
  isLoading?: boolean;
};

const StyledHtmlToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f5f5f9',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '220px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[6],
  },
}));

const ToolTipComponent = forwardRef<any, TooltipProps>((props, ref) => (
  <StyledHtmlToolTip {...props} />
));
ToolTipComponent.displayName = 'ToolTipComponent';

const HtmlToolTip = forwardRef<TooltipProps, HtmlToolTipProps>(
  (
    {
      title,
      icon = null,
      color,
      description,
      children,
      open,
      onClose,
      isLoading = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const body = isLoading ? (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    ) : (
      description
    );

    return (
      <ToolTipComponent
        ref={ref}
        title={
          <>
            <Typography
              color="inherit"
              sx={{
                borderRadius: '4px 0 0 0',
                marginBlockEnd: 1,
                marginTop: '-0.3rem',
                marginLeft: '-0.55rem',
                marginRight: '-0.55rem',
                padding: '0.25rem 0.65rem',
                borderLeft: `6px solid ${
                  color || theme.palette.highlight.main
                }`,
                borderBottom: '1px solid #dadde9',
              }}
            >
              {icon} {title}
            </Typography>
            <Box maxHeight="369px" overflow="auto" padding={1}>
              {body}
            </Box>
          </>
        }
        open={open}
        onClose={onClose}
      >
        {children}
      </ToolTipComponent>
    );
  }
);
HtmlToolTip.displayName = 'HtmlToolTip';

export default HtmlToolTip;
