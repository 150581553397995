import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useGetSettings = ({ crm }) => {
  return useQuery(
    [INTEGRATION_KEYS.GET_SETTINGS, crm],
    async () => {
      return await request({
        url: `/v1/integrations/settings/?crm=${crm}`,
        method: 'GET',
      });
    },
    {
      enabled: !!crm,
    }
  ) as UseQueryResult<MedScout.UseGetSettingsResponse>;
};

export default useGetSettings;
