import { useState } from 'react';
import {
  faPencil,
  faCheckSquare,
  faTimesSquare,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { styled, useTheme, TextField, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton } from 'src/components';

const InlineEditComponent = styled('div')<{ disabled: boolean; iconOnly }>`
  cursor: ${({ disabled, iconOnly }) =>
    disabled && !iconOnly ? 'default' : 'pointer'};
`;
const InlineEditForm = styled('form')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface InlineEditProps {
  onSave: (newValue: string) => Promise<void>;
  onDelete?: () => Promise<void>;
  children: string;
  fullWidth?: boolean;
  iconOnly?: boolean;
  isSaving?: boolean;
  disabled?: boolean;
  secondary?: string | null;
}

/**
 * Text that may be edited inline
 *
 * @param {(newValue: string) => Promise<void>} onSave
 * @param {() => Promise<void>} onDelete
 * @param {string} children
 * @param {boolean} fullWidth
 * @param {boolean} iconOnly
 * @param {boolean} isSaving
 * @param {boolean} disabled
 * @param {string | null} secondary
 * @return {React.FC}
 */
const InlineEdit: React.FC<InlineEditProps> = ({
  children,
  fullWidth = false,
  iconOnly = false,
  disabled = false,
  isSaving = false,
  secondary = null,
  onSave,
  onDelete = null,
}) => {
  const [value, setValue] = useState(children);
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();

  function cancelEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    setValue(children);
    setIsEditing(false);
  }

  async function saveValue(e) {
    e.preventDefault();
    e.stopPropagation();
    await onSave(value);
    setIsEditing(false);
  }

  async function handleDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    await onDelete();
    setIsEditing(false);
  }

  function handleEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(true);
  }

  const displayText = secondary ? `${value} (${secondary})` : value;

  return (
    <InlineEditComponent
      onClick={(e) => {
        if (disabled || iconOnly) return;
        handleEdit(e);
      }}
      disabled={disabled}
      iconOnly={iconOnly}
    >
      {isEditing ? (
        <InlineEditForm onSubmit={saveValue}>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            name="newString"
            disabled={isSaving}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                padding: '0.25rem 0.5rem',
                backgroundColor: 'white',
                borderRadius: '0.25rem',
              },
            }}
            aria-label="Inline Edit Input"
          />
          {onDelete && (
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                icon={faTrash}
                onClick={handleDelete}
                disabled={isSaving}
                size="small"
                color="inherit"
                aria-label="Inline Delete"
              />
            </Box>
          )}
          <IconButton
            icon={faTimesSquare}
            onClick={cancelEdit}
            disabled={isSaving}
            size="large"
            color="inherit"
            aria-label="Cancel"
          />
          {isSaving ? (
            <LoadingButton
              loading
              variant="outlined"
              sx={{
                minWidth: 'unset',
                padding: 0,
                width: '1.5rem',
                minHeight: '1.5rem',
              }}
            />
          ) : (
            <IconButton
              icon={faCheckSquare}
              onClick={saveValue}
              type="submit"
              size="large"
              color="success"
              aria-label="Inline Edit Save"
            />
          )}
        </InlineEditForm>
      ) : (
        <Box
          sx={
            fullWidth
              ? { display: 'flex', alignItems: 'center' }
              : { display: 'inline-block' }
          }
        >
          <span style={{ flexGrow: 1 }}>{displayText}</span>
          {!disabled && (
            <Icon
              onClick={handleEdit}
              icon={faPencil}
              size="sm"
              style={{
                color: theme.palette.grey[300],
                marginLeft: '0.5rem',
              }}
              aria-label="Inline Edit Button"
            />
          )}
        </Box>
      )}
    </InlineEditComponent>
  );
};

export default InlineEdit;
