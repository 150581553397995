import _ from 'lodash';

// startingResult allows stringing calls together to generate one query param string
export function getSearchQueryParamsForAPI(queryParams, startingResult = '') {
  // by sorting the query params by key name, and sorted internal query param fields it maintains a
  // consistent url structure to allow caching based on url
  const sortedQueryParamKeys = _.sortBy(_.keys(queryParams));

  // override existing components with queryParams, if they exist
  let newQueryString = startingResult;
  if (newQueryString) {
    for (const filterKey in queryParams) {
      newQueryString = stripSearchComponent(newQueryString, filterKey);
    }
  }

  const result = _.reduce(
    sortedQueryParamKeys,
    (result, key) => {
      const field = queryParams[key];
      // don't append the key if null / undefined
      if (_.isNil(field)) {
        return result;
      }

      // don't append empty strings / arrays
      if ((_.isArray(field) || _.isString(field)) && _.isEmpty(field)) {
        return result;
      }

      const value = _.isString(field) ? field : JSON.stringify(field);
      const keyValue = `${encodeURIComponent(key)}=${encodeURIComponent(
        value
      )}`;

      result = result ? `${result}&${keyValue}` : `?${keyValue}`;

      return result;
    },
    newQueryString
  );

  return result;
}

export function stripSearchComponent(queryString: string, component: string) {
  const url = new URL(window.location.origin + queryString);
  const filterParams = new URLSearchParams(url.search);
  filterParams.delete(component);

  return '?' + filterParams.toString();
}

export function serializeQueryArray(key: string, values: string[]) {
  return values.map((value) => `${key}=${encodeURIComponent(value)}`).join('&');
}
