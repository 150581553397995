import { useMemo } from 'react';
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  styled,
} from '@mui/material';
import DetailsPopover from 'src/modules/Discovery/components/ResultComponents/DetailsPopover';
import numeral from 'numeral';
// TODO: Kill this with fire
import { OverlayTrigger } from 'react-bootstrap';
import { useProspectSearch } from 'src/context';

const detailsLookup = [
  'claims',
  'patients',
  'year',
  'code',
  'total_payments',
  'avg_reimbursement',
];

const CellLabel = styled(Typography)(({ theme }) => ({
  display: 'inline',
  fontSize: '0.5rem',
  textTransform: 'uppercase',
  marginRight: '0.25rem',
}));

const ProspectDetailsCell = ({ result, loading }) => {
  const { prospectVolumeType } = useProspectSearch();

  const details = useMemo(() => {
    return detailsLookup.map((detail) => {
      if (!result[detail] && detail !== prospectVolumeType) return null;

      switch (detail) {
        case 'claims':
          return (
            prospectVolumeType === 'claims' && (
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  textAlign: 'end',
                }}
              >
                <CellLabel variant="h6">Claims:</CellLabel>
                <span style={{ fontWeight: 500, fontSize: '1.15rem' }}>
                  {result.claims === '<11'
                    ? result.claims
                    : numeral(Number(result.claims)).format('0,0')}
                </span>
              </Box>
            )
          );
        case 'patients':
          return (
            prospectVolumeType === 'patients' && (
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  textAlign: 'end',
                }}
              >
                <CellLabel variant="h6">Patients:</CellLabel>
                <span style={{ fontWeight: 500, fontSize: '1.15rem' }}>
                  {result.patients === '<11'
                    ? result.patients
                    : numeral(Number(result.patients)).format('0,0')}
                </span>
              </Box>
            )
          );
        case 'year':
          return (
            <Box
              sx={{
                whiteSpace: 'nowrap',
                textAlign: 'end',
              }}
            >
              <CellLabel variant="h6">Year:</CellLabel>
              <span style={{ fontWeight: 500, fontSize: '1.25rem' }}>
                {result.year}
              </span>
            </Box>
          );
        case 'code':
          return (
            <Box
              sx={{
                whiteSpace: 'nowrap',
                textAlign: 'end',
              }}
            >
              <CellLabel variant="h6">Code:</CellLabel>
              <span style={{ fontWeight: 500, fontSize: '1.25rem' }}>
                {result.code}
              </span>
            </Box>
          );
        case 'total_payments':
          return (
            <Box
              sx={{
                whiteSpace: 'nowrap',
                textAlign: 'end',
              }}
            >
              <CellLabel variant="h6">Payments:</CellLabel>
              <span style={{ fontWeight: 500, fontSize: '1.25rem' }}>
                {numeral(result.total_payments).format('$0,0.00')}
              </span>
            </Box>
          );
        case 'avg_reimbursement':
          return (
            <Box
              sx={{
                whiteSpace: 'nowrap',
                textAlign: 'end',
              }}
            >
              <CellLabel variant="h6">Avg Reimb:</CellLabel>
              <span style={{ fontWeight: 500, fontSize: '1.25rem' }}>
                {numeral(result.avg_reimbursement).format('$0,0[.]00')}
              </span>
            </Box>
          );
        default:
          return null;
      }
    });
  }, [result, prospectVolumeType]);

  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      rootClose
      overlay={<DetailsPopover row={result} />}
    >
      <Button variant="outlined" size="small" sx={{ fontSize: '0.75rem' }}>
        {loading ? (
          <CircularProgress size="1rem" />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {details.map((detail, i) => (
              <Box key={i}>{detail}</Box>
            ))}
          </Box>
        )}
      </Button>
    </OverlayTrigger>
  );
};

export default ProspectDetailsCell;
