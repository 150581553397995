import React from 'react';
import { Typography, Card, CardContent, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';

interface NotFoundProps {
  elevation?: number;
  color?: string;
  message: string;
  position?: 'flex-start' | 'center' | 'flex-end';
  size?: string;
  textAlign?: string;
}

const NotFound = ({
  elevation = 6,
  color = grey[600],
  message,
  position = 'center',
  size = '1.2rem',
  textAlign = 'center',
}: NotFoundProps) => {
  return (
    <Grid container justifyContent={position}>
      <Grid item xs={12} sm={6}>
        <Card elevation={elevation}>
          <CardContent>
            <Typography
              sx={{
                textAlign: textAlign,
                color: color,
                fontSize: size,
              }}
            >
              {message}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotFound;
