import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { instance } from 'src/api/request';
import { AUTH } from 'src/constants';
import { useQueryClient } from 'react-query';
import { useLocalStorage } from 'src/hooks';
import { useLogging } from 'src/context';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const AuthContext =
  createContext<[MedScout.User, Dispatch<SetStateAction<MedScout.User>>]>(null);

export const AuthProvider = (props) => {
  const userState = useLocalStorage('user', null);
  const [user] = userState as [MedScout.User];
  const ldClient = useLDClient();
  const log = useLogging();

  useEffect(() => {
    if (!ldClient) return;
    if (!user) return;

    ldClient.identify({
      kind: 'multi',
      user: { key: user.username, name: user.username },
      company: { key: user.company.name, name: user.company.name },
    });

    if (!!user && !user.impersonation) log.identify(user);
  }, [user, ldClient, log]);

  return <AuthContext.Provider value={userState} {...props} />;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  const [user, setUser] = context;
  const queryClient = useQueryClient();
  const [accessToken, setAccessToken] = useLocalStorage(AUTH.ACCESS_TOKEN, '');
  const [, setRefreshToken] = useLocalStorage(AUTH.REFRESH_TOKEN, '');
  const log = useLogging();

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const login = useCallback(async ({ access_token, refresh_token }) => {
    if (!access_token) {
      log.exception(new Error('No access token found in query params'));
    }

    try {
      const response = await fetchUserData(access_token);
      const data = response.data.result;

      // set the access token in local-storage
      setUser(data);
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
    } catch (err) {
      log.exception(new Error(`Error logging in`), {
        tags: { error: err.message },
      });
    }

    return '/search';
  }, []);

  const logout = useCallback(
    async ({ user_initiated } = { user_initiated: false }) => {
      const queryProps = user_initiated ? '?user_initiated=true' : '';
      try {
        const response = await instance.request({
          url: `${process.env.NEXT_PUBLIC_API_URL}v1/admin/logout${queryProps}`,
          method: 'GET',
        });

        queryClient?.clear();
        setUser(null);
        setAccessToken('');
        setRefreshToken('');
        localStorage.clear();

        return (
          response?.data?.logoutUrl ||
          `${process.env.NEXT_PUBLIC_API_URL}v1/admin/login`
        );
      } catch (err) {
        log.exception(new Error(`Error logging out`), {
          tags: { error: err.message },
        });
      }

      return null;
    },
    [queryClient]
  );

  const refreshLocalUser = useCallback(async () => {
    if (!accessToken) return;

    try {
      const response = await fetchUserData(accessToken);
      const data = response?.data?.result;

      data && setUser(data);
    } catch (err) {
      log.exception(new Error(`Error refreshing user`), {
        tags: { error: err.message, token: accessToken }, // TODO: remove token from this for security
      });
    }
  }, [accessToken, setUser]);

  return {
    user,
    setUser,
    refreshLocalUser,
    login,
    logout,
  };
};

/**
 * Fetches the user data from the API
 *
 * @param {string} token
 */
async function fetchUserData(
  token
): Promise<{ data: { result: MedScout.User } }> {
  return instance.request({
    url: `${process.env.NEXT_PUBLIC_API_URL}v1/auth/me/`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
