import { Box } from '@mui/material';

const AddressCell = ({ row }) => {
  return (
    <Box sx={{ '&>p': { fontSize: '0.65rem' } }}>
      {row.address_line_1 && <p>{row.address_line_1}</p>}
      {row.address_line_2 && <p>{row.address_line_2}</p>}
      <p style={{ fontWeight: '800' }}>
        {row.city}, {row.state}, {row.zip_code}
      </p>
    </Box>
  );
};

export default AddressCell;
