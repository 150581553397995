'use client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { green } from '@mui/material/colors';

import { StepIndicator } from 'src/components';

interface MultiStepDialogProps {
  open: boolean;
  title: string;
  children: any;
  onSubmit: any;
  onClose: any;
  steps: string[];
  activeStep: number;
  setActiveStep: any;
  onReset?: any;
  loading?: boolean;
  disabled?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
}

const MultiStepDialog = ({
  loading = false,
  disabled = false,
  fullWidth = false,
  maxWidth = 'md',
  ...props
}: MultiStepDialogProps) => {
  function isLastStep() {
    if (props.steps.length <= 1) return true;
    const totalSteps = props.steps.length - 1;
    return props.activeStep === totalSteps;
  }

  function handleNext() {
    props.setActiveStep((prev) => {
      if (!isLastStep()) {
        return prev + 1;
      }
    });
  }

  function handleBack() {
    props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // reset react-form if first step
    if (props.activeStep === 1 && props.onReset) props.onReset();
  }

  function handleStep(step: number) {
    props.setActiveStep(step);
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle>{props.title}</DialogTitle>
      <Box sx={{ paddingInline: '1rem' }}>
        <StepIndicator
          steps={props.steps}
          activeStep={props.activeStep}
          completed={isLastStep()}
          handleStep={handleStep}
        />
      </Box>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 1rem',
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            disabled={props.activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="outlined" onClick={props.onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            {isLastStep() ? (
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={props.onSubmit}
                sx={{
                  background: green[800],
                  '&:hover': { background: green[900] },
                }}
                disabled={disabled}
              >
                Save
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={disabled}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MultiStepDialog;
