import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useGetAvailableIntegrations = () => {
  return useQuery(
    [INTEGRATION_KEYS.GET_AVAILABLE_INTEGRATIONS],
    () => {
      return request({
        url: `/v1/integrations/available-integrations/`,
        method: 'GET',
      });
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

export default useGetAvailableIntegrations;
