import { useEffect, useState } from 'react';

export function useLastTargetElement() {
  const [lastFocusedElement, setLastFocusedElement] = useState(null);

  useEffect(() => {
    function handleOutsideEvent(event) {
      setLastFocusedElement(event.target);
    }

    if (document) {
      document.addEventListener('mousedown', handleOutsideEvent);
      document.addEventListener('touchstart', handleOutsideEvent);

      return () => {
        document.removeEventListener('mousedown', handleOutsideEvent);
        document.removeEventListener('touchstart', handleOutsideEvent);
      };
    }
  }, []);

  return lastFocusedElement;
}
