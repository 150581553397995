import { useState, useEffect, ChangeEvent } from 'react';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { FaTimes } from 'react-icons/fa';
import StyledTextField from './StyledTextField';

const AutocompleteField = ({
  disabled,
  searchTypeInfo,
  isFetching,
  minMaxSelectEntity,
  setResultsVisible,
  handleSearch,
  clearSearch,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    minMaxSelectEntity ? setValue(minMaxSelectEntity.title) : setValue('');
  }, [minMaxSelectEntity]);

  function handleClearSearch() {
    setValue('');
    clearSearch();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
    handleSearch(e);
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative' }}>
        <StyledTextField
          fullWidth
          variant="outlined"
          size="small"
          value={value}
          placeholder={
            disabled
              ? 'Unavailable with current filters'
              : searchTypeInfo.placeholder
          }
          onFocus={() => {
            setResultsVisible(true);
          }}
          onChange={handleChange}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!isFetching && (value || minMaxSelectEntity) ? (
                  <FaTimes
                    style={{
                      cursor: 'pointer',
                      color: minMaxSelectEntity ? blue[900] : grey[500],
                    }}
                    onClick={handleClearSearch}
                  />
                ) : null}
                {isFetching && <CircularProgress size={20} color="inherit" />}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default AutocompleteField;
