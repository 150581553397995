import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

function useGetTerritories({ pageSize = 250 }: { pageSize?: number }) {
  const queryParams = getSearchQueryParamsForAPI({
    pageSize,
  });
  return useQuery(
    [QUERY_KEYS.territories],
    () => {
      return request({
        url: `v1/accounts/territories/${queryParams}`,
        data: {},
        method: 'GET',
        timeout: 20_000,
      });
    },
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
}

export default useGetTerritories;
