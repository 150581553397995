import { useState, useEffect } from 'react';
import { useUserImpersonation } from 'src/hooks';
import { useAuth } from 'src/context';
import { Box, Typography, styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faUserNinja, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useCountdown } from 'src/utils/hooks/useCountdown';
import { IconButton } from 'src/components';
import { red } from '@mui/material/colors';
import numeral from 'numeral';

const BarComponent = styled(Box)({
  backgroundColor: red[900],
  position: 'absolute',
  width: '9rem',
  zIndex: 1500,
  padding: '0 0.5rem',
  borderRadius: '0 0 12px 12px',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: '-6rem',
  display: 'flex',
  transition: 'top 0.25s',
  '&>.MuiBox-root': {
    display: 'flex',
    padding: '0.25rem 0',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    boxShadow: `12px 0 0 0 ${red[900]}`,
    left: '-25px',
    top: '0',
    height: '16px',
    width: '25px',
    borderTopRightRadius: '12px',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    boxShadow: `-12px 0 0 0 ${red[900]}`,
    right: '-25px',
    top: '0',
    height: '16px',
    width: '25px',
    borderTopLeftRadius: '12px',
  },
});

const ImpersonationBar = () => {
  const { stopImpersonating } = useUserImpersonation();
  const { user } = useAuth();
  const expiration = user?.impersonation?.expiration;
  const [expiresIn, setExpiresIn] = useState('02:00:00');
  const [days, hours, minutes, seconds] = useCountdown(expiration);

  useEffect(() => {
    setExpiresIn(
      [
        numeral(hours).format('00'),
        numeral(minutes).format('00'),
        numeral(seconds).format('00'),
      ].join(':')
    );
  }, [days, hours, minutes, seconds]);

  useEffect(() => {
    if (expiration && new Date(expiration).getTime() < Date.now()) {
      stopImpersonating();
    }
  }, [expiration, stopImpersonating]);

  return (
    <BarComponent
      top={!!user?.impersonation ? '0' : '-3rem'}
      sx={{
        left: { xs: '88%', md: '77%', lg: '65%' },
      }}
    >
      <Box>
        <Typography
          color="white"
          fontSize="0.85rem"
          fontWeight="300"
          sx={{ px: '0.25rem' }}
        >
          <Icon
            icon={faUserNinja}
            style={{
              margin: '0 0.35rem 0 0',
              color: '#00000077',
            }}
            size="lg"
          />
          <span>{expiresIn}</span>
        </Typography>
      </Box>
      <Box sx={{ borderLeft: '1px solid #00000044' }}>
        <IconButton
          icon={faTimes}
          onClick={() => stopImpersonating()}
          sx={{ color: 'white', mx: '0.5rem', height: '100%' }}
        />
      </Box>
    </BarComponent>
  );
};

export default ImpersonationBar;
