import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

interface GetProviderListItem {
  listId: string | number;
  itemId: string | number;
}

/**
 * @description Returns the details for the given provider list item
 * @param listId
 * @param itemId
 */
const useGetProviderListItem = ({ listId, itemId }: GetProviderListItem) => {
  return useQuery([QUERY_KEYS.providerListItem, listId, itemId], async () => {
    return await request({
      url: `/v1/provider-lists/lists/${listId}/items/${itemId}/`,
      method: 'GET',
    });
  });
};

export default useGetProviderListItem;
