import { styled, keyframes } from '@mui/material';

const fadeIn = keyframes`
  from {
    transform: translateY(3rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(3rem);
    opacity: 0;
  }
`;

const ContextCommands = styled('div')<{ show: boolean }>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  animation: ${({ show }) => (show ? fadeIn : fadeOut)} 150ms ease-in-out;
  transition: visibility 150ms ease-in-out;
`;

export default ContextCommands;
