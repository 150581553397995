import { COLOR_MAP } from './constants/scss-variables.constants';

export function textColorBasedOnBg({
  bgHex,
  lightHex = COLOR_MAP.white,
  darkHex = COLOR_MAP.black,
}) {
  if (!bgHex) {
    return darkHex;
  }

  let color = bgHex.charAt(0) === '#' ? bgHex.substring(1, 7) : bgHex;
  if (color.length === 3) {
    // if it is shorthand color create 6 char code
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  return parseInt(color, 16) > 0xffffff / 2 ? darkHex : lightHex;
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 16)) & 0xff;
    color += `00${value.toString(12)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
