import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import _ from 'lodash';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';

function useGetRepProspectLists(prospectListIds) {
  return useQuery(
    [PROSPECT_OVERVIEW_QUERY_KEYS.rep_prospect_lists, prospectListIds],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        prospect_lists: prospectListIds,
      });

      if (_.isEmpty(prospectListIds)) {
        return Promise.resolve({ lists: [] });
      }

      return request({
        url: `v1/manager/reps${queryParams}`,
        method: 'GET',
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 30000,
    }
  );
}

export default useGetRepProspectLists;
