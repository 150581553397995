export const MAX_ZOOM = 17;
export const MAX_WINDOW_WIDTH = 2048;
export const DRAW_MODES = {
  MOVE: 'MOVE',
  STATE: 'STATE',
  COUNTY: 'COUNTY',
  ZIP: 'ZIP',
  DRAW_POLY: 'DRAW_POLY',
};

export const DC_STATE_ID = 11;
export const VA_STATE_ID = 51;

export const STATE_CODES = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  // 'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];
