import Tag from './Tag';
import { Box, AvatarGroupProps, ChipProps, AvatarGroup } from '@mui/material';

const Tags: React.FC<
  AvatarGroupProps & {
    tags: {
      id: number;
      label: string;
      FormattedText: () => JSX.Element;
      fullText?: string;
      bgColor?: string;
      href?: string;
    }[];
    size?: ChipProps['size'];
    tagType?: 'list' | 'integration' | 'tag';
  }
> = ({ tags, sx, max, size = 'small', tagType = 'list' }) => {
  const tagsWithNoEmptyValues = tags?.filter((tag) => tag);

  const tagStyle = {
    height: '1.75rem',
  };
  switch (tagType) {
    case 'list':
      Object.assign(tagStyle, {
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 0,
      });
      break;
    case 'integration':
      Object.assign(tagStyle, {});
      break;
    case 'tag':
      Object.assign(tagStyle, {
        flexWrap: 'wrap',
      });
      break;
  }

  if (!tagsWithNoEmptyValues) return null;

  const tagSet = tagsWithNoEmptyValues?.map((tag, i) => {
    const dynamicFontSize = tag.label?.length > 20 ? '0.65rem' : '0.75rem';

    return (
      <Tag
        tag={tag}
        size={size}
        key={tag.id || i}
        sx={{
          ...tagStyle,
          fontSize: dynamicFontSize,
        }}
      />
    );
  });

  return tagType === 'tag' ? (
    <Box display="flex" sx={sx}>
      {tagSet}
    </Box>
  ) : (
    <AvatarGroup max={max} spacing="small" sx={sx}>
      {tagSet}
    </AvatarGroup>
  );
};

export default Tags;
