import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeleteProviderListItem } from 'src/hooks';
import { useLogging, useProspectSelection, useNotification } from 'src/context';
import { useDeleteProspectFromList } from 'src/api/prospects/prospect-lists';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';
import { useFlags } from 'launchdarkly-react-client-sdk';
/**
 * Dialog to confirm the removal of a prospect from a pipeline
 *
 * @param {*} { show, onHide }
 * @return {*}
 */
const RemoveProvidersDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const { useNewListsModule } = useFlags();
  const { query } = useRouter() as any;
  const { setNotification } = useNotification();
  const { isLoading: isProviderDeleting, mutateAsync: removeProspect } =
    useDeleteProviderListItem();
  const {
    isLoading: isProspectDeleting,
    mutateAsync: onDeleteProspectFromList,
  } = useDeleteProspectFromList();

  const log = useLogging();
  const {
    state: [selectedProspects, setProspectSelection],
  } = useProspectSelection();

  const handleDeleteProspect = async (e) => {
    try {
      await Promise.all(
        selectedProspects.map((prospect) => {
          log.event('deleteProspectFromList', {
            listName: prospect.ui_list?.name,
            id: prospect.id,
          });
          if (useNewListsModule) {
            return removeProspect({
              itemId: prospect.id,
              listId: query?.id,
            });
          } else {
            return onDeleteProspectFromList({
              prospectId: prospect.id,
            });
          }
        })
      );
      setNotification({
        title: useNewListsModule ? 'Provider removed' : 'Prospect removed',
        message: useNewListsModule
          ? 'The provider(s) has been removed from the list.'
          : 'The prospect(s) has been removed from the list.',
        type: 'success',
      });
      setProspectSelection([]);
      onClose(e, 'backdropClick');
    } catch (err) {
      setNotification({
        title: 'Error',
        message:
          'An error occurred while removing the provider(s) from the list.',
        type: 'error',
      });
      log.exception(`Error deleting prospect from list: ${err}`, {
        tags: {
          source: 'RemoveProvidersDialog',
          context: selectedProspects,
        },
      });
    }
  };

  const isDeleting = useNewListsModule
    ? isProviderDeleting
    : isProspectDeleting;

  const source = useNewListsModule ? 'Provider' : 'Prospect';
  const type = useNewListsModule ? 'List' : 'Pipeline';
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Delete {pluralize(source, selectedProspects.length)} from {type}
      </DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to remove&nbsp;
          {selectedProspects.length === 1 ? (
            <strong>{selectedProspects[0]?.name}</strong>
          ) : (
            <strong>
              {selectedProspects.length}{' '}
              {useNewListsModule ? 'providers' : 'prospects'}
            </strong>
          )}
          ?
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isDeleting}
          onClick={(e) => {
            onClose(e, 'backdropClick');
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isDeleting}
          onClick={(e) => handleDeleteProspect(e)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveProvidersDialog;
