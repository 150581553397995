// disabled no unused due to needing to pluck specific props to not pass to popover for overlay trigger support
import { forwardRef } from 'react';
import _ from 'lodash';
import { Popover } from 'react-bootstrap';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { useStore } from 'src/store/store';

const tooltipLineStyle = { paddingTop: 2, paddingBottom: 2 };
const sourceInfoViewMap = {
  center: 'centerProfile',
  hcp: 'physicianProfile',
  inbound_patient_patterns: 'inboundPatientReferrals',
  outbound_patient_patterns: 'outboundPatientReferrals',
  affiliations: 'hospitalAffiliations',
  referrals: 'historicPatientReferrals',
};

const DataInfoPopover = forwardRef<
  HTMLDivElement,
  {
    view: string;
    tooltipData?;
    popper?;
    children?;
    show?: boolean;
  }
>(({ view, tooltipData, popper, children, show, ...props }, ref) => {
  const dataSourcesInfo = useStore((store: any) => store.dataSourcesInfo);
  const dataSourceTooltipInfo =
    dataSourcesInfo[sourceInfoViewMap[view] || view];

  let dataArray = [];
  if (dataSourceTooltipInfo) {
    dataArray = _.isArray(dataSourceTooltipInfo)
      ? dataSourceTooltipInfo
      : [dataSourceTooltipInfo];
  } else if (tooltipData) {
    dataArray = _.isArray(tooltipData) ? tooltipData : [tooltipData];
  }

  return (
    <Popover ref={ref} body id="popover-contained" {...props}>
      <div style={{ maxHeight: 250, overflowY: 'auto' }}>
        {_.map(dataArray, (tooltipDataItem, idx) => {
          tooltipDataItem =
            tooltipDataItem.key && _.get(dataSourcesInfo, tooltipDataItem.key)
              ? {
                  ...tooltipDataItem,
                  ...dataSourcesInfo[tooltipDataItem.key],
                }
              : tooltipDataItem;

          const isFirst = idx === 0;

          const fullCoverageText = _.isArray(tooltipDataItem.coverage?.full)
            ? _.join(tooltipDataItem.coverage?.full, ', ')
            : tooltipDataItem.coverage?.full;
          const partialCoverageText = _.isArray(
            tooltipDataItem.coverage?.partial
          )
            ? _.join(tooltipDataItem.coverage?.partial, ', ')
            : tooltipDataItem.coverage?.partial;

          return (
            <div
              style={{ fontSize: 12 }}
              key={`${tooltipDataItem.header}_${idx}`}
            >
              {!isFirst && <hr style={{ margin: 2 }} />}
              {tooltipDataItem.header && (
                <div style={{ color: COLOR_MAP['blue-dark'] }}>
                  <strong>{tooltipDataItem.header}</strong>
                </div>
              )}
              {tooltipDataItem.subHeader && (
                <div style={tooltipLineStyle}>{tooltipDataItem.subHeader}</div>
              )}
              {fullCoverageText && (
                <div style={tooltipLineStyle}>
                  <strong>Full Coverage</strong>
                  <br />
                  {fullCoverageText}
                </div>
              )}
              {partialCoverageText && (
                <div style={tooltipLineStyle}>
                  <strong>Partial Coverage (Hospital Setting)</strong>
                  <br />
                  {partialCoverageText}
                </div>
              )}
              {tooltipDataItem.coverage?.enrichedData && (
                <div style={tooltipLineStyle}>
                  <strong>MedScout Enriched</strong>
                  <br />
                  <span>
                    {_.join(
                      _.map(
                        tooltipDataItem.coverage?.enrichedData,
                        ({ label }) => {
                          return label;
                        }
                      ),
                      ', '
                    )}
                  </span>
                </div>
              )}
              {tooltipDataItem.updateFrequency && (
                <div style={tooltipLineStyle}>
                  <strong>Updated</strong>
                  <br />
                  {tooltipDataItem.updateFrequency}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Popover>
  );
});
DataInfoPopover.displayName = 'DataInfoPopover';

export default DataInfoPopover;
