import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from './constants';

/**
 * Custom hook to fetch search provider details.
 *
 * @param {Object} options - The options for fetching search provider details.
 * @param {string} options.provider_id - The ID of the provider.
 * @param {any} options.details_params - Additional parameters for fetching details.
 * @param {boolean} [options.enabled] - Flag to enable or disable the query.
 *
 * @returns {QueryResult} - The result of the query.
 */
export default function useGetSearchProviderDetails({
  provider_id,
  details_params,
  enabled,
}: {
  provider_id: string;
  details_params: any;
  enabled?: boolean;
}) {
  const queryParams = getSearchQueryParamsForAPI({ details_params });
  return useQuery(
    [
      QUERY_KEYS.search_provider_details,
      {
        provider_id,
        queryParams,
      },
    ],
    () => {
      return request({
        method: 'GET',
        data: {},
        url: `/v1/query/details/${provider_id}${queryParams}`,
      });
    },
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      staleTime: 30000,
      retry: false,
    }
  );
}
