import { useQuery } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

const useGetUserNotificationSettings = () => {
  return useQuery(
    [QUERY_KEYS.user_notification_settings],
    () => {
      return request({
        url: `/v1/settings/notifications`,
        method: 'GET',
      });
    },
    {
      staleTime: 30000,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetUserNotificationSettings;
