import dynamic from 'next/dynamic';
import _ from 'lodash';
import { Box, Container, Typography, Grid, styled } from '@mui/material';
import { Blade, BladeHeaderWidget } from 'src/components';
import { useListsLayoutControl } from 'src/modules/Lists/context';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { green, grey, red } from '@mui/material/colors';
import { localizeVolume } from 'src/utils/stringFormat.helpers';
import BladeSubheader from './BladeSubheader';
const ResponsiveLine = dynamic(
  () => import('@nivo/line').then((m) => m.ResponsiveLine),
  { ssr: false }
);

interface SmartListsBladeProps {
  open: boolean;
  onClose: () => void;
  // width?: { [key: string]: number | string };
  width?: number | string;
}
interface RootProp {
  color?: string;
  elevation?: number;
  widthDepth?: number;
}

const lineTheme = {
  axis: {
    domain: {
      line: {
        stroke: grey[700],
        strokeWidth: 1,
      },
    },

    ticks: {
      line: {
        stroke: grey[700],
        strokeWidth: 1,
      },
    },
  },
};

const TooltipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'elevation',
})<RootProp>(({ theme, elevation }) => ({
  background: 'white',
  padding: '0.5rem',
  border: `1px solid ${grey[200]}`,
  borderRadius: '0.25rem',
  boxShadow: theme.shadows[elevation || 1],
}));

const TooltipHeader = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  marginBottom: '0.5rem',
  textAlign: 'center',
  borderBottom: `1px solid ${grey[200]}`,
}));

const TooltipBody = styled(Box)({
  marginBottom: '0.5rem',
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
});

const TooltipColorKey = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<RootProp>(({ color }) => ({
  width: '0.75rem',
  height: '0.75rem',
  background: `${color || grey[700]}`,
}));

const SmartListsBlade = ({ open, onClose, width }: SmartListsBladeProps) => {
  const { smartListsDrawer } = useListsLayoutControl();
  if (!smartListsDrawer) return null;
  const totalVolume = smartListsDrawer?.volume?.['volume'] || 0;

  // list value to percentage
  // remove the percentage sign
  const percentage = smartListsDrawer?.list_value.replace(/%/g, '');

  // check if the percentage is negative
  const isNegative = percentage < 0;

  const years_data = smartListsDrawer?.list_value_extra?.years_data;
  const data = Object.keys(years_data).map((key) => {
    const date = new Date(key).getFullYear();
    return {
      x: date,
      y: years_data[key],
    };
  });

  return (
    <Blade
      open={open}
      onClose={onClose}
      title={'Smart Lists'}
      width={width}
      variant={'temporary'}
      hideBackdrop={true}
      headerSlots={{
        slot1: <BladeHeaderWidget label={'Total Volume'} value={totalVolume} />,
      }}
      subHeader={<BladeSubheader item={smartListsDrawer} />}
    >
      <Container sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item md={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
                Volume Shift
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  icon={isNegative ? faArrowDown : faArrowUp}
                  color={isNegative ? red[600] : green[600]}
                  style={{ marginRight: '5px', fontSize: '1.3rem' }}
                />
                <Typography sx={{ fontSize: '1.3rem', fontWeight: 700 }}>
                  {_.round(percentage, 2)}%
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={9}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '250px',
              }}
            >
              <ResponsiveLine
                data={[{ id: 'smart-lists', data }]}
                theme={lineTheme}
                lineWidth={5}
                pointBorderWidth={5}
                pointSize={15}
                colors={{ scheme: 'category10' }}
                pointBorderColor={{ from: 'serieColor' }}
                pointColor={'white'}
                enableGridX={false}
                enableGridY={false}
                margin={{ top: 10, right: 45, bottom: 50, left: 50 }}
                xScale={{ type: 'point' }}
                yScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 5,
                }}
                isInteractive={true}
                enableSlices="x"
                sliceTooltip={({ slice }) => {
                  return (
                    <TooltipContainer elevation={2}>
                      <TooltipHeader>
                        {slice.points[0].data.xFormatted}
                      </TooltipHeader>
                      {slice.points.map((point) => {
                        return (
                          <TooltipBody key={point.id}>
                            <TooltipColorKey color={point.serieColor} />
                            <Typography sx={{ fontSize: '0.8rem' }}>
                              {localizeVolume(point.data.yFormatted)}
                            </Typography>
                          </TooltipBody>
                        );
                      })}
                    </TooltipContainer>
                  );
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          component="div"
          sx={{
            pt: 1,
            mt: 2,
            borderTop: `1px solid ${grey[200]}`,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            What is MedScout Volume Trend?
          </Typography>
          <Typography sx={{ fontSize: '0.8rem', fontWeight: 500, mb: 1 }}>
            MedScout Volume Trend is a proprietary calculation to help you
            identify the most exciting targets in your territory.
          </Typography>
          <Typography sx={{ fontSize: '0.8rem', fontWeight: 500, mb: 1 }}>
            First, we normalize the raw claims data for every provider. Next, we
            rank the projected rate of increase or decrese over time and present
            the biggest movers to you!
          </Typography>
        </Box>
      </Container>
    </Blade>
  );
};

export default SmartListsBlade;
