import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useDeleteSetting = () => {
  return useMutation(
    [INTEGRATION_KEYS.DELETE_SETTING],
    async (data: MedScout.Setting) => {
      return await request({
        url: `/v1/integrations/settings/${data?.id}`,
        method: 'DELETE',
      });
    }
  );
};

export default useDeleteSetting;
