import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useAddMappingOption = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ data, mappingId }: { data; mappingId: string }) => {
      return await request({
        url: `/v1/integrations/mappings/${mappingId}/`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              INTEGRATION_KEYS.GET_AUTOPOPULATE_OPTIONS
            );
          },
        });

        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              INTEGRATION_KEYS.GET_USER_INPUT_OPTIONS
            );
          },
        });
      },
    }
  );
};

export default useAddMappingOption;
