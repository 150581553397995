import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useLinkToCRM = () => {
  return useMutation(
    [INTEGRATION_KEYS.PUSH_TO_CRM],
    (data: MedScout.CRMObject) => {
      return request({
        url: '/v1/integrations/link-to-crm-object/',
        method: 'POST',
        data,
      });
    }
  );
};

export default useLinkToCRM;
