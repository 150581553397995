import _ from 'lodash';
import { QUERY_KEYS } from './constants';
import { request } from 'src/api/request';
import { useMutation, useQueryClient } from 'react-query';

function useDeleteTerritory() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ territoryId }: { territoryId: string }) => {
      return request({
        url: `v1/accounts/territories/${territoryId}`,
        data: {},
        method: 'DELETE',
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries([QUERY_KEYS.territories]);
      },
    }
  );
}

export default useDeleteTerritory;
