import { STATUS_COLOR_MAP } from 'src/utils/constants/status-colors.constants';
import { textColorBasedOnBg } from 'src/utils/color.helpers';

const StatusCell = ({ status }) => {
  if (!status) return null;

  return (
    <div
      style={{
        backgroundColor: status.color || STATUS_COLOR_MAP[status.value],
        borderRadius: 3,
        padding: 6,
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 0.5,
        color: textColorBasedOnBg({
          bgHex: status.color || STATUS_COLOR_MAP[status.status],
        }),
        minWidth: 85,
        textAlign: 'center',
      }}
    >
      {status.name}
    </div>
  );
};

export default StatusCell;
