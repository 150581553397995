function DashboardEmptyIcon({ width = 240, height = 120 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="209.5"
        y="11.5"
        width="177"
        height="177"
        rx="4.5"
        stroke="#021B9C"
        strokeWidth="3"
      />
      <circle cx="297.5" cy="99.5" r="53.5" fill="#021B9C" />
      <line x1="298" y1="75" x2="298" y2="124" stroke="white" strokeWidth="4" />
      <path
        d="M275 99H320"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="square"
      />
      <rect
        x="13.5"
        y="11.5"
        width="177"
        height="177"
        rx="4.5"
        stroke="#021B9C"
        strokeWidth="3"
      />
      <rect x="129" y="19" width="54" height="17" rx="3" fill="#021B9C" />
      <line
        x1="136"
        y1="27.5"
        x2="176"
        y2="27.5"
        stroke="white"
        strokeOpacity="0.51"
        strokeWidth="5"
      />
      <line
        x1="22"
        y1="48.5"
        x2="82"
        y2="48.5"
        stroke="#021B9C"
        strokeWidth="5"
      />
      <rect x="22" y="59" width="71" height="22" fill="#021B9C" />
      <rect x="111" y="59" width="71" height="22" fill="#021B9C" />
      <line
        x1="22"
        y1="90.5"
        x2="82"
        y2="90.5"
        stroke="#021B9C"
        strokeWidth="5"
      />
      <rect x="22" y="101" width="71" height="22" fill="#021B9C" />
      <rect x="111" y="101" width="71" height="22" fill="#021B9C" />
      <line x1="61.5" y1="144" x2="61.5" y2="180" stroke="#021B9C" />
      <line x1="101.5" y1="144" x2="101.5" y2="180" stroke="#021B9C" />
      <line x1="141.5" y1="144" x2="141.5" y2="180" stroke="#021B9C" />
      <line x1="23" y1="158.5" x2="61" y2="158.5" stroke="#021B9C" />
      <line x1="62" y1="158.5" x2="101" y2="158.5" stroke="#021B9C" />
      <line x1="102" y1="158.5" x2="141" y2="158.5" stroke="#021B9C" />
      <line x1="142" y1="158.5" x2="181" y2="158.5" stroke="#021B9C" />
      <line
        x1="32"
        y1="169.5"
        x2="52"
        y2="169.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <line
        x1="71"
        y1="169.5"
        x2="91"
        y2="169.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <line
        x1="112"
        y1="169.5"
        x2="132"
        y2="169.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <line
        x1="151"
        y1="169.5"
        x2="171"
        y2="169.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <line
        x1="65"
        y1="150.5"
        x2="97"
        y2="150.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <line
        x1="26"
        y1="150.5"
        x2="58"
        y2="150.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <line
        x1="105"
        y1="150.5"
        x2="137"
        y2="150.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <line
        x1="145"
        y1="150.5"
        x2="177"
        y2="150.5"
        stroke="#021B9C"
        strokeOpacity="0.33"
        strokeWidth="5"
      />
      <rect
        x="22.5"
        y="143.5"
        width="159"
        height="37"
        rx="2.5"
        stroke="#021B9C"
      />
      <line
        x1="22"
        y1="132.5"
        x2="82"
        y2="132.5"
        stroke="#021B9C"
        strokeWidth="5"
      />
    </svg>
  );
}

export default DashboardEmptyIcon;
