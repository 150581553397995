import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

export default function useUnsubscribe() {
  return useMutation<any, unknown, { email: string; id: string }>(
    ({ email, id }) => {
      const queryParams = getSearchQueryParamsForAPI({ email, id });

      return request({
        url: `v1/settings/notifications/unsubscribe${queryParams}`,
        data: {},
        method: 'GET',
      });
    }
  );
}
