import { request } from 'src/api/request';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from './constants';

/**
 * @description Deletes a provider list
 * @param listId
 */
const useDeleteProviderList = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (listId: string | number) => {
      return request({
        url: `/v1/provider-lists/lists/${listId}/`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.providerLists);
      },
    }
  );
};

export default useDeleteProviderList;
