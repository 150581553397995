import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { useAuth } from 'src/context';

export default function useAcceptTerms() {
  const { setUser } = useAuth();

  return useMutation<any, unknown, { termsVersion }>(
    '/v1/terms/accept',
    ({ termsVersion }) => {
      return request({
        url: `/v1/terms/accept`,
        method: 'PUT',
        data: {
          terms_version: termsVersion,
        },
      });
    },
    {
      onSuccess: (data, body) => {
        setUser((oldUser) => ({
          ...oldUser,
          company: {
            ...oldUser?.company,
            terms_accepted_rev: body.termsVersion,
            terms_accepted_at: new Date().toISOString(),
          },
        }));
      },
    }
  );
}
