import { useLogging } from 'src/context';
import { FaExternalLinkAlt } from 'react-icons/fa';

const CrmLinkCell = ({ row }) => {
  const log = useLogging();

  if (!row.crm_link) return null;

  return (
    <a
      style={{ fontWeight: 500 }}
      href={row.crm_link}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        log.event('viewInCRMLinkClicked');
      }}
    >
      View in CRM
      <FaExternalLinkAlt
        style={{ marginBottom: 4, marginLeft: 3, fontSize: 10 }}
      />
    </a>
  );
};

export default CrmLinkCell;
