import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';

interface EditTerritoryProps {
  name: string;
  user: number;
  is_canonical: boolean;
  geometry?: string[];
  points?: number[];
  territoryId: number;
  owner: MedScout.User;
}

function useEditTerritory() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ...data }: EditTerritoryProps) => {
      return request({
        url: `v1/accounts/territories/${data.territoryId}/`,
        method: 'PATCH',
        data: data,
        timeout: 60_000,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.territory]);
        queryClient.invalidateQueries([QUERY_KEYS.territories]);

        // queryClient.invalidateQueries([
        //   PROSPECT_OVERVIEW_QUERY_KEYS.all_rep_prospects,
        // ]);
      },
    }
  );
}

export default useEditTerritory;
