import { GridExportMenuItemProps } from '@mui/x-data-grid-premium';
import { MenuItem } from '@mui/joy';

interface ExportMenuItemProps
  extends GridExportMenuItemProps<Record<string, never>> {
  type: string;
  onExport: (type: string) => void;
}

const ExportMenuItem = (props: ExportMenuItemProps) => {
  // May need to send type in the future.
  const titleMap = {
    csv: 'Download as CSV',
    xlsx: 'Download as Excel',
  };
  return (
    <MenuItem
      sx={{
        fontSize: '1rem',
        fontWeight: 500,
        padding: '0.5rem 1rem',
        textAlign: 'center',
      }}
      onClick={async () => {
        // close the menu
        props.hideMenu?.();
        await props.onExport(props.type);
      }}
    >
      <span>{titleMap[props.type]}</span>
    </MenuItem>
  );
};

export default ExportMenuItem;
