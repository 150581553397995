import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

/**
 * Custom hook for adding a saved search.
 *
 * @returns A mutation function for adding a saved search.
 */
export default function useAddSavedSearch() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      name,
      orgSearch,
      contentType,
      groupedFiltersByContext,
      company_id,
    }: {
      name: string;
      orgSearch;
      contentType;
      groupedFiltersByContext;
      company_id: string;
    }) => {
      return request({
        url: `/v1/saved-searches`,
        method: 'POST',
        data: {
          ...groupedFiltersByContext,
          name,
          org_search: orgSearch,
          content_type: contentType,
          company_id,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.saved_searches]);
      },
    }
  );
}
