import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { useAuth } from 'src/context';

const useUserImpersonation = () => {
  const { setUser, refreshLocalUser } = useAuth();
  const queryClient = useQueryClient();
  const {
    mutateAsync: startImpersonating,
    isLoading: loadingStartImpersonation,
  } = useMutation<MedScout.User, unknown, { userId: number }>(({ userId }) => {
    return request({
      url: `/v1/admin/impersonation/`,
      method: 'POST',
      data: {
        impersonate: userId,
      },
    });
  });

  const {
    mutateAsync: stopImpersonating,
    isLoading: loadingStopImpersonating,
  } = useMutation(() => {
    return request({
      url: `/v1/admin/impersonation/`,
      method: 'DELETE',
      data: {},
    });
  });

  const handleStartImpersonating = useCallback(
    async (userId: number) => {
      const response = await startImpersonating({ userId });
      setUser(response);
      queryClient.invalidateQueries();
    },
    [queryClient, setUser, startImpersonating]
  );

  const handleStopImpersonating = useCallback(async () => {
    try {
      await stopImpersonating();
    } catch (err) {
      if (err.response?.status === 400) return;

      console.error(err);
    } finally {
      await refreshLocalUser();
      queryClient.invalidateQueries();
    }
  }, [queryClient, refreshLocalUser, stopImpersonating]);

  return {
    // user: data,
    isLoading: loadingStartImpersonation || loadingStopImpersonating,
    // isError: error,
    impersonate: handleStartImpersonating,
    stopImpersonating: handleStopImpersonating,
  };
};

export default useUserImpersonation;
