import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useGetBuildId } from 'src/api/app-release/releases';
import { usePrevious } from 'src/utils/hooks/usePrevious';

const AppReleaseChecker = () => {
  const router = useRouter();
  const { data: buildIdData } = useGetBuildId();
  const prevBuildId = usePrevious(buildIdData?.data?.buildId);

  useEffect(() => {
    const latestBuildId = buildIdData?.data?.buildId;

    if (latestBuildId && prevBuildId && latestBuildId !== prevBuildId) {
      router.reload();
    }
  }, [buildIdData, prevBuildId]);

  return null;
};

export default AppReleaseChecker;
