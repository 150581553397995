import { Box, Grid, styled } from '@mui/material';

import {
  GridToolbarContainer,
  GridToolbarContainerProps,
} from '@mui/x-data-grid-premium';

interface ToolbarProps extends GridToolbarContainerProps {
  onExport?: (type: string) => void;
  onRemove?: () => void;
  headerSlots?: {
    slot1?: React.ReactNode;
  };
  subHeaderSlots?: {
    slot1?: React.ReactNode;
    slot2?: React.ReactNode;
  };
}

const ToolbarRow = styled(Box)(({ theme }) => ({
  width: '100%',

  padding: '0.5rem 0.5rem 1rem 0.5rem',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,

  '&:last-child': {
    borderBottom: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const TableToolbar = ({ ...props }: ToolbarProps) => {
  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        width: '100%',
      }}
    >
      {props.headerSlots?.slot1 && (
        <ToolbarRow>{props.headerSlots?.slot1}</ToolbarRow>
      )}
      <ToolbarRow>
        <Grid container display="flex" alignItems="center">
          {props.subHeaderSlots?.slot1}
          {props.subHeaderSlots?.slot2}
        </Grid>
      </ToolbarRow>
    </GridToolbarContainer>
  );
};

export default TableToolbar;
