import React, { useState } from 'react';
import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { useLogging } from 'src/context';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { useNewPipeline } from 'src/hooks';
import {
  DialogHeader,
  NewPipelineForm,
  PipelineSharingProps,
} from 'src/components';

/**
 * Add one or more selected opportunities to a pipeline
 *
 * @param {*} {
 *   onHide,
 * }
 * @return {*}
 */
const AddPipelineDialog = ({ onHide }) => {
  const [inlineError, setInlineError] = useState('');
  const [newPipeline, setNewPipeline] = useState<PipelineSharingProps>(null);
  const log = useLogging();
  const { isLoading: isAddingToList, create } = useNewPipeline();

  async function handleNewPipeline() {
    const company_shared = newPipeline.shared.withCompany || false;
    const manager_shared = true;
    const shared_user_ids = Object.keys(newPipeline.shared.users);
    try {
      await create({
        name: newPipeline.name,
        company_shared,
        manager_shared,
        shared_user_ids,
      });
      log.event('createList');

      onHide();
    } catch (err) {
      setInlineError(err.message);
    }
  }

  return (
    <Modal show onHide={onHide}>
      <DialogHeader icon={faFilter}>Create New Pipeline</DialogHeader>
      <Modal.Body>
        <NewPipelineForm onChange={setNewPipeline} />

        {!!inlineError && (
          <Alert className="mt-3" variant="danger" show>
            {inlineError}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-muted"
          disabled={isAddingToList}
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          disabled={!newPipeline?.name || isAddingToList}
          onClick={async () => await handleNewPipeline()}
        >
          {isAddingToList ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Create</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPipelineDialog;
