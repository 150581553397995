import React from 'react';
import _ from 'lodash';
import { Box, Tooltip } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { GridColumnHeaderTitle } from '@mui/x-data-grid-pro';
import { useProspectSearch } from 'src/context';
import { useMemo } from 'react';

const VolumeHeader = () => {
  const { prospectVolumeType, prospectMode } = useProspectSearch();

  //TODO: Might come back to this, we will see what the masses say
  // const label = useMemo(() => {
  //   if (prospectVolumeType === 'patients' && prospectMode !== 'code_volume') {
  //     return 'Referral Patient Count';
  //   } else if (
  //     prospectVolumeType === 'claims' &&
  //     prospectMode === 'code_volume'
  //   ) {
  //     return 'Treating Claims Volume';
  //   } else {
  //     return `Saved Search ${_.upperFirst(prospectVolumeType)} Volume`;
  //   }
  // }, [prospectMode, prospectVolumeType]);

  const tooltip = [
    'When “Treating” is selected, the volumes associated with Providers or',
    'Sites represent their total CLAIMS volume for the selected Saved Search.',
    'When “Referring” mode is selected, the volume represents the PATIENT count Referred to or',
    'from the Provider or Site in the table and the Provider whose Profile you are viewing',
  ].join(' ');

  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100% !important',
      }}
    >
      <GridColumnHeaderTitle label="Total Volumes" columnWidth={0} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <Tooltip title={tooltip} placement="top" arrow>
          <Icon icon={faInfoCircle} size="sm" />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default VolumeHeader;
