import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';

interface AddTerritoryProps {
  name: string;
  user;
  is_canonical;
  geometry?: string[];
  points?: number[];
}

function useAddTerritory() {
  const queryClient = useQueryClient();
  const queryParams = getSearchQueryParamsForAPI({ preview: 0 }); //This may need to be sent from the component
  return useMutation(
    ({ name, user, is_canonical, geometry, points }: AddTerritoryProps) => {
      return request({
        url: `v1/accounts/territories/${queryParams}`,
        method: 'POST',
        data: {
          name,
          user,
          geometry,
          points,
          is_canonical,
        },
        timeout: 60_000,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.territories]);
        // queryClient.invalidateQueries([
        //   PROSPECT_OVERVIEW_QUERY_KEYS.all_rep_prospects,
        // ]);
      },
    }
  );
}

export default useAddTerritory;
