import { useState } from 'react';
import {
  Tabs,
  MenuItem,
  TabProps,
  MenuProps,
  Tab,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link, NextLinkComposed, OverlayMenu } from 'src/components';
import { useRouter } from 'next/router';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLogging, useAuth, useLayoutControl } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';

type ModuleTabProps = {
  path: string;
  label: string;
  menu?: ModuleTabProps[];
  roles?: string[];
};

/**
 * Menu of links to pages within a module.
 *
 * @param {*} { menuItems, rootPath, ...props }
 * @return {*}
 */
const ModuleMenu: React.FC<
  { menuItems: ModuleTabProps[]; rootPath: string } & MenuProps
> = ({ menuItems, rootPath, ...props }) => {
  const log = useLogging();

  return (
    <OverlayMenu {...props}>
      {menuItems.map((item) => {
        const path = [rootPath, item.path].join('/');
        return (
          <MenuItem key={path} sx={{ p: 0 }}>
            <ListItemText>
              <Link
                href={path}
                onClick={() => log.page(path)}
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  px: 2,
                  py: 1,
                }}
                underline="none"
              >
                {item.label}
              </Link>
            </ListItemText>
          </MenuItem>
        );
      })}
    </OverlayMenu>
  );
};

/**
 * Tab for a module. If the module has subpages, the tab will be a dropdown menu.
 *
 * @param {*} { menu, value, label, ...props }
 * @return {*}
 */
const ModuleTab: React.FC<
  { menu?: ModuleTabProps[]; selected?: boolean; roles: string[] } & TabProps
> = ({ menu, value, label, roles, ...props }) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const log = useLogging();
  const { user } = useAuth();
  const { setShowFilterDrawer } = useLayoutControl();

  function filterMenu(menu: ModuleTabProps[]) {
    return menu?.filter((item) =>
      item.roles?.includes('superuser') && !user?.is_superuser ? false : true
    );
  }

  if (roles?.includes('superuser') && !user?.is_superuser) return;

  return menu ? (
    <>
      <Tab
        onClickCapture={(e) => {
          setShowFilterDrawer(false);
          setAnchor(e.currentTarget);
        }}
        label={label}
        value={value}
        icon={<Icon icon={faChevronDown} size="sm" />}
        iconPosition="end"
        {...props}
      />
      <ModuleMenu
        rootPath={value}
        menuItems={filterMenu(menu)}
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={open}
        onClick={() => setAnchor(null)}
        onClose={() => setAnchor(null)}
        MenuListProps={{ dense: true }}
      />
    </>
  ) : (
    <Tab
      component={NextLinkComposed}
      to={value}
      value={value}
      label={label}
      selected={props.selected}
      onClick={() => {
        setShowFilterDrawer(false);
        log.page(value);
      }}
    />
  );
};

const invalidTabs = ['/profiles', '/', '/admin', '/settings'];

/**
 * Tabs for the primary navigation.
 *
 * @return {*}
 */
const ModuleTabs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();
  const { user } = useAuth();
  const { useNewListsModule } = useFlags();

  const pages: ModuleTabProps[] = [
    { path: '/search', label: 'Discovery' },
    useNewListsModule
      ? { path: '/lists', label: 'Lists' }
      : { path: '/pipelines', label: 'Pipelines' },
    {
      path: user?.company?.manager ? '/manager' : '/rep',
      label: 'Territory',
      menu: [
        {
          path: 'territories',
          label: 'Analytics',
        },
        { path: 'territory-overview', label: 'Map' },
      ],
    },
  ];
  const currentTab = '/' + router.pathname.split('/')[1];
  // Since no active tab is rendered on profile pages (because profiles currently
  // fall outside the taxonomy of the other modules), we need to manually set the
  // current tab to 'false' on the profile pages:
  const isValidTab = !invalidTabs.includes(currentTab);

  return (
    <Tabs
      value={isValidTab && currentTab}
      sx={{ flexGrow: 1 }}
      centered={isMobile}
    >
      {pages.map((page) => (
        <ModuleTab
          roles={page.roles}
          value={page.path}
          label={page.label}
          menu={page.menu}
          key={page.path}
        />
      ))}
    </Tabs>
  );
};

export default ModuleTabs;
