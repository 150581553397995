import { Box, Typography, styled } from '@mui/material';

const WidgetComponent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginTop: '-0.5rem',
  marginBottom: '-0.4rem',
  height: '4rem',
  borderLeft: `1px solid ${theme.palette.highlight.dark}`,
  borderRight: `1px solid ${theme.palette.highlight.dark}`,
  borderCollapse: 'collapse',
}));
const WidgetLabel = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.highlight.main,
  fontSize: '0.65rem',
  padding: '0.25rem 0.5rem 0',
  width: '100%',
}));
const WidgetValue = styled(Typography)({
  fontWeight: 600,
  lineHeight: '1em',
  fontSize: '2rem',
  padding: '0.5rem 0.25em 0.5rem',
  color: 'white',
});

interface WidgetProps {
  label: string;
  value: string | number;
}

/**
 * BladeHeaderWidget
 * @description widget to occupy slots in the blade header. Maximum of three.
 *
 * @param {string} {
 *  label,
 *  value
 * }
 * @return {*}
 */
const BladeHeaderWidget: React.FC<WidgetProps> = ({ label, value }) => {
  return (
    <WidgetComponent>
      <WidgetLabel variant="body2">{label}</WidgetLabel>
      <WidgetValue>{value}</WidgetValue>
    </WidgetComponent>
  );
};

export default BladeHeaderWidget;
