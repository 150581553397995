import { Box, Typography, styled } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { grey } from '@mui/material/colors';

const FieldContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  gap: '0.5rem',
  width: '100%',
}));

const IconContainer = styled(Box)(() => ({
  width: '1.5rem',
  textAlign: 'center',
  paddingBottom: '0.5rem',
}));

const FieldLabel = styled(Typography)`
  color: ${grey[600]};
`;

interface FilterFieldProps {
  icon: IconDefinition;
  label: string;
}

const FilterField: React.FC<FilterFieldProps> = ({ icon, label, children }) => {
  return (
    <FieldContainer>
      <IconContainer>
        <Icon icon={icon} size="lg" color="#6a6b72" />
      </IconContainer>
      <Box sx={{ flexGrow: 1 }}>
        <FieldLabel gutterBottom>{label}</FieldLabel>
        {children}
      </Box>
    </FieldContainer>
  );
};

export default FilterField;
