import { forwardRef, useState, useEffect } from 'react';
import { Snackbar, Alert as MuiAlert, AlertProps } from '@mui/material';
import { useNotification } from 'src/context';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = () => {
  const { notification, setNotification } = useNotification();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) return;
    if (!notification) return;

    setNotification(null);
  }, [open]);

  useEffect(() => {
    if (notification) return setOpen(true);
  }, [notification]);

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') return;
    setOpen(false);
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={notification?.type || 'success'}
        sx={{ width: '100%' }}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
