import { Box, styled, Grid } from '@mui/material';
import { ReferringTreatingToggle, VolumeTypeToggle } from './components';

const VolumeSelectorsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexWrap: 'nowrap',
  padding: '0.5rem 0',
}));

interface VolumeSelectorsProps {
  showTreatingReferring?: boolean;
  variant?: 'affiliations' | null;
}

// TODO: Joy UI dropdown does not work on Disocvery page, no ideas.. yet
const VolumeSelectors = ({
  showTreatingReferring = false,
  variant = null,
}: VolumeSelectorsProps) => {
  // change the layout of the VolumeSelectorsContainer based on the variant prop
  const gridWidth =
    variant === 'affiliations' ? { xs: 12, xl: 5 } : { xs: 12, md: 5 };
  const justifyContent =
    variant === 'affiliations'
      ? { xs: 'flex-start', xl: 'flex-end' }
      : { xs: 'flex-start', lg: 'flex-end' };
  return (
    <Grid item {...gridWidth}>
      <VolumeSelectorsContainer
        sx={{
          justifyContent: justifyContent,
        }}
      >
        {showTreatingReferring && <ReferringTreatingToggle />}
        <VolumeTypeToggle />
      </VolumeSelectorsContainer>
    </Grid>
  );
};

export default VolumeSelectors;
