import { useState, useEffect } from 'react';
import storage from 'local-storage-fallback';

/**
 * State retained in local storage
 *
 * @param {string} key
 * @param {*} initialValue
 * @return {*}
 */
const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(storage.getItem(key) || initialValue);
    } catch (err) {
      // Fallback for raw, unparsed values (legacy)
      currentValue = storage.getItem(key) || initialValue;
    }

    return currentValue;
  });

  useEffect(() => {
    storage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;
