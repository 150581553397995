import { useState, useEffect } from 'react';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { Slider } from './components/Slider';
import { format } from 'date-fns';
import { useProspectSearch } from 'src/context';
import { getMarks, DateType } from './utils';
import FilterField from '../FilterField';

interface DateFilterProps {
  applyFilter: (arg0: any) => void;
  removeFilter: (arg0: any) => void;
  requiredContext?: string[];
}

const DateFilter = ({
  applyFilter,
  removeFilter,
  requiredContext,
}: DateFilterProps) => {
  const { prospectFilters } = useProspectSearch();
  const appliedFilter = prospectFilters.find(
    (filter) => filter.context === 'year'
  );
  const [value, setValue] = useState<number[]>([0, 100]);
  const [disableSwap, setDisableSwap] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const marks = getMarks();

  // Logic for setting value to match selected saved search
  useEffect(() => {
    if (!appliedFilter) {
      setValue(() => [0, 100]);
      return;
    }
    const appliedYearFilter: DateType = JSON.parse(appliedFilter?.value);
    const gte = appliedYearFilter?.gte
      ? marks.find((m) => m.label === appliedYearFilter.gte.substring(0, 4))
          ?.value || 0
      : 0;
    let lte = 100;

    switch (appliedYearFilter.lte) {
      case 'Historic':
        lte = 0;
        break;
      case 'now':
        lte = 100;
        break;
      default:
        lte =
          marks.find((m) => m.label === appliedYearFilter.lte.substring(0, 4))
            ?.value || 100;
    }

    setValue(() => [gte, lte]);
  }, [appliedFilter]);

  // Logic for disabling the filter
  useEffect(() => {
    let disableSearchContext = false;
    if (!requiredContext) return;

    disableSearchContext = !prospectFilters
      .map((f) => f.context)
      .some((r) => requiredContext.includes(r));

    if (disableSearchContext) {
      const filter = prospectFilters.find(
        (filter) => filter.context === 'year'
      );
      if (filter) removeFilter(filter);
      setValue([0, 100]);
    }

    setDisabled(disableSearchContext);
  }, [prospectFilters, requiredContext, removeFilter]);

  function createFilter() {
    const years = {
      lte: marks.find((mark) => mark.value === value[1])?.label,
      gte: marks.find((mark) => mark.value === value[0])?.label,
    };

    const currentYearSelected = years.lte === format(new Date(), 'yyyy');
    const label =
      years.gte !== years.lte
        ? currentYearSelected
          ? `${years.gte} - Current`
          : `${years.gte} - ${years.lte}`
        : years.lte.toString();
    if (currentYearSelected) years.lte = 'now';
    if (years.gte === 'Historic') delete years.gte;

    // // Filter Structure
    // // {title: '2023 - 2022', subTitle: '', value: JSON.stringify({year: {lte, gte}}), context: 'date'}
    const newFilter = {
      title: label,
      subTitle: '',
      value: JSON.stringify(years),
      context: 'year',
    };

    applyFilter(newFilter);
  }

  function handleChange(
    e: any,
    newValue: number | number[],
    activeThumb: number
  ) {
    if (!Array.isArray(newValue)) return;

    /*
     * @desc: long story short, you need the swapping disabled to
     *        put a thumb over a thumb, but then it blocks movement, so has to be enabled.
     */
    const isNotEqual = newValue[0] !== newValue[1];
    setDisableSwap(isNotEqual);

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1]), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0])]);
    }
  }

  function snapToMark() {
    const values = marks.map((mark) => mark.value);

    const newValue = value.map((value) => {
      return values.reduce((prev, curr) => {
        return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
      });
    });

    setValue(newValue);
  }

  return (
    <FilterField label="Year" icon={faCalendarAlt}>
      <Slider
        getAriaLabel={() => 'Date Filter'}
        value={value}
        onChange={handleChange}
        onMouseUp={snapToMark}
        onChangeCommitted={createFilter}
        marks={marks}
        disableSwap={disableSwap}
        data-testid="date-filter-slider"
        isSet={prospectFilters.some((filter) => filter.context === 'year')}
        disabled={disabled}
      />
    </FilterField>
  );
};

export default DateFilter;
