import { useMutation, useQueryClient } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

const useUpdateUserInputOption = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ data, mappingId }: { data: any; mappingId: string }) => {
      return await request({
        url: `/v1/integrations/mappings/${mappingId}/user-inputs/${data.id}/`,
        method: 'PATCH',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.includes(
              INTEGRATION_KEYS.GET_USER_INPUT_OPTIONS
            );
          },
        });
      },
    }
  );
};

export default useUpdateUserInputOption;
