import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

const DiagonalHatch = ({ id }) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 10 10" sx={{ width: '100%', height: '100%' }}>
      <pattern
        id={id}
        width="30"
        height="40"
        patternTransform="rotate(135 0 0)"
        patternUnits="userSpaceOnUse"
      >
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="20"
          style={{
            stroke: theme.palette.highlight.main,
            strokeWidth: 80,
            width: 10,
          }}
        />
      </pattern>
    </SvgIcon>
  );
};

export default DiagonalHatch;
