export { default as usePipelines, type PipelineSet } from './usePipelines';
export { default as useNewPipeline } from './useNewPipeline';
export const PIPELINE_QUERY_MAP = new Map([
  ['my_lists', 'my_lists'],
  ['prospect_lists', 'prospect_lists'],
  ['all_normalized_prospects', 'all_normalized_prospects'],
  ['notes', 'notes'],
  ['prospect_list_available_accounts', 'prospect_list_available_accounts'],
  ['prospect_list_analytics', 'prospect_list_analytics'],
  ['prospect', 'prospect'],
  ['company_users', 'company_users'],
  ['crm_picklist_values_Opportunity', 'crm_picklist_values_opportunity'],
  ['crm_picklist_values_Account', 'crm_picklist_values_account'],
  ['crm_creation_options', 'crm_creation_options'],
]);
