import { styled, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: grey[800],
    backgroundColor: 'white',
    '& fieldset': {
      color: grey[300],
      borderColor: grey[300],
    },
    '&:hover fieldset': {
      borderColor: grey[300],
    },
    '&.Mui-focused fieldset': {
      borderColor: grey[300],
    },
  },
});

export default StyledTextField;
