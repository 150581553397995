import { MutableRefObject, useEffect } from 'react';

function useOnClickOutside(
  ref: MutableRefObject<any>,
  callBack: { (): void; (): void }
) {
  function handleClick(e: { target: any }) {
    if (ref.current && !ref.current.contains(e.target)) {
      callBack();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  });
}

export default useOnClickOutside;
