import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  styled,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faClose, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { useAddProviderList } from 'src/hooks';
import { useLogging } from 'src/context';
import { useForm, SubmitHandler } from 'react-hook-form';
import { grey } from '@mui/material/colors';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: grey[800],
    backgroundColor: 'white',
    '& fieldset': {
      color: grey[300],
      borderColor: grey[300],
    },
    '&:hover fieldset': {
      borderColor: grey[300],
    },
    '&.Mui-focused fieldset': {
      borderColor: grey[300],
    },
  },
});

const AddListDialog = ({ open, onClose }) => {
  const log = useLogging();
  const { isLoading, mutateAsync } = useAddProviderList();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm();

  const onSubmit: SubmitHandler<{ listName: string }> = async (data) => {
    const { listName } = data;
    if (!listName) {
      setError('listName', { message: 'List name is required' });
      log.exception('Error creating new list: name not provided');
      return;
    }

    try {
      await mutateAsync(listName);
      log.event('List created', { name: listName });
      reset({ listName: '' });
      onClose();
    } catch (err: any) {
      log.exception(`Error creating new list: ${err}`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography sx={{ fontSize: '1.2rem', color: grey[600] }}>
          <Icon icon={faFilter} color={grey[600]} /> Add New List
        </Typography>
        <Icon icon={faClose} onClick={onClose} color={grey[600]} />
      </DialogTitle>
      <DialogContent>
        <StyledTextField
          fullWidth
          placeholder="List Name"
          {...register('listName', { required: true })}
          error={!!errors.listName}
          helperText={errors.listName?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || !isDirty}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddListDialog;
