import { useMemo, useCallback } from 'react';
import { useQueries, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';

class VolumeData {
  count: number;
  next: string;
  previous: string;
  results: { npi: string; volume: number }[];
}

interface useVolumesBySearchProps {
  searchIds: string[];
  enabledSearchIds: string[];
  npis: any[];
  page?: number;
  pageSize?: number;
}

const useVolumesBySearch = ({
  searchIds,
  enabledSearchIds,
  npis,
  page = 0,
  pageSize = 1000,
}: useVolumesBySearchProps) => {
  const npiPage = useMemo(
    () => npis?.slice(0, pageSize).map((npi) => npi.provider_id) || [],
    [npis, pageSize]
  );
  const volumeData = useQueries(
    searchIds.map((searchId) => ({
      queryKey: ['volumes', searchId, ...npiPage],
      queryFn: async () => {
        const data: Promise<VolumeData> = await request({
          url: `/v1/account/saved-search/${searchId}/volume/?pageSize=${pageSize}`,
          method: 'POST',
          data: {
            npis: npiPage, // TODO: Handle pagination properly
          },
        });

        return { ...data, id: searchId };
      },
      refetchOnWindowFocus: false,
      enabled: enabledSearchIds.indexOf(searchId) > -1, // Only fetch if the column is enabled
    }))
  ) as UseQueryResult<VolumeData & { id: string }>[];

  const filterOnVolumeData = useCallback(
    (volumeColumn) => {
      return (
        !!volumeColumn.data &&
        enabledSearchIds.indexOf(volumeColumn.data.id) >= 0
      );
    },
    [enabledSearchIds]
  );

  // Reduces column data to a map of npi to volume
  // TODO: opportunity for proper Map() object?
  function reduceToVolume(agg, volumeColumn) {
    const npiObject = new Object();
    if (volumeColumn.data.results.length === 0) {
      agg[volumeColumn.data.id] = npiObject;
    } else {
      volumeColumn.data.results.forEach((result) => {
        return (npiObject[result.npi] = result.volume);
      });

      agg[volumeColumn.data.id] = npiObject;
    }

    return agg;
  }

  const volumeColumns = volumeData
    .filter(filterOnVolumeData)
    .reduce(reduceToVolume, {});

  const enhanceNpi = useCallback(
    (npi) => ({
      ...npi,
      ...Object.keys(volumeColumns).reduce((agg, k) => {
        // If no volume data, return n/a per Marcus
        agg[k] = volumeColumns[k]?.[npi.provider_id] || 'n/a';

        return agg;
      }, {}),
    }),
    [volumeColumns]
  );

  return useMemo(() => {
    if (!volumeData.length) return npis;

    return npis.map(enhanceNpi);
  }, [volumeData, enhanceNpi, npis]);
};

export default useVolumesBySearch;
