import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faUserMd,
  faUsers,
  faHospital,
  faClinicMedical,
} from '@fortawesome/pro-solid-svg-icons';
import { SizeProp, Styles } from '@fortawesome/fontawesome-svg-core';

interface TypeIconProps {
  type: string;
  size?: SizeProp;
  style?: Styles;
}

const TypeIcon = ({ type, size = null, style = null }: TypeIconProps) => {
  const iconMap = {
    hcp: faUserMd,
    hcp_group: faUsers,
    center: faHospital,
    clinic: faClinicMedical,
  };

  return (
    <Icon
      icon={iconMap[type?.toLowerCase()] || faUserMd}
      size={size}
      style={style || { marginRight: '0.5rem', width: '1.25rem' }}
    />
  );
};

export default TypeIcon;
