import {
  createContext,
  useReducer,
  useContext,
  useCallback,
  Dispatch,
} from 'react';

const ListsLayoutActionTypes: { [key: string]: string } = {
  SET_SMART_LISTS_DRAWER: 'SET_SMART_LISTS_DRAWER',
};

type ListsLayoutControlState = {
  smartListsDrawer: MedScout.Opportunity | null;
};

type ListsLayoutActions = {
  type: keyof typeof ListsLayoutActionTypes;
  payload: any;
};

const initialState: ListsLayoutControlState = {
  smartListsDrawer: null,
};

const ListsLayoutControlContext = createContext<{
  state: ListsLayoutControlState;
  dispatch: Dispatch<ListsLayoutActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const listsLayoutReducer = (
  state: ListsLayoutControlState,
  action: ListsLayoutActions
) => {
  const newState = { ...state };

  switch (action.type) {
    case ListsLayoutActionTypes.SET_SMART_LISTS_DRAWER:
      newState.smartListsDrawer = action.payload;
      break;
  }

  return newState;
};

export const ListsLayoutProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(listsLayoutReducer, initialState);

  return (
    <ListsLayoutControlContext.Provider value={{ state, dispatch }}>
      {children}
    </ListsLayoutControlContext.Provider>
  );
};

export const useListsLayoutControl = () => {
  const context = useContext(ListsLayoutControlContext);

  if (!context) {
    throw new Error(
      'useListsLayoutControl must be used within a ListsLayoutProvider'
    );
  }

  const { state, dispatch } = context;

  const toggleSmartListsDrawer = useCallback(
    (show: boolean) =>
      dispatch({
        type: ListsLayoutActionTypes.SET_SHOW_SMART_LISTS_DRAWER,
        payload: show,
      }),
    [dispatch]
  );

  return {
    ...state,
    toggleSmartListsDrawer,
  };
};
