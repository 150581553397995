import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { request } from 'src/api/request';
import _ from 'lodash';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { PROSPECT_OVERVIEW_QUERY_KEYS } from 'src/hooks/manager';
import { useLogging, useAuth, useNotification } from 'src/context';
import { PIPELINE_QUERY_MAP, PROFILE_QUERY_MAP } from 'src/hooks';
import { QUERY_KEYS } from 'src/hooks/search/constants';

export type PipelineOwner = {
  id: number;
  first_name: string;
  last_name: string;
};
export type Pipeline = {
  company_shared: boolean;
  manager_shared: boolean;
  count: number;
  id: number;
  name: string;
  oldName: string;
  owner: PipelineOwner;
  shared_users: any[];
};

export function useGetMyProspectLists() {
  const { user } = useAuth();

  return useQuery(
    [PIPELINE_QUERY_MAP.get('my_lists'), user?.company?.id],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        company_id: user?.company?.id,
      });

      return request({
        url: `/v1/prospect-list${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: true,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    }
  );
}

export function useGetAllNormalizedProspects() {
  const queryClient = useQueryClient();
  return useQuery(
    [PIPELINE_QUERY_MAP.get('all_normalized_prospects')],
    () => {
      return request({
        url: `/v1/prospect-list/prospects`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: true,
      staleTime: 60 * 1000,
      onSuccess: (data) => {
        queryClient.invalidateQueries([QUERY_KEYS.search]);
      },
    }
  );
}

export function useUpdateProspectList() {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  return useMutation(
    ({ list, newListName }: { list: any; newListName: string }) => {
      const queryParams = getSearchQueryParamsForAPI({
        list_id: list.id,
        list_name: list.name,
      });

      return request({
        url: `v1/prospect-list${queryParams}`,
        method: 'PUT',
        data: {
          company_id: user?.company?.id,
          new_name: newListName,
          company_shared: list.company_shared,
          manager_shared: true,
          shared_user_ids: _.map(list.shared_users, (shared_user) => {
            return shared_user.id;
          }),
        },
      });
    },
    {
      onSuccess: (
        data,
        body: {
          list: any;
          newListName: string;
          listName: string;
        }
      ) => {
        queryClient.setQueryData(
          [PIPELINE_QUERY_MAP.get('my_lists'), user?.company?.id],
          (old: any) => {
            return {
              ...old,
              lists: _.map(old.lists, (list) => {
                if (list.id !== body.list.id) {
                  return list;
                }

                return {
                  ...list,
                  name: body.newListName,
                  oldName: body.listName,
                };
              }),
            };
          }
        );
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('all_normalized_prospects'),
        ]);
      },
    }
  );
}

export function useDeleteProspectList() {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  return useMutation(
    // company_id, ownerId used for cache busting
    ({
      listName,
      listId,
      ownerId,
    }: {
      listName: string;
      listId: string;
      ownerId: string;
    }) => {
      const queryParams = getSearchQueryParamsForAPI({
        list_name: listName,
        list_id: listId,
      });

      return request({
        url: `v1/prospect-list${queryParams}`,
        data: {},
        method: 'DELETE',
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('my_lists'),
          user?.company?.id,
        ]);
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('all_normalized_prospects'),
        ]);
        // Ensure manager views update appropriately when deleting lists
        queryClient.invalidateQueries([
          PROSPECT_OVERVIEW_QUERY_KEYS.all_rep_prospects,
          user?.company?.id,
        ]);
        queryClient.invalidateQueries([
          PROSPECT_OVERVIEW_QUERY_KEYS.rep_prospect_lists,
          [body.listId],
        ]);
        queryClient.invalidateQueries([
          PROSPECT_OVERVIEW_QUERY_KEYS.individual_rep_prospects,
          body.ownerId,
        ]);

        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('prospect_lists'),
        ]);
      },
    }
  );
}

export function useGetProspectLists(listIds) {
  return useQuery(
    [PIPELINE_QUERY_MAP.get('prospect_lists'), listIds],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        list_ids: listIds,
      });

      return request({
        url: `v1/prospect-list${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !_.isEmpty(listIds),
      keepPreviousData: !_.isEmpty(listIds),
      // due to kanban view refetching on focus is extremely disruptive to ordering
      // when column order is maintained on the backend we can add this back in
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );
}

export function useDeleteProspectFromList() {
  const queryClient = useQueryClient();
  return useMutation(
    // listIds used for cache-busting
    ({ prospectId, listIds }: { prospectId: string; listIds?: any }) => {
      return request({
        url: `v1/prospect/${prospectId}/delete`,
        data: {},
        method: 'DELETE',
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('prospect_lists'),
        ]);
        queryClient.invalidateQueries([PIPELINE_QUERY_MAP.get('my_lists')]);
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('all_normalized_prospects'),
        ]);
        // Ensure manager views update appropriately when deleting lists
        queryClient.invalidateQueries([
          PROSPECT_OVERVIEW_QUERY_KEYS.all_rep_prospects,
        ]);
        queryClient.invalidateQueries([
          PROSPECT_OVERVIEW_QUERY_KEYS.rep_prospect_lists,
        ]);
        queryClient.invalidateQueries([
          PROSPECT_OVERVIEW_QUERY_KEYS.individual_rep_prospects,
        ]);
      },
    }
  );
}

export function useResetProspectAccountOptionsQueryData({ prospect_id, q }) {
  const queryClient = useQueryClient();
  // when the prospect_id changes we want to ensure there are no stale values for previous accounts
  // due to the query search happening on this same key it requires this manual intervention to
  // ensure we are fetching fresh on prospect id changes

  return () => {
    const queryKey = [
      PIPELINE_QUERY_MAP.get('prospect_list_available_accounts'),
      prospect_id,
      q,
    ];

    queryClient.setQueryData(queryKey, () => {
      return null;
    });

    queryClient.invalidateQueries(queryKey);
  };
}

export function useGetProspectAccountOptions({ prospect_id, q }) {
  return useQuery(
    [
      PIPELINE_QUERY_MAP.get('prospect_list_available_accounts'),
      prospect_id,
      q,
    ],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        prospect_id,
        q,
      });

      return request({
        url: `v1/crm/available-accounts${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: Boolean(prospect_id),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 30_000,
    }
  );
}

export function useUpdateProspectStatus() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      prospectId,
      status,
      syncWithCrm,
      crm_account,
      crm_opportunity,
      crm_contact,
      crm_lead,
      stageIds,
    }: {
      prospectId: string;
      status;
      syncWithCrm?;
      crm_account?;
      crm_opportunity?;
      crm_contact?;
      crm_lead?;
      stageIds;
    }) => {
      return request({
        url: `/v1/prospect/${prospectId}/status`,
        method: 'PATCH',
        data: {
          status,
          sync: syncWithCrm,
          crm_account,
          crm_opportunity,
          crm_contact,
          crm_lead,
        },
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.setQueryData(
          [PIPELINE_QUERY_MAP.get('prospect_lists'), body.stageIds],
          (old: any) => {
            const prospectCopy = _.map(old.prospects, (prospect) => {
              if (`${prospect.id}` === `${body.prospectId}`) {
                // returning copies of the prospect will ensure useEffect triggers
                return {
                  ...prospect,
                  status: body.status,
                };
              }
              return prospect;
            });

            return {
              ...old,
              // utilized to prevent rerenders and regeneration of pipelines when on funnel view
              optimisticUpdate: true,
              prospects: prospectCopy,
            };
          }
        );
        queryClient.cancelQueries([
          PIPELINE_QUERY_MAP.get('prospect_lists'),
          body.stageIds,
        ]);
      },
    }
  );
}

export function useAddProspectToList({ source } = { source: 'referralView' }) {
  const queryClient = useQueryClient();
  const log = useLogging();
  const { user } = useAuth();

  const { mutateAsync, isLoading } = useMutation(
    ({
      providerId,
      listName,
      listId,
      contentType,
      company_id,
      company_shared,
      manager_shared,
      shared_user_ids,
    }: {
      providerId: string;
      listName: string;
      listId?: string;
      contentType: string;
      company_id: string;
      company_shared?;
      manager_shared?;
      shared_user_ids?;
    }) => {
      const conditionalSharedPayload = {};
      if (!_.isNil(company_shared)) {
        conditionalSharedPayload['company_shared'] = company_shared;
      }

      if (!_.isNil(manager_shared)) {
        conditionalSharedPayload['manager_shared'] = manager_shared;
      }

      if (!_.isNil(shared_user_ids)) {
        conditionalSharedPayload['shared_user_ids'] = shared_user_ids;
      }

      return request({
        url: `/v1/prospect-list/new`,
        method: 'POST',
        data: {
          provider_id: providerId,
          list_name: listName,
          list_id: listId,
          content_type: contentType,
          company_id,
          ...conditionalSharedPayload,
        },
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('my_lists'),
          body.company_id,
        ]);
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('all_normalized_prospects'),
        ]);
        queryClient.invalidateQueries([
          PROFILE_QUERY_MAP.get('profile_details'),
          body.providerId,
          body.contentType,
          body.company_id,
        ]);
      },
    }
  );

  async function addProspect({ prospect, list }) {
    if (!prospect || !list) return;

    await mutateAsync({
      providerId: prospect.provider_id,
      listId: list.id,
      listName: list.name,
      company_id: user?.company?.id?.toString(),
      contentType: prospect.content_type || 'hcp',
    });

    log.event('addProspect', { source });
  }

  return { mutateAsync, isLoading, addProspect };
}

export function useAddMultipleProspectsToList() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      providers,
      listName,
      listId,
      contentType,
      company_id,
      company_shared,
      manager_shared,
      shared_user_ids,
    }: {
      listName: string;
      listId?: string;
      providers;
      contentType?: string;
      company_id?: string;
      company_shared?;
      manager_shared?;
      shared_user_ids?;
    }) => {
      const conditionalSharedPayload = {};
      if (!_.isNil(company_shared)) {
        conditionalSharedPayload['company_shared'] = company_shared;
      }

      if (!_.isNil(manager_shared)) {
        conditionalSharedPayload['manager_shared'] = manager_shared;
      }

      if (!_.isNil(shared_user_ids)) {
        conditionalSharedPayload['shared_user_ids'] = shared_user_ids;
      }

      return request({
        url: `/v1/prospect-list/new/bulk`,
        method: 'POST',
        data: {
          prospects: _.map(providers, (provider) => {
            return {
              provider_id: provider.provider_id,
              content_type: provider.content_type || contentType,
            };
          }),
          list_name: listName,
          list_id: listId,
          company_id,
          ...conditionalSharedPayload,
        },
      });
    },
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('my_lists'),
          body.company_id,
        ]);
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('all_normalized_prospects'),
        ]);
        _.forEach(body.providers, ({ provider_id }) => {
          queryClient.invalidateQueries([
            PROFILE_QUERY_MAP.get('profile_details'),
            provider_id,
            body.contentType,
            body.company_id,
          ]);
        });
      },
    }
  );
}

export function useAddNote() {
  const queryClient = useQueryClient();
  let latestRequestTimestamp = null;

  return useMutation(
    // company_id for cache busting
    ({
      providerId,
      note,
      contentType,
      company_id,
    }: {
      note;
      providerId: string;
      contentType: string;
      company_id?: string;
    }) => {
      latestRequestTimestamp = new Date().toISOString();
      return request({
        url: `/v1/prospect/notes/new`,
        method: 'POST',
        data: {
          provider_id: providerId,
          content_type: contentType,
          note,
        },
      });
    },
    {
      onError: (err: any, body) => {
        console.error('err', err);
      },
      onSuccess: (
        data,
        body: {
          id: string;
          note;
          providerId: string;
          contentType: string;
          company_id: string;
        }
      ) => {
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('notes'),
          body.id,
        ]);

        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey.includes(PROFILE_QUERY_MAP.get('profile_details')),
        });
      },
    }
  );
}

export function useEditNote() {
  const queryClient = useQueryClient();

  return useMutation(
    // unused providerId, contentType, company_id for cachebusting in onSuccess
    ({
      providerId,
      contentType,
      noteId,
      note,
      company_id,
    }: {
      noteId: string;
      note;
      providerId: string;
      contentType: string;
      company_id: string;
    }) => {
      return request({
        url: `/v1/prospect/${noteId}/notes/`,
        data: { id: noteId, note },
        method: 'PUT',
      });
    },
    {
      onError: (err: any, body) => {
        console.error('err', err);
      },
      onSuccess: (data, body) => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey.includes(PROFILE_QUERY_MAP.get('profile_details')),
        });
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('notes'),
          body.noteId,
        ]);
      },
    }
  );
}

export function useDeleteNote() {
  const queryClient = useQueryClient();

  return useMutation(
    // unused providerId, contentType, company_id for cachebusting in onSuccess
    ({
      providerId,
      contentType,
      noteId,
      company_id,
    }: {
      noteId: string;
      providerId: string;
      contentType: string;
      company_id: string;
    }) => {
      return request({
        url: `/v1/prospect/${noteId}/notes/`,
        data: { id: noteId },
        method: 'DELETE',
      });
    },
    {
      onError: (err: any, body) => {
        console.error('err', err);
      },
      onSuccess: (data, body) => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey.includes(PROFILE_QUERY_MAP.get('profile_details')),
        });
        queryClient.invalidateQueries([
          PIPELINE_QUERY_MAP.get('notes'),
          body.noteId,
        ]);
      },
    }
  );
}

export function useGetCrmNotes({ providerId }) {
  const { setNotification } = useNotification();
  return useQuery(
    [PIPELINE_QUERY_MAP.get('prospect_lists'), providerId, 'notes'],
    () => {
      return request({
        url: `/v1/crm/contact/${providerId}/notes`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !!providerId,
      keepPreviousData: !!providerId,
      // due to kanban view refetching on focus is extremely disruptive to ordering
      // when column order is maintained on the backend we can add this back in
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      onError: (err: any) => {
        setNotification({
          title:
            'There was an issue fetching your notes, please try again shortly.',
          message: err.message,
          type: 'error',
        });
      },
    }
  );
}

export function useGetMyCompanyUsers({ company_id = null }) {
  return useQuery(
    [PIPELINE_QUERY_MAP.get('company_users'), company_id],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        company_id,
      });

      return request({
        url: `/v1/company/users${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: true,
      staleTime: 60_000,
    }
  );
}

export function useGetCrmPicklistValues({ type }) {
  return useQuery(
    [PIPELINE_QUERY_MAP.get(`crm_picklist_values_${type}`)],
    () => {
      return request({
        url: `v1/crm/picklist-values/${type}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !!type,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60_000,
    }
  );
}

export function useGetCrmCreationOptions({ prospectId, stageId = '' }) {
  return useQuery(
    [PIPELINE_QUERY_MAP.get(`crm_creation_options`), prospectId],
    () => {
      return request({
        url: `v1/crm/prospect/${prospectId}/creation-options/${stageId}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !!prospectId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60_000,
    }
  );
}

export function usePipelineAnalytics({
  prospectListId,
}: {
  prospectListId: string;
}): UseQueryResult<{ id: string; average: number; thresholds: number[] }[]> {
  return useQuery(
    [PIPELINE_QUERY_MAP.get('prospect_list_analytics'), prospectListId],
    () => {
      return request({
        url: `v1/prospect-list/${prospectListId}/analytics`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: !!prospectListId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 60_000,
    }
  );
}
