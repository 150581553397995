import { Box, styled } from '@mui/material';
import { useRouter } from 'next/router';

const SmartListBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: theme.palette.grey[800],
  },
}));

const SmartListSavedSearchCell = ({ row }) => {
  const { push } = useRouter();
  if (!row) return null;

  return (
    <SmartListBox onClick={() => push(`/lists/smart/${row?.id}`)}>
      {row?.saved_search}
    </SmartListBox>
  );
};

export default SmartListSavedSearchCell;
