import { Form } from 'react-bootstrap';
import {
  BORDER_STYLES,
  COLOR_MAP,
} from 'src/utils/constants/scss-variables.constants';
import { useGetMyCompanyUsers } from 'src/api/prospects/prospect-lists';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import styles from './AddPropsectDialog.module.scss';
import { useAuth } from 'src/context';

export interface PipelineSharingProps {
  name: string;
  shared: {
    users: { [userId: number]: any };
    withCompany: boolean;
  };
}

const NewPipelineForm: React.FC<{
  onChange: ({ name, shared }: PipelineSharingProps) => void;
}> = ({ onChange: handleChange }) => {
  const [name, setName] = useState('');
  const [shared, setShared] = useState<PipelineSharingProps['shared']>({
    users: [],
    withCompany: false,
  });
  useEffect(() => {
    handleChange({ name, shared });
  }, [name, shared, handleChange]);
  const { user } = useAuth();
  const { data: companyUsers } = useGetMyCompanyUsers({
    company_id: user?.company?.id,
  });
  const currentUserManagerList = user?.company?.managers
    .map((manager) => `${manager.first_name} ${manager.last_name}`)
    .join(', ');

  function handleUserShare(user) {
    return (e) => {
      const { checked } = e.currentTarget;

      setShared((oldShared) => {
        const newShared = { ...oldShared };
        const newUsers = { ...(oldShared.users || {}) };

        if (checked) {
          newUsers[user.id] = user;
        } else {
          delete newUsers[user.id];
        }

        newShared.users = newUsers;

        return newShared;
      });
    };
  }

  function handleCompanyShare(e) {
    const { checked } = e.currentTarget;

    setShared((oldShared) => ({
      ...oldShared,
      withCompany: checked,
    }));
  }

  return (
    <Form
      style={{
        padding: '0.25rem 0.75rem 0.75rem',
        borderRadius: '5px',
        margin: '0.5rem 0.5rem 0.5rem 0',
      }}
      className={`${styles.editListContainerActive} ${styles.listGroupItem}`}
      onSubmit={(e) => e.preventDefault()}
    >
      <Form.Group>
        <Form.Control
          className="mt-2"
          type="text"
          placeholder="Pipeline Name"
          aria-label="Pipeline Name"
          value={name}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <Form.Label className="mt-2" style={{ color: COLOR_MAP.black }}>
          Share with Users
        </Form.Label>
        <div
          style={{
            backgroundColor: COLOR_MAP.white,
            border: BORDER_STYLES['border-thin'],
            borderColor: COLOR_MAP['gray-lighter'],
            borderRadius: 3,
            padding: 10,
            maxHeight: 150,
            overflowY: 'auto',
          }}
        >
          {user?.company?.manager && (
            <Form.Check
              type="checkbox"
              label="Share with Entire Company"
              id="share-with-company"
              onChange={handleCompanyShare}
            />
          )}
          <Form.Check
            type="checkbox"
            label={`Share with Managers${
              _.isEmpty(currentUserManagerList)
                ? ''
                : ` (${currentUserManagerList})`
            }`}
            id="share-with-manager"
            checked={true}
            readOnly
            disabled
          />

          <hr style={{ marginTop: 5, marginBottom: 5 }} />

          {_.map(
            _.sortBy(
              companyUsers?.users,
              (companyUser) =>
                `${companyUser.first_name} ${companyUser.last_name}`
            ),
            (companyUser) => {
              // Do not return yourself
              if (companyUser.id === user?.id) return null;
              const name = `${companyUser.first_name} ${companyUser.last_name}`;

              return (
                <Form.Check
                  key={companyUser.id}
                  label={name}
                  id={`shared-with-${companyUser.id}`}
                  onChange={handleUserShare(companyUser)}
                />
              );
            }
          )}
        </div>
      </Form.Group>
    </Form>
  );
};

export default NewPipelineForm;
