import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

interface ControlledCheckboxProps {
  name: string;
  label?: string;
  rules?: any;
  required?: boolean;
  value?: boolean;
  errorText?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

const ConrolledCheckbox = ({ ...props }: ControlledCheckboxProps) => {
  const { control } = useFormContext();

  if (!props) return null;

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={field.value || false}
                onChange={field.onChange}
                disabled={props.disabled || false}
                size={props.size || 'medium'}
              />
            }
            label={props.label}
          />
        );
      }}
    />
  );
};

export default ConrolledCheckbox;
