import { useMutation } from 'react-query';
import { request } from 'src/api/request';
import { INTEGRATION_KEYS } from './constants';

interface StartOneTimeSyncParams {
  mappingId: number | number;
}

const useStartOneTimeSync = () => {
  return useMutation(
    [INTEGRATION_KEYS.START_ONE_TIME_SYNC],
    async ({ mappingId }: StartOneTimeSyncParams) => {
      return await request({
        url: '/v1/integrations/one-time-sync-trackers/',
        method: 'POST',
        data: {
          mapping: mappingId,
        },
      });
    }
  );
};

export default useStartOneTimeSync;
