import { Link, Chip, ChipProps, Tooltip, Typography } from '@mui/material';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import { textColorBasedOnBg } from 'src/utils/color.helpers';

const Tag: React.FC<ChipProps & { tag }> = ({ tag, sx, ...props }) => {
  const bg = tag.bgColor || tag.bg_color || COLOR_MAP['gray-lightest'];
  const displayText = tag.FormattedText ? (
    <tag.FormattedText />
  ) : (
    <Typography sx={{ fontSize: '0.65rem' }}>
      {tag.name?.toUpperCase()}
    </Typography>
  );
  const fullName = tag.fullText || displayText;

  function renderChip() {
    return (
      <Tooltip title={fullName} arrow>
        <Chip
          label={displayText}
          {...props}
          sx={{
            backgroundColor: bg,
            color: tag.color || textColorBasedOnBg({ bgHex: bg }),
            border: '1px #00000033 solid',
            ...sx,
          }}
        />
      </Tooltip>
    );
  }

  return !tag.taggable_type && tag.href ? (
    <Link href={tag.href} aria-label="Pipeline Tag" maxWidth="100%">
      {renderChip()}
    </Link>
  ) : (
    <>{renderChip()}</>
  );
};

export default Tag;
