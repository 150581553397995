const createDataSourcesInfoSlice = (set, get) => ({
  dataSourcesInfo: {},
  updateDataSourcesInfo: (dataSourcesInfo) => {
    set(() => {
      return {
        dataSourcesInfo: { ...dataSourcesInfo },
      };
    });
  },
});

export default createDataSourcesInfoSlice;
