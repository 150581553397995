import React, { useState, useMemo } from 'react';
import styles from './AddPropsectDialog.module.scss';
import {
  Button,
  Form,
  Modal,
  Spinner,
  Alert,
  ListGroup,
} from 'react-bootstrap';
import {
  useGetMyProspectLists,
  useAddMultipleProspectsToList,
} from 'src/api/prospects/prospect-lists';
import _ from 'lodash';
import { useLogging, useAuth } from 'src/context';
import { styled } from '@mui/material';
import { faPlus, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  DialogHeader,
  NewPipelineForm,
  PipelineSharingProps,
} from 'src/components';

const PipelineGroup = styled('h6')`
  font-size: 0.9rem;
  color: #6c757d;
  margin: 0.5rem 0;
  font-weight: 200;
`;
const PipelineOwner = styled('span')`
  margin: 0 0.25rem;
  font-size: 0.75rem;
`;

/**
 * Add one or more selected opportunities to a pipeline
 *
 * @param {*} {
 *   onHide,
 *   newProspects,
 * }
 * @return {*}
 */
const AddProspectDialog = ({ onHide, newProspects }) => {
  const [inlineError, setInlineError] = useState('');
  const [createNewPipeline, setCreateNewPipeline] = useState(false);
  const [selectedProspectList, setSelectedProspectList] = useState(null);
  const [newPipeline, setNewPipeline] = useState<PipelineSharingProps>(null);
  const log = useLogging();
  const { user } = useAuth();
  const { isLoading: isAddingToList, mutateAsync: addMultipleToProspectList } =
    useAddMultipleProspectsToList();
  // TODO: Update this to the usePipeline hook
  const { data: userPipelines } = useGetMyProspectLists();
  const prospectLanguage =
    _.size(newProspects?.prospects) > 1
      ? `${_.size(newProspects?.prospects)} Prospects`
      : 'Prospect';

  const { userLists, sharedListsByOwner } = useMemo(() => {
    const sortedProspectLists = _.orderBy(userPipelines?.lists || [], 'name');
    const userLists = [];
    const sharedListsByOwner = {};
    _.forEach(sortedProspectLists, (prospectList) => {
      if (user?.id === prospectList.owner.id) {
        userLists.push(prospectList);
      } else {
        sharedListsByOwner[prospectList.owner.id] =
          sharedListsByOwner[prospectList.owner.id] || [];
        sharedListsByOwner[prospectList.owner.id].push(prospectList);
      }
    });

    return {
      userLists,
      sharedListsByOwner: _.sortBy<{ [id: string]: { owner; map } }>(
        sharedListsByOwner,
        [
          (
            lists: {
              owner: { first_name: string; last_name: string };
            }[]
          ) => {
            // sort the reps by their names for consistency
            const firstList = lists[0];
            return `${firstList.owner.first_name} ${firstList.owner.last_name}`;
          },
        ]
      ),
    };
  }, [user, userPipelines]);

  async function handleSubmit() {
    try {
      const responses = await addMultipleToProspectList({
        providers: newProspects.prospects,
        listName: selectedProspectList.name,
        listId: selectedProspectList.id,
        contentType: newProspects.prospects[0].content_type,
      });

      if (
        _.every(responses, (response) => {
          return response.status === 'rejected';
        })
      ) {
        throw new Error(
          'All prospects failed to be added to the list, they are likely already part of this list.'
        );
      }

      log.event('addProspect', {
        source: 'searchView',
        view: newProspects.ui_fromMap ? 'map' : 'table',
        count: _.size(newProspects.prospects),
      });

      if (newProspects?.onSuccess) {
        newProspects.onSuccess();
      }
      onHide();
    } catch (err) {
      setInlineError(err.message);
    }
  }

  async function handleNewPipeline() {
    if (!newPipeline) return;

    log.event('createList');
    const company_shared = newPipeline.shared.withCompany || false;
    const manager_shared = true;
    const shared_user_ids = Object.keys(newPipeline.shared.users);

    try {
      const responses = await addMultipleToProspectList({
        providers: newProspects.prospects,
        listName: newPipeline.name,
        contentType: newProspects.prospects[0].content_type,
        company_id: user?.company?.id?.toString(),
        company_shared,
        manager_shared,
        shared_user_ids,
      });

      if (
        _.every(responses, (response) => {
          return response.status === 'rejected';
        })
      ) {
        throw new Error(
          'All prospects failed to be added to the list, they are likely already part of this list.'
        );
      }
      log.event('addProspect', {
        source: 'searchView',
        count: _.size(newProspects.prospects),
      });
      const source = 'searchViewCreateModal';

      log.event('prospectListSharedStatusUpdated', {
        source,
        company_shared,
        manager_shared,
        shared_user_count: shared_user_ids.length,
        shared_with_users: !!shared_user_ids.length,
      });

      if (newProspects?.onSuccess) {
        newProspects.onSuccess();
      }

      onHide();
    } catch (err) {
      setInlineError(err.message);
    }
  }

  function handleSelectPipeline(pipeline) {
    setCreateNewPipeline(false);
    setSelectedProspectList(pipeline);
  }

  function toggleNewPipeline() {
    setSelectedProspectList(null);
    setCreateNewPipeline(!createNewPipeline);
  }

  if (!userLists) return null;

  return (
    <Modal show onHide={onHide}>
      <DialogHeader icon={faUserCheck}>
        Add {prospectLanguage} to Pipeline
      </DialogHeader>
      <Modal.Body style={{ padding: '0 0 0 0.5rem' }}>
        <div style={{ maxHeight: 'calc(100vh - 15rem)', overflowY: 'auto' }}>
          <PipelineGroup>My Pipelines</PipelineGroup>
          <ListGroup>
            {userLists.map((pipeline) => {
              const listIsActive = selectedProspectList?.id === pipeline.id;
              return (
                <ListGroup.Item
                  key={`pipeline_${pipeline.id}`}
                  className={`${
                    listIsActive ? styles.editListContainerActive : ''
                  } ${styles.listGroupItem}`}
                >
                  <Form.Check
                    id={`list-${pipeline.id}`}
                    type="radio"
                    label={pipeline.name}
                    checked={listIsActive}
                    onChange={() => handleSelectPipeline(pipeline)}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <PipelineGroup>Team Pipelines</PipelineGroup>
          <ListGroup>
            {_.map(sharedListsByOwner, (repLists) => {
              const owner = repLists[0].owner;
              return (
                <React.Fragment key={`list-owner-${owner.id}`}>
                  {repLists.map((pipeline) => {
                    const listIsActive =
                      selectedProspectList?.id === pipeline.id;

                    return (
                      <ListGroup.Item
                        key={pipeline.id}
                        className={`${
                          listIsActive ? styles.editListContainerActive : ''
                        } ${styles.listGroupItem}`}
                      >
                        <Form.Check
                          type="radio"
                          id={`list-${pipeline.id}`}
                          label={
                            <>
                              {pipeline.name}
                              <PipelineOwner>{`by ${owner.first_name} ${owner.last_name}`}</PipelineOwner>
                            </>
                          }
                          checked={listIsActive}
                          onChange={() => handleSelectPipeline(pipeline)}
                        />
                      </ListGroup.Item>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </ListGroup>
        </div>
        <hr style={{ backgroundColor: 'gray', margin: '0.5rem -1rem' }} />
        <div
          className="d-flex align-items-center"
          style={{ cursor: 'pointer', margin: '1rem 0.5rem' }}
          onClick={toggleNewPipeline}
          aria-label="Create new pipeline"
        >
          <Icon icon={faPlus} size="lg" style={{ marginRight: '0.5rem' }} />
          Create new pipeline...
        </div>
        {createNewPipeline && <NewPipelineForm onChange={setNewPipeline} />}
        {!!inlineError && (
          <Alert className="mt-3" variant="danger" show>
            {inlineError}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-muted"
          disabled={isAddingToList}
          onClick={onHide}
        >
          Cancel
        </Button>
        {createNewPipeline ? (
          <Button
            disabled={!newPipeline?.name || isAddingToList}
            onClick={handleNewPipeline}
            aria-label="Create and Add Prospects to Pipeline"
          >
            {isAddingToList ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Create and Add {prospectLanguage}</span>
            )}
          </Button>
        ) : (
          <Button
            disabled={!selectedProspectList?.name || isAddingToList}
            onClick={handleSubmit}
            aria-label="Add Prospects to Pipeline"
          >
            {isAddingToList ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Add {prospectLanguage}</span>
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddProspectDialog;
