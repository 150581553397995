import { useQueryClient, useMutation } from 'react-query';
import { request } from 'src/api/request';
import { QUERY_KEYS } from './constants';

/**
 * @description Add a new provider list
 * @param listName
 */
const useAddProviderList = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (listName: string) => {
      return request({
        url: `/v1/provider-lists/lists/`,
        method: 'POST',
        data: {
          name: listName,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.providerLists);
      },
    }
  );
};

export default useAddProviderList;
