import React from 'react';
import { Button, styled } from '@mui/material';
import { useLogging } from 'src/context';

interface RootProps {
  open: boolean;
}

const ToggleButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'open',
})<RootProps>(({ theme, open }) => ({
  height: '4rem',
  width: '1.75rem',
  minHeight: 0,
  minWidth: 0,
  position: 'absolute',
  top: theme.spacing(14),
  right: 0,
  transform: 'translateY(-10%)',
  zIndex: 1,
  borderRadius: 0,
  borderTopLeftRadius: '0.5rem',
  borderBottomLeftRadius: '0.5rem',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: 'white',
  color: theme.palette.fill.main,
  '&:hover': {
    backgroundColor: theme.palette.highlight.light,
  },
}));

interface DrawerToggleProps {
  children: React.ReactNode;
  toggleOpen: () => void;
  open: boolean;
  drawer: string;
}

const DrawerToggle = ({
  toggleOpen,
  children,
  open,
  drawer,
}: DrawerToggleProps & RootProps) => {
  const log = useLogging();

  function handleToggle() {
    log.event(
      `Toggle Blade Button ${open ? 'Closed' : 'Opened'} Drawer: ${
        drawer || 'unknown'
      }`
    );
    toggleOpen();
  }

  return (
    <ToggleButton open={open} onClick={handleToggle} aria-label="Toggle Blade">
      {children}
    </ToggleButton>
  );
};

export default DrawerToggle;
