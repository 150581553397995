import React from 'react';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@mui/material';
import { Link } from 'src/components';
import { nanoid } from 'nanoid';

const AffiliationCell = ({ row }) => {
  const theme = useTheme();
  if (!row.hcp?.affiliations && !row.affiliations) return null;

  function affiliations(row) {
    const affils = row.hcp?.affiliations || row.affiliations;

    return affils
      ?.filter((affil) => affil.name)
      ?.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <div>
      {affiliations(row)?.map((affil) => {
        return (
          <Box key={nanoid()}>
            <Link
              href={`/profiles/center/${affil.provider_id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '0.65rem',
                color: theme.palette.primary.main,
                fontWeight: 500,
              }}
            >
              {affil.name} <Icon icon={faExternalLink} />
            </Link>
          </Box>
        );
      })}
    </div>
  );
};

export default AffiliationCell;
