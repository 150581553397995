import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { request } from 'src/api/request';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { QUERY_KEYS } from './constants';
import { MapService } from 'src/services';

/**
 * Custom hook for fetching map search results.
 *
 * @param queryParams - The query parameters for the search.
 * @param enabled - Whether the query should be enabled or not.
 * @param mapState - The state of the map.
 * @param company_id - The ID of the company.
 * @returns The result of the query.
 */
export default function useGetMapSearchResults({
  queryParams,
  enabled,
  mapState,
  company_id,
}): UseQueryResult<any, { message: string } | null> {
  // bounds get set to null to help with legacy caching below
  const bounds = MapService.getBoundsFromMapState(mapState);
  const params = getSearchQueryParamsForAPI(
    {
      bounds,
      company_id,
    },
    queryParams
  );

  return useQuery(
    [QUERY_KEYS.search_map, params],
    () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // map request data without bounds can be multiple mb's of data allow client cancellation
      const promise = request({
        url: `/v1/query/map${params}`,
        data: {},
        method: 'GET',
        cancelToken: source.token,
      });

      return promise;
    },
    {
      enabled,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
