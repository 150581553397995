import { forwardRef, useMemo } from 'react';
import { Popover, Spinner } from 'react-bootstrap';
import { useGetSearchProviderDetails } from 'src/hooks';
import { COLOR_MAP } from 'src/utils/constants/scss-variables.constants';
import numeral from 'numeral';
import _ from 'lodash';
import { useProspectSearch } from 'src/context';

const DetailsPopover = forwardRef<
  HTMLDivElement,
  { row; popper?; show?: boolean }
>(({ row, popper, children, show, ...props }, ref) => {
  const { provider_id, details_params } = row;
  const { prospectMode, prospectVolumeType } = useProspectSearch();

  const { data, isLoading } = useGetSearchProviderDetails({
    provider_id,
    details_params,
    enabled: show,
  });

  const sortedRecordsByClaims = useMemo(() => {
    return _.orderBy(
      data,
      [
        (detail) => {
          if (!detail.claims || detail.claims === '<11') return -1;

          return Number(detail.claims);
        },
        'year',
        'payment',
      ],
      ['desc', 'desc', 'desc']
    );
  }, [data]);

  const sortedRecordsByPatients = useMemo(() => {
    return _.orderBy(
      data,
      [
        (detail) => {
          if (!detail.patients || detail.patients === '<11') return -1;

          return Number(detail.patients);
        },
        'year',
        'payment',
      ],
      ['desc', 'desc', 'desc']
    );
  }, [data]);

  return (
    <Popover ref={ref} {...props}>
      <Popover.Header as="h3">Detailed Breakdown</Popover.Header>
      <Popover.Body
        style={{ overflowY: 'auto', maxHeight: 250, minHeight: 250 }}
      >
        <div>
          {isLoading && (
            <div className="text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                size="sm"
                variant="muted"
              />
            </div>
          )}
          {!details_params && (
            <div style={{ padding: 10, fontSize: 14 }}>
              <em>
                There are no expanded details available for this record. Please
                open their profile to view more information.
              </em>
            </div>
          )}
          {_.map(
            prospectVolumeType === 'claims'
              ? sortedRecordsByClaims
              : sortedRecordsByPatients,
            (row, idx) => {
              const isFirst = idx === 0;
              const isIndustryPaymentType = row.type === 'payments';
              return (
                <div
                  key={`${row.code || row.drug}_${row.year}`}
                  style={{
                    borderTop: isFirst ? 'none' : '1px solid',
                    borderColor: COLOR_MAP['gray-lighter'],
                    padding: 10,
                    paddingTop: isFirst ? 0 : 5,
                    paddingBottom: 5,
                  }}
                >
                  {isIndustryPaymentType && (
                    <p>
                      <strong>Open Payment:</strong>{' '}
                      {numeral(row.payment).format('$0,0.00')}
                    </p>
                  )}
                  {_.has(row, 'claims') && prospectVolumeType === 'claims' && (
                    <p>
                      <strong>Claims:</strong>{' '}
                      {row.claims === '<11'
                        ? row.claims
                        : numeral(Number(row.claims)).format('0,0')}
                    </p>
                  )}
                  {_.has(row, 'patients') && prospectVolumeType === 'patients' && (
                    <p>
                      <strong>Patients:</strong>{' '}
                      {numeral(Number(row.patients)).format('0,0')}
                    </p>
                  )}
                  {_.has(row, 'company') && (
                    <p style={{ fontSize: 12 }}>
                      <strong>Company:</strong> {row.company}
                    </p>
                  )}
                  {_.has(row, 'code') && (
                    <p style={{ fontSize: 12 }}>
                      <strong>Code:</strong> {row.code} ({row.description})
                    </p>
                  )}
                  {_.has(row, 'drug') && (
                    <p style={{ fontSize: 12 }}>
                      <strong>Drug:</strong> {row.drug}{' '}
                      {row?.generic && `(${row.generic})`}
                    </p>
                  )}
                  {_.has(row, 'year') && (
                    <p style={{ fontSize: 12 }}>
                      <strong>Year:</strong> {row.year}
                    </p>
                  )}
                  {!isIndustryPaymentType && _.has(row, 'avg_reimbursement') && (
                    <p style={{ fontSize: 12 }}>
                      <strong>Reimbursement:</strong>{' '}
                      {numeral(row.avg_reimbursement).format('$0,0.00')}
                    </p>
                  )}
                </div>
              );
            }
          )}
        </div>
      </Popover.Body>
    </Popover>
  );
});
DetailsPopover.displayName = 'DetailsPopover';

export default DetailsPopover;
