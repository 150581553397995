function MedScoutLoadingIcon() {
  return (
    <svg
      data-testid="medscoutLoadingIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="174"
      height="174"
      fill="none"
      viewBox="0 0 174 174"
    >
      <path
        fill="#2391D9"
        d="M88.986 139.093a30.5 30.5 0 01-27.729-30.363V34a30.502 30.502 0 0127.73 30.363v74.73z"
      ></path>
      <path
        fill="#31B0C8"
        d="M94.532 64.363A30.502 30.502 0 01122.261 34v74.73a30.505 30.505 0 01-27.729 30.363v-74.73z"
      ></path>
      <path
        fill="#32D9C3"
        d="M155.536 139.093a30.5 30.5 0 01-27.729-30.363V34a30.501 30.501 0 0127.729 30.363v74.73z"
      ></path>
      <path
        fill="#2391D9"
        d="M37.41 139.301c10.72 0 19.41-8.69 19.41-19.41s-8.69-19.411-19.41-19.411S18 109.171 18 119.891s8.69 19.41 19.41 19.41z"
      >
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </path>
    </svg>
  );
}

export default MedScoutLoadingIcon;
